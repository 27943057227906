/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Component handles the beneficial owner add and edit form
 */
import React, { useMemo } from 'react';
import { Divider, Grid } from '@mui/material';
import FxMaterialSelect from '../../../Input/FxSelect/FxMaterialSelect';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { FxTextEdit } from '../../../Input/FxText/FxTextEdit';
import FxLabelView from '../../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../../Action/FxButton';
import usa_state from '../../../Utils/usa_states.json'
import { ageLimit, handleKeyDownChange, handleZipChange, handleAddressChange, renderError, setZipValidationOptions, renderOwnershipPercentageError, setPhoneValidationOptions, renderPhoneError, handleSecIdChange, secondaryIdentificationIdValidation, updateComponentParams, setEmailValidationOptions, setSsnValidationOptions, setAddressLine1Validation } from '../../../Utils/CommonBaseClass';
import issuance_countries from '../../../../libs/utils/country/country.json'
import { FxDateEdit } from '../../../Input/FxDate/FxDateEdit';
import FxPhoneEdit from '../../../Input/FxPhone/FxPhoneEdit';
import { FxTextEditSSN } from '../../../Input/FxSSN/FxTextEditSSN';
import { FxSelectAutoSearch } from '../../../Input/FxSelect/FxSelectAutoSearch';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

const secondaryIDTypeData: any = [
    { value: 'DRIVER_LICENSE', label: 'Driver License' },
    { value: 'PASSPORT', label: 'Passport' },
    { value: 'ALIEN_REGISTRATION_CARD', label: 'Alien registration Card' }
];

const usCitizenDropdown: any = [
    { value: 'US_CITIZEN', label: 'US Citizen' },
    { value: 'PERMENANT_RESIDENT', label: 'Permanent Resident' },
    { value: 'NON_RESIDENT', label: 'Non Resident' }
]

interface IAddBeneficialOwner {
    id: string;
    onSubmit?: any;
    onCancel?: any;
    businessCategory?: any;
    metaData?: any;
    register?: any;
    control?: any;
    errors?: any;
    setValue?: any;
    setError?: any;
    clearErrors?: any;
    handleSubmit?: any;
    trigger?: any;
    data?: any;
    isEdit?: boolean;
    index?: any;
    watch?: any;
    reset?: any;
    fields?: any;
}


export const AddBeneficialOwner: React.FC<IAddBeneficialOwner> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));

        const dispatch = useDispatch()
        const index = props.index;
        const { register, control, formState: { errors }, watch, setValue, setError, clearErrors, trigger, unregister } = useFormContext();
        const businessCategories = [
            'GOVERNMENT_ORGANISATION',
            'NON_PROFIT',
            'TAX_EXEMPTION',
            'LLC',
            'LLP',
            'S-CORP',
            'C-CORP',
        ];

        /**
         * Set Validation condition for Ownership Percentage
         */
        const authSignDate: any = useMemo(() => {
            let requiredField: boolean = false;
            const authorized = watch('beneficialOwner')?.[props.index]?.authSignatory?.length > 0;
            if (authorized && businessCategories.includes(watch('businessCategory'))) {
                requiredField = true
            }
            return requiredField
        }, [watch('beneficialOwner'), props.index])

        /**
         * Method handles the change event of ssn and secondary identification fields
         * @param event : event parameter
         */
        const handleChange = (event: any) => {
            if (event.target.name === `beneficialOwner.${index}.secondaryIdentificationType`) {
                const metaData = { ...props.metaData }
                metaData['secondaryIdType'] = event.target.value;
                updateComponentParams(dispatch, props.id, { metaData })
                clearErrors(`beneficialOwner.${index}.secondaryIdentificationId`)
            }
            if (event.target.name === `beneficialOwner.${index}.ssn`) {
                let val = event.target.value.replace(/\D/g, '');
                val = val.replace(/^(\d{3})/, '$1-');
                val = val.replace(/-(\d{2})/, '-$1-');
                val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
                if (val.length === 11) {
                    clearErrors(`beneficialOwner.${index}.ssn`);
                }
                else {
                    setError(`beneficialOwner.${index}.ssn`, {
                        type: "manual",
                        message: "Invalid SSN",
                    });
                }
                setValue(`beneficialOwner.${index}.ssn`, val)
            }
            if(event.target.value === 'DRIVER_LICENSE'){
                const idValue =  watch(`beneficialOwner.${index}.secondaryIdentificationId`)
                unregister(`beneficialOwner.${index}.secondaryIdentificationId`)
                register(`beneficialOwner.${index}.secondaryIdentificationId`)
                idValue && setValue(`beneficialOwner.${index}.secondaryIdentificationId`, idValue)
            }
        }

        /**
         * Method handles the form submission of add/edit beneficial owner form
         * @param index : index of the add/edit item
         */
        const handleAddBeneficialOwner = async (index: any) => {
            await trigger();
            if (props.onSubmit && errors?.beneficialOwner?.[index] === undefined) {
                props.onSubmit()
            }
        }

        // Ownership percentage validation constant
        const setOwnerShipPercentageRule = {
            required: authSignDate === true ? false : true,
            min: 0,
            max: 100
        };

        /**
         * Method handles the cancellation of add/edit beneficial form
         */
        const handleCancel = () => {
            props.onCancel && props.onCancel(index);
        }

        return (
            <fieldset className="fx-container-create-schdule-summery">
                <legend>Beneficial Owner {`#${index + 1}`}</legend>
                <div className="fx-form-edit-profile">
                    <FxCardBody id="signUp-business-owner-form-card-body" className="fx-business-owner-add fx-info-card" >
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <FxLabelView id="business-owner-details-label" className="" >OWNER PROFILE</FxLabelView>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxTextEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.firstName`) }} className={errors[`beneficialOwner`]?.[index]?.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id={`beneficialOwner.${index}.firstName`} label="Legal First Name *" name={`beneficialOwner.${index}.firstName`} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxTextEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.lastName`) }} className={errors[`beneficialOwner`]?.[index]?.lastName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id={`beneficialOwner.${index}.lastName`} label="Legal Last Name *" name={`beneficialOwner.${index}.lastName`} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FxTextEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.email`) }} type="email" className={errors[`beneficialOwner`]?.[index]?.email ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setEmailValidationOptions(true)} id={`beneficialOwner.${index}.email`} label="Email *" name={`beneficialOwner.${index}.email`} variant="outlined" />
                                <div className={'error-message'}>
                                    {errors[`beneficialOwner`]?.[index]?.email && renderError(errors[`beneficialOwner`]?.[index]?.email)}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FxTextEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.jobtitle`) }} className={errors[`beneficialOwner`]?.[index]?.jobtitle ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id={`beneficialOwner.${index}.jobtitle`} label="Job Title *" name={`beneficialOwner.${index}.jobtitle`} />
                            </Grid>
                            <Grid item xs={12} sm={4} >
                                <FxTextEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.ownerShipPercentage`) }} className={errors[`beneficialOwner`]?.[index]?.ownerShipPercentage ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setOwnerShipPercentageRule} id={`beneficialOwner.${index}.ownerShipPercentage`} type="number" label={authSignDate === true ? "Ownership" : "Ownership*"} suffix="%" name={`beneficialOwner.${index}.ownerShipPercentage`} variant="outlined" showDecimal={true} setValue={setValue} min='0' defaultValue={0.00} />
                                <div className={'error-message'}>
                                    {errors[`beneficialOwner`]?.[index]?.ownerShipPercentage && renderOwnershipPercentageError(errors[`beneficialOwner`]?.[index]?.ownerShipPercentage)}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FxLabelView id="business-owner-details-label" className="" >ABOUT OWNER</FxLabelView>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxDateEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.dob`) }} className={errors[`beneficialOwner`]?.[index]?.dob ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id={`add-beneficial-owner-form-card-dob#${index}`} label="Date of Birth *" name={`beneficialOwner.${index}.dob`} variant="outlined" maxDate={ageLimit(18)} setValue={setValue} value={watch('beneficialOwner')?.[props.index]?.dob} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxTextEditSSN
                                    key={`beneficialOwner.${index}.id`}
                                    register={{ ...register(`beneficialOwner.${index}.ssn`) }}
                                    className={errors[`beneficialOwner`]?.[index]?.ssn ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                    error={errors[`beneficialOwner`]?.[index]?.ssn}
                                    control={control}
                                    rules={{...setSsnValidationOptions(true), ...(props?.data?.[index]?.itin ? {disabled: true, required : false} : {disabled: false, required : true})}}
                                    id={`beneficialOwner.${index}.ssn`}
                                    label={`Social Security Number ${props?.data?.[index]?.itin ? "": "*"}`}
                                    placeholder="XXX-XX-XXXX"
                                    name={`beneficialOwner.${index}.ssn`}
                                    defaultValue={props?.data?.[index]?.ssn ? props?.data?.[index]?.ssn : ''}
                                    variant="outlined"
                                    setValue={setValue}
                                    setError={setError}
                                    clearErrors={clearErrors}
                                />
                                <div className={'error-message'}>
                                    {errors[`beneficialOwner`]?.[index]?.ssn && renderError(errors[`beneficialOwner`]?.[index]?.ssn)}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxMaterialSelect key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.usCitizen`) }} rules={{ required: false }} className={errors[`beneficialOwner`]?.[index]?.usCitizen ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id={`beneficialOwner.${index}.usCitizen`} name={`beneficialOwner.${index}.usCitizen`} data={usCitizenDropdown} label="US Citizenship Status" setValue={setValue} value={'US_CITIZEN'} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxPhoneEdit key={`beneficialOwner.${index}.id`} register={register} className={errors[`beneficialOwner`]?.[index]?.mobilePhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(true)} id={`beneficialOwner.${index}.mobilePhone`} label="Mobile Phone *" name={`beneficialOwner.${index}.mobilePhone`} placeholder={'XXX-XXX-XXXX'} setValue={setValue} setError={setError} disableFlag={true} clearErrors={clearErrors} />
                                <div className={'error-message'}>
                                    {errors[`beneficialOwner`]?.[index]?.mobilePhone && renderPhoneError(errors[`beneficialOwner`]?.[index]?.mobilePhone)}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxPhoneEdit key={`beneficialOwner.${index}.id`} register={register} className={errors[`beneficialOwner`]?.[index]?.homePhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(false)} id={`beneficialOwner.${index}.homePhone`} label="Home Phone" name={`beneficialOwner.${index}.homePhone`} placeholder={'XXX-XXX-XXXX'} setValue={setValue} setError={setError} disableFlag={true} clearErrors={clearErrors} />
                                <div className={'error-message'}>
                                    {errors[`beneficialOwner`]?.[index]?.homePhone && renderPhoneError(errors[`beneficialOwner`]?.[index]?.homePhone)}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxPhoneEdit key={`beneficialOwner.${index}.id`} register={register} className={errors[`beneficialOwner`]?.[index]?.workPhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(false)} id={`beneficialOwner.${index}.workPhone`} label="Work Phone" name={`beneficialOwner.${index}.workPhone`} placeholder={'XXX-XXX-XXXX'} setValue={setValue} setError={setError} disableFlag={true} clearErrors={clearErrors} />
                                <div className={'error-message'}>
                                    {errors[`beneficialOwner`]?.[index]?.workPhone && renderPhoneError(errors[`beneficialOwner`]?.[index]?.workPhone)}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FxLabelView id="business-owner-details-label" className="" >SECONDARY IDENTIFICATION</FxLabelView>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FxMaterialSelect key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.secondaryIdentificationType`) }} control={control} className={errors[`beneficialOwner`]?.[index]?.secondaryIdentificationType ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={`beneficialOwner.${index}.secondaryIdentificationType`} name={`beneficialOwner.${index}.secondaryIdentificationType`} data={secondaryIDTypeData}
                                    onChange={handleChange} label="Type *" setValue={setValue} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FxTextEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.secondaryIdentificationId`) }} className={errors[`beneficialOwner`]?.[index]?.secondaryIdentificationId ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} rules={watch(`beneficialOwner.${index}.secondaryIdentificationType`) === 'DRIVER_LICENSE'? {required: true}:secondaryIdentificationIdValidation(watch(`beneficialOwner.${index}.secondaryIdentificationType`))} id={`beneficialOwner.${index}.secondaryIdentificationId`} label="ID *" name={`beneficialOwner.${index}.secondaryIdentificationId`} onChange={(e: any) => { handleSecIdChange(e, setValue, setError, clearErrors, watch(`beneficialOwner.${index}.secondaryIdentificationType`)) }} />
                                <div className={'error-message'}>
                                    {errors[`beneficialOwner`]?.[index]?.secondaryIdentificationId && renderError(errors[`beneficialOwner`]?.[index]?.secondaryIdentificationId)}
                                </div>
                            </Grid>
                            {watch(`beneficialOwner.${index}.secondaryIdentificationType`) === 'DRIVER_LICENSE' &&
                                <Grid item xs={12} sm={4}>
                                    <FxMaterialSelect key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.secondaryIdentificationIssuanceState`) }} control={control} className={errors[`beneficialOwner`]?.[index]?.secondaryIdentificationIssuanceState ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={`beneficialOwner.${index}.secondaryIdentificationIssuanceState`} name={`beneficialOwner.${index}.secondaryIdentificationIssuanceState`} data={usa_state}
                                        onChange={handleChange} label="State of Issuance *" setValue={setValue} value={watch(`beneficialOwner.${index}.secondaryIdentificationIssuanceState`)}/>
                                </Grid>}
                            {watch(`beneficialOwner.${index}.secondaryIdentificationType`) !== 'DRIVER_LICENSE' && watch(`beneficialOwner.${index}.secondaryIdentificationType`) !== 'ALIEN_REGISTRATION_CARD' && <Grid item xs={12} sm={4}>
                                <FxMaterialSelect key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.secondaryIdentificationIssuance`) }} control={control} className={errors[`beneficialOwner`]?.[index]?.secondaryIdentificationIssuance ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: watch(`beneficialOwner.${index}.secondaryIdentificationType`) !== '' ? true : false }} id={`beneficialOwner.${index}.secondaryIdentificationIssuance`} name={`beneficialOwner.${index}.secondaryIdentificationIssuance`} data={issuance_countries}
                                    onChange={handleChange} readOnly={watch(`beneficialOwner.${index}.secondaryIdentificationType`) === 'ALIEN_REGISTRATION_CARD' ? true : false} label="Country of Issuance *" setValue={setValue} />
                            </Grid>}
                            {watch(`beneficialOwner.${index}.secondaryIdentificationType`) === 'ALIEN_REGISTRATION_CARD' &&
                                <Grid item xs={12} sm={4}>
                                    <FxTextEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.secondaryIdentificationIssuanceAlien`) }} className={errors[`beneficialOwner`]?.[index]?.secondaryIdentificationIssuanceAlien ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                        control={control} id={`beneficialOwner.${index}.secondaryIdentificationIssuanceAlien`} label="Country of Issuance *" name={`beneficialOwner.${index}.secondaryIdentificationIssuanceAlien`} value="United States" isEditable={false} />
                                    <div className={'error-message'}>
                                        {errors[`beneficialOwner`]?.[index]?.secondaryIdentificationIssuanceAlien && renderError(errors[`beneficialOwner`]?.[index]?.secondaryIdentificationIssuanceAlien)}
                                    </div>
                                </Grid>
                            }
                            <Grid item xs={12} sm={12}>
                                <FxLabelView id="business-owner-details-label" className="" >PRIMARY ADDRESS</FxLabelView>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxTextEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.addressLine1`) }} className={errors[`beneficialOwner`]?.[index]?.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={setAddressLine1Validation(true)} control={control} id={`beneficialOwner.${index}.addressLine1`} label="Address Line 1 *" name={`beneficialOwner.${index}.addressLine1`} onChange={(e: any) => { handleAddressChange(e, setValue, setError, clearErrors) }} />
                                <div className={'error-message'}>
                                    {errors[`beneficialOwner`]?.[index]?.addressLine1 && renderError(errors[`beneficialOwner`]?.[index]?.addressLine1)}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxTextEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.addressLine2`) }} className={errors[`beneficialOwner`]?.[index]?.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id=
                                    {`beneficialOwner.${index}.addressLine2`} label="Address Line 2" name={`beneficialOwner.${index}.addressLine2`} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FxTextEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.city`) }} className={errors[`beneficialOwner`]?.[index]?.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id={`beneficialOwner.${index}.city`} label="City *" name={`beneficialOwner.${index}.city`} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxSelectAutoSearch key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.state`) }} rules={{ required: true }} className={errors[`beneficialOwner`]?.[index]?.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id={`beneficialOwner.${index}.state`} name={`beneficialOwner.${index}.state`} data={usa_state} label="State *" setValue={setValue} value={watch(`beneficialOwner.${index}.state`)} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxTextEdit key={`beneficialOwner.${index}.id`} register={{ ...register(`beneficialOwner.${index}.zip`) }} className={errors[`beneficialOwner`]?.[index]?.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setZipValidationOptions(true)} id={`beneficialOwner.${index}.zip`} label="ZIP *" name={`beneficialOwner.${index}.zip`} onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} />
                                <div className={'error-message'}>
                                    {errors[`beneficialOwner`]?.[index]?.zip && renderError(errors[`beneficialOwner`]?.[index]?.zip)}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={12}>&nbsp;</Grid>
                        </Grid>
                    </FxCardBody>
                    <FxCardFooter id="signUp-business-owner-Card-Footer" className="fx-footer">
                        <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                            <FxButton
                                disableRipple={false}
                                variant="contained"
                                className="fx-button fx-button-cancel"
                                id="cancel-add-business-owner-btn"
                                onClick={handleCancel}
                            > CANCEL
                            </FxButton>
                            <Grid className='fx-padding-right-15'></Grid>
                            <FxButton
                                disableRipple={false}
                                className="fx-button fx-button-theme"
                                id="add-beneficial-owner-save-button"
                                onClick={(e) => handleAddBeneficialOwner(index)}
                            > Save
                            </FxButton>
                        </Grid>
                    </FxCardFooter>
                </div>
            </fieldset>
        )
    }
)
