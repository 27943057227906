import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
 /* eslint-disable  @typescript-eslint/no-explicit-any */
 /* eslint react-hooks/exhaustive-deps: "off" */
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { AccountInfoCard } from './AccountInfoCard';
import { updateComponentData, updateComponentParams } from '../../../Utils/CommonBaseClass';
import { getKey, setKey } from '../../../../libs/utils/storageManager';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { useHistory } from 'react-router';
import { FxSkeltonList } from '../../Cards/FxSkelton';
import { applyTransactionFeatures } from '../../../../libs/services/uiFeatures';
import { FxButton } from '../../../Action/FxButton';
import FxRightDrawer from '../../../Utils/fx-right-drawer';
import { FxAccountDrawer } from '../../../DrawerPages/FxAccounts/FxAccountDrawer';
import { ReactComponent as AddIcon } from '../../../../assets/svg/add-new-icon.svg';
import { FxAccountInfoCard } from '../../Cards/FxAccountInfoCard';
import { FxInvestmentAccountDrawer } from '../../../DrawerPages/Treasure/FxInvestmentAccountDrawer';
import { ReactComponent as EyeIcon } from '../../../../assets/svg/eye-icon.svg';
import { ReactComponent as TelegramIcon } from '../../../../assets/svg/telegram-icon.svg';
import { ReactComponent as ArrowPermsDown } from '../../../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as ArrowPermsUp } from '../../../../assets/svg/triangle-up-icon.svg';
import DEFAULT_CONFIG from '../../../../configs/default.json';
import { pushGTM } from '../../../../libs/utils/utils';


// Interface for Accordion Props 
interface FxAccountAccordionProps {
    id: string;
    title: string;
    renderBody: () => React.ReactNode;  // Function to render different body content
    accordion: string;
}

/**
 * The is a Customer Account Listing Page
 */
export const AccountListCard: React.FC<any> = React.memo((props) => {

    let context:any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const history = useHistory();
    const accountsData = context?.data?.body;
    const userName = getKey('userName')

    /**
     * Selector to retrieve the 'accountList' from the Redux store.
     * @returns {Array} - Returns the 'accountList` arrays from the Redux store if it exists, otherwise `undefined`.
     */
    const { accountList, targetBalanceSweep} = useSelector((state: any) => {
        const customerBasicInfo = state?.data?.['customer-basic-info'];
        return customerBasicInfo?.params || {};
    });

    const masterAccount = targetBalanceSweep?.masterAccount;

    /**
     * Function to be called before loading the component
     */
    useEffect(() => {
      updateComponentParams(dispatch, props.id, { treasuryAccordionOpen: true});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Toggles the open/closed state of a specific accordion.
     * @param {string} accordion - The ID of the accordion to toggle. This determines which accordion's state should be updated.
     */
    const handleAccordionChange = (accordion: string) => {
      if (accordion === 'customer-treasury-account-card') {
        updateComponentParams(dispatch, props.id, { treasuryAccordionOpen: !props?.treasuryAccordionOpen});
      }
    }

    /**
     * Categorizes accounts based on type and status.
    */
    const { customerAccountList, cashBuilderAccount, cashBuilderPlus } = useMemo(() => {
        const customerAccountList: any = [];
        let cashBuilderAccount;
        let cashBuilderPlus; 
        accountsData?.resources?.forEach((account: any) => {
            if (account?.type === 'DELETED') {
                return;
            } else if (account?.type === 'CASH_BUILDER') {
                cashBuilderAccount = account;
            } else if (account?.type === 'CASH_BUILDER_PLUS') {
                cashBuilderPlus = account;
            } else {
                customerAccountList.push(account);
            }
        });
        return {
          customerAccountList,
          cashBuilderAccount,
          cashBuilderPlus,
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountsData, masterAccount]);

    const [treasuryAccounts, setTreasuryAccounts] = useState<{
          id: string;
          title: string;
          subtitle: string;
          description: string;
          icon: JSX.Element;
          buttonText: string;
          accountData: any;
          callback: () => void;
        }[]
    >([]);
    
    // Destructure props for easier readability
    const { showCashBuilderCard, showCashBuilderPlusCard } = props;

    /**
     * Memoize the accounts array to prevent unnecessary re-creation on each render
     */
    const accounts = useMemo(() => {
        const accountArray = [];

        // Add Cash Builder Account if applicable
        if (showCashBuilderCard || cashBuilderAccount) {
            accountArray.push({
                id: "cash-builder",
                title: "Cash Builder Account",
                subtitle: "Grow Your Savings with Zero Minimums!",
                description: `Unlock the power of savings without the burden of minimum balances. Cash Builder allows you to earn a competitive APY of ${getKey("cashBuilderInterestRate")} effortlessly, giving your money the chance to work harder for you. Enjoy the security of Account with passthrough FDIC insurance up to ${DEFAULT_CONFIG?.CASH_BUILDER_INSURANCE_UPTO} and watch your savings grow. Start your journey to financial freedom today!`,
                icon: <EyeIcon />,
                buttonText: "Learn More",
                accountData: cashBuilderAccount,
                callback: () => openPassportDrawer("CASH_BUILDER"),
            });
        }

        // Add Cash Builder+ Account if applicable
        if (showCashBuilderPlusCard || cashBuilderPlus) {
            accountArray.push({
                id: "cash-builder-plus",
                title: "Cash Builder+ Account",
                subtitle: `Maximize Your Wealth with a ${getKey("cashBuilderPlusInterestRate")} APY!`,
                description: `Elevate your savings strategy with Cash Builder+. With a minimum balance of ${DEFAULT_CONFIG?.CASH_BUILDER_PLUS_MINIMUM_BALANCE}, your funds are strategically invested in high-yield, short-term market products designed for premium returns. Experience smarter wealth growth while maximizing your earning potential. Join Cash Builder+ and take your savings to the next level!`,
                icon: <TelegramIcon />,
                buttonText: "Learn More",
                accountData: cashBuilderPlus,
                callback: () => openPassportDrawer("CASH_BUILDER_PLUS"),
            });
        }
        return accountArray;
    // Dependencies include relevant props and account data to recalculate when these change
    }, [showCashBuilderCard, showCashBuilderPlusCard, cashBuilderAccount, cashBuilderPlus]);

    /**
     * useEffect hook to update the treasuryAccounts state when accounts change
     */
    useEffect(() => {
        setTreasuryAccounts(accounts);
    // Only run when `accounts` changes
    }, [accounts]);

    /**
     * useEffect to set title of page
     */
    useEffect(() => {
      updateComponentData(dispatch, 'page-title-details', { title: 'Home' })
      if(accountsData) {
        updateComponentParams(dispatch, 'customer-basic-info', { 'accountList': accountsData })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountsData])

    /**
    * method to Open Investment Account Drawer
    */
    const openPassportDrawer = (async (type: any) => {
        const event = type === 'CASH_BUILDER_PLUS' ? 'bannerCashBuilderPlusStartTrigger' : 'bannerCashBuilderStartTrigger';
        // Handling gtm data for cash builder and cash builder plus account while launching the banner
        const gtmData: any = {
            event,
            userName
        };
        pushGTM(gtmData)
        await FxRightDrawer.show(({ onClose }) => {
            return <FxInvestmentAccountDrawer type={type} onDrawerClose={onClose} />
        })
    })

    /*
    *  my account click action
    */
    const myAccountClick = (event: any) => {
        if(event?.type === 'CASH_BUILDER_PLUS'){
            history.push('/cashbuilder')
        }else if(event?.type === 'CASH_BUILDER'){
            history.push('/portfolio')
        }else{
            history.push('/')
        }    
    }

    /**
    * Method handles the onchange event of selecting account number by setting the selected account object, updating component parameters for recent transactions card, resetting the component data for recent transactions list
    * @param item
    */
    const handleAccountChange = (item: any, type: any) => {
        const selectedItem = accountList?.resources?.find((x:any)=>x.id === item);
        accountList?.resources?.forEach((optionData: any) => {
            if (type === 'Account') {
                myAccountClick(selectedItem);
            }
            if (optionData.id === item) {
                if(optionData.status === 'INACTIVE'){
                    setKey("accountStatus", 'false');
                }
                applyTransactionFeatures()
                updateComponentParams(dispatch, props?.id, { 'selectedOption': optionData })
                setKey("selectedAccount", optionData);
                if(type !== 'Download'){
                    updateComponentParams(dispatch, 'layout-passport', { 'accountBasedMenu': true })
                    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'customer-home-passport' } });
                    updateComponentParams(dispatch, "customer-basic-info", { 'accountDetails':{}});
                    updateComponentParams(dispatch, 'customer-home-passport', { 'accountUpdated': true  ,'showSkeleton':true})
                    updateComponentData(dispatch, 'switch-customer-data', { selectedAccount: optionData })
                    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'home-my-account-balance-chart' } });
                    dispatch({ type: "DATA_COMPONENT_INIT", payload: { "id": 'home-my-account-card-account-info-card'} });
                }
            }
        })
    }
    

   /**
    * Method to Open New Account Creation Drawer
    */
    const openAccountCreationDrawer = (async () => {
       await FxRightDrawer.show(({ onClose }) => {
            return <FxAccountDrawer id="create-new-customer-account-drawer" onDrawerClose={onClose} />
        });
    });

    // Reusable FxAccountAccordion Component
    const FxAccountAccordion: React.FC<FxAccountAccordionProps> = ({ id, title, renderBody, accordion }) => (
        <FxCard id={id} className="fx-card-general fx-theme-passport fx-customer-account-list">
            <Accordion expanded={!!accordion} onChange={() => handleAccordionChange(id)}>
                <AccordionSummary aria-controls={`${id}-content`} id={`${id}-header`} className="fx-accordion">
                    <FxCardHeader id={`${id}-header`}>
                        <Grid item container xs={12} spacing={1} alignItems="center">
                            <Grid item xs className="fx-flex-grow">
                                <Grid id="passport-account-list-title" item xs justifyContent="flex-start">
                                    <Typography variant="h3">{title}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item className="fx-dashboard-toggle-icon">
                                {!accordion ? <ArrowPermsUp /> : <ArrowPermsDown />}
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                </AccordionSummary>

                <AccordionDetails>
                    <FxCardBody id={`${id}-body`} className="fx-card-body">
                        {renderBody()}  {/* Render the specific content */}
                    </FxCardBody>
                </AccordionDetails>
            </Accordion>
        </FxCard>
    );  

    return (
        <Grid id="customer-account-list-card-Home-Grid" item xs={12} >
            <FxCard id="customer-account-list-card-Home-Card" className="fx-card-general fx-theme-passport fx-customer-account-list">
                <FxCardHeader id="customer-account-list-card-Home-Card-Header">
                    <Grid item container xs={12} spacing={1} alignItems='center'>
                        <Grid item className="fx-flex-grow">
                            <Grid id="passport-account-list-title" item xs justifyContent="flex-start" className=''>
                                <Typography variant="h3">Passport Accounts</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <FxButton className="fx-button fx-button-theme fx-account-creation-icon"
                                variant="contained" id={props.id + '-add-new-account'} onClick={openAccountCreationDrawer}
                                startIcon={<AddIcon />} >ADD NEW</FxButton>
                        </Grid>
                    </Grid>
                </FxCardHeader>
                <FxCardBody id="customer-account-list-card-Home-Card-Body" className="fx-card-body">
                    {customerAccountList.length > 0 ? customerAccountList.map((item:any,index:any)=>(
                        <AccountInfoCard data={item} id={`'account-info-card' + ${index}`} handleAccountChange={handleAccountChange}/>
                    )) : 
                        <FxSkeltonList width="100%" height="25rem" />
                    }
                </FxCardBody>
            </FxCard>
            {/* Cash Builder Accounts  */}
            {treasuryAccounts?.length > 0 && (
                <FxAccountAccordion
                    id="customer-treasury-account-card"
                    title="Treasury Accounts"
                    accordion={props?.treasuryAccordionOpen}
                    renderBody={() => (
                        <FxAccountInfoCard 
                            id="treasury-accounts-card" 
                            accounts={treasuryAccounts} 
                            handleAccountChange={handleAccountChange}
                        />
                    )}
                />
            )}
        </Grid>
    )
})