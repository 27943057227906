 /* eslint-disable  @typescript-eslint/no-explicit-any */
 /* eslint-disable @typescript-eslint/no-unused-vars */
 /* eslint react-hooks/exhaustive-deps: "off" */
import React, { useMemo, useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FxCurrencyView } from '../../../Input/FxCurrency/FxCurrencyView';
import { checkPermission, fetchDataFromState, maskAccountNumber, truncateChars, updateComponentParams } from '../../../Utils/CommonBaseClass';
import { setKey, getKey } from '../../../../libs/utils/storageManager';
import { FxLink } from '../../../Action/FxLink';
import { ReactComponent as DownloadIcon } from '../../../../assets/svg/download-home.svg';
import { FxInfoTooltip } from '../../../Utils/FxInfoTooltip';
import { AccountStatementDownload } from './AccountStatementDownload';
import { FxButton } from '../../../Action/FxButton';
import { LogoAccount } from '../../../Utils/Logo';
import { getCustomerUrl, useIsMobileScreen } from '../../../../libs/utils/utils';
import { FxAccessDenied } from './FxAccessDenied';
import { DebitCardList } from './DebitCardList';
import clsx from "clsx";
import { ReactComponent as ThunderIconCard } from '../../../../assets/svg/thunder-icon-card.svg';
import { ReactComponent as DarkInfoIcon } from '../../../../assets/svg/darkgrey-info-icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/svg/new-edit-icon.svg';
import FxModalGeneric from '../../../Utils/FxModalGeneric';
import { AvailFunds } from '../../Ledgers/AvailFundsCard';
import { Logger } from '../../../../libs/utils/logger';
import HttpClient from '../../../../libs/utils/httpClient';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { ViewBalanceDetails } from './ViewBalanceDetails';
import { ReactComponent as CopyIcon } from '../../../../assets/svg/copy-to-clipboard.svg';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import { useHistory } from 'react-router';
import { EditNicknameModal } from './EditNicknameModal';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';
import { ShowInfoToolTip } from "../../../Utils/CommonBaseClass";
const httpClient = HttpClient.getClient();
const accountStatementPermission = {
  entity: "Accounts",
  name: "Statement",
  operation: "Download"
}

const availFundsPopupPermission = {
  entity: "Accounts",
  name: "Faster Funds",
  operation: "Avail"
};

export const AccountInfo: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props))
  const dispatch = useDispatch();
  let template: any;
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available
  const individualCustomer = uiFeat.getIndividualCustomerFeature().available
  const data = { availableBalance: 0, accountNumber: null }
  const isMobileScreen = useIsMobileScreen();
  const isDebitCardDisplayed = ((getKey('isIssueDebitCardIndividualAllowed') === "TRUE" && individualCustomer)||(getKey('isIssueDebitCardBusinessAllowed') === "TRUE" && businessCustomer)) && props?.accountData?.isCustomerOwned === true && getKey("selectedAccount")?.status !== 'INACTIVE' && checkViewPermission("debit_card_view");
  let creditLineData: any;
  const history = useHistory();

  useEffect(() => {
    getCreditLimitData();
    getAllMerchants();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * To get balanceDetails 
   */
  const balanceDetails = useSelector((state: any) => {
    if (state.data['customer-basic-info']) {
        return state.data['customer-basic-info']?.params?.viewBalanceDetails
    }
  });

  /**
   * This async method calls the Credit Limit GET api, and waits for the response data and then sets the state
   */
  async function getCreditLimitData(){
    creditLineData = await getCreditLineDetails();
    updateComponentParams(dispatch, props?.id, { 'creditData': creditLineData?.fasterFundsAvailable });
  }

  /**
   * Method to call the Credit Line API to get the Faster Funds Available Amount
   */
    async function getCreditLineDetails(){
      try {
          const data: any = await httpClient
            .get(getCustomerUrl("/account/id/" + getKey("selectedAccount")?.id + "/fasterFunds", false));
            if(data && data.data){
              return data.data;
            }
        } catch (err) {
          Logger.error("AccountInfo.tsx", "error", err);
          return err;
        }
    }

  /**
   * Method handles the closing of the Avail Funds Modal
   */
  const handleCloseModal = ()=>{
    updateComponentParams(dispatch, props?.id, { 'isOpen': false});
  }

  /**
   * Method handles the opening of the Avail Funds Modal
   */
  const handleAvailFund=()=>{
    updateComponentParams(dispatch, props?.id, { 'isOpen': true});
  }
  
  /**
  * function to check permission 
  */
  function checkViewPermission(data: any) {
    const permissionObject: any = {
        'debit_card_view': {
            entity: "Accounts",
            name: "Debit Card",
            operation: "View"
        }
    }
    return checkPermission(permissionObject[data])
  }
  /**
    * Method to get switch customer details from state
    */
  const switchData = useSelector((state: any) =>
    fetchDataFromState(state, 'switch-customer-data')?.data
  );

  /**
   * checking props data available
   */
  const accountData = props.accountData;
  if (accountData && (accountData?.id === switchData?.selectedAccount?.id)) {
    data.availableBalance = accountData.availableBalance?.amount;
    data.accountNumber = accountData.accountNumber;
    template = accountData.configuration?.debit?.checkProcessing?.defaultRemittanceTemplate
    setKey("accountinfotemplate", template)
  }

  /**
   * Method handles the onclick event for opening modal
   */
  const handleOpen = () => {
    updateComponentParams(dispatch, props?.id, { 'open': true });
  };

  /*
  * Method handles the closing of the account statement modal
  */
  const handleAccountStatementClose = () => {
    updateComponentParams(dispatch, props?.id, { 'open': false });
  }

  /**
  * to set permission
  */
  const permission = useMemo(() => {
    return checkPermission({
      entity: "Accounts",
      name: "Account",
      operation: "View"
    })
  }, []);

  /**
  * to get debit card details
  */
   const customerBasicInfoList = useSelector((state: any) => {
    if (state.data['customer-basic-info']) {
      return state.data['customer-basic-info']
    }
  });

  /**
   * This Method handles the opening of the Hold & Reserves Split Detail Modal
   */
  const handleHoldReserveDetailModalOpen = () => {
    if(balanceDetails?.reserveAndHoldBalance > 0)
      updateComponentParams(dispatch, props?.id, { 'holdSplitModalOpen': true });
  }

  /**
   * This Method handles the closing of the Hold & Reserves Split Detail Modal
   */
  const handleHoldReserveDetailModalClose = () => {
    updateComponentParams(dispatch, props?.id, { 'holdSplitModalOpen': false });
  }


  /**
 * method to get all merchants link with customer
 */
  async function getAllMerchants() {
    const merchantIds:any =[];

    try{
      const merchants:any = await httpClient.get('customer/id/' + getKey("customerId") + '/merchant');
      merchants?.data?.map((item:any)=>{
        if (item?.status === 'ACTIVE' ) {
          merchantIds.push(item.id);
      }
    });

    getMerchantSetting(merchantIds);


  } catch (err) {
    Logger.error("AccountInfo.tsx :: getAllMerchants", "error", err);

  }
   
   
}

  /**
   * Method to get merchant settings
   * @returns setting
   */
  async function getMerchantSetting(merchantIds: any) {
    try {
      const quick_settle_settings_request = {
        "pageNumber": 1,
        "pageSize": 1,
        "sortOptions": {
          "sortBy": "lastUpdatedOn",
          "sortOrder": "desc"
        },
        "criteria": {
          "filters": [{
            "operator": "eq",
            "key": "category",
            "values": [
              "QUICK_SETTLE"
            ]
          }, {
            "operator": "in",
            "key": "entityName",
            "values": [
              "MERCHANT"]
          }, {
            "operator": "eq",
            "key": "hierarchyLevel",
            "values": [
              "ENTITY"]
          },
          {
            "operator": "in",
            "key": "hierarchyLevelEntityId",
            "values": merchantIds
          }]
        }
      }
      const settings = await httpClient.post('programManager/configuration/list', quick_settle_settings_request);
      if(merchantIds.length > 0 && settings?.data?.resources[0]?.value !== undefined){
        updateComponentParams(dispatch, props?.id, { 'fasterFunding': (settings?.data?.resources[0]?.value === "0") ? false : true });
      }else{
        updateComponentParams(dispatch, props?.id, { 'fasterFunding': false });
      }
    } catch (err) {
      Logger.error("AccountInfo.tsx ::getMerchantSetting", "error", err);
    }
  }

  /**
   * Method handles the onclick event of copy ppi
   * */
  const copyPPI = (e:any) => {
    try{
        navigator.clipboard.writeText(customerBasicInfoList?.params?.ppiDetails?.ppi)
        FxSnackBar.show({
            severity: 'success',
            text: 'PPI ID Copied Successfully',
        });
    }
    catch(err){
      Logger.error("AccountInfo.tsx", "error", err);
    }
  }

  /**
   * Method handles the onclick on Passport logo 
   * */
  const handleLogoClick = () => {
    if(getKey("selectedAccount")?.id){
      history.push("/account/view/" + getKey("selectedAccount")?.id);
    }
  }

  const accountListInfo = useSelector((state: any) => state.data['account-list-sub-menu'] || null);

  /**
   * Update Selected Account when Account List is changed
   */
  useMemo(()=> {
    return accountListInfo?.data?.body?.resources?.filter((item:any) => {
      if(item.id === getKey('selectedAccount')?.id){
        setKey('selectedAccount',item);
        updateComponentParams(dispatch,'page-header-info',{'updated':true});
      }
      return item;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[accountListInfo])

  /**
  * Method handles the closing of edit nickname modal
  */
  const handleEditNicknameModalClose = () => {
    updateComponentParams(dispatch,props?.id, {'editNicknameModalOpen':false})
  }

  /**
  * Method handles the opening of edit nickname modal
  */
  const handleEditNicknameModalOpen = () => {
    updateComponentParams(dispatch,props?.id, {'editNicknameModalOpen':true})
  }

  return (
    <>
      <Grid className="fx-theme-passport fx-account-summary-card fx-accountinfo fx-grey-background fx-account-summary-card-center fx-account-summary-card-accountgraph-body">
        { permission ? 
        <Grid xs={12} className='fx-account-summary-card-accountgraph-data'>
          <Grid container item xs direction='row' className='fx-accountinfo-account'>      
            <Grid item className='fx-accountinfo-logo'>
              <div id='account-details' onClick={handleLogoClick}>
                <LogoAccount />
              </div>
            </Grid>
            <Grid container item xs direction='column' className='fx-accountinfo-details'>
              <Grid item display='flex' alignItems='center'>
                <FxLink id='account-details' redirect={getKey("selectedAccount")?.id ? "/account/view/" + getKey("selectedAccount")?.id : ''}>
                  <Typography id="account-info" className='fx-account-list-menu-sel'>
                    {getKey("selectedAccount")?.nickName ? getKey("selectedAccount")?.nickName  : 'Passport Account'}&nbsp;&nbsp;
                  </Typography>
                </FxLink>
                  {getKey("selectedAccount")?.id && <FxLink id='edit-nickname-icon' onClick={handleEditNicknameModalOpen} tooltip={true} title='Edit Nickname'>
                    <EditIcon />
                </FxLink>}
              </Grid>
              {getKey("selectedAccount")?.id && <Grid container item xs direction='row'>
                <Grid item className='fx-accountinfo-active-account'>
                  <Typography variant='h3'>
                    {customerBasicInfoList?.params?.accountList?.totalCount > 0 ? 'Passport Account ' : ''}{maskAccountNumber(data?.accountNumber ? data?.accountNumber : getKey("customerInitialAccount")?.accountNumber, false)}
                  </Typography>
                </Grid>
                {getKey("selectedAccount")?.isPrimary && customerBasicInfoList?.params?.ppiDetails?.ppi && <Grid item className='fx-account-info-card-ppi'>
                  <FxLink id='ppi-id-link' onClick={copyPPI} tooltip={true} title={customerBasicInfoList?.params?.ppiDetails?.ppi}>
                    <Typography variant='h4'>
                      <CopyIcon className='fx-thanks-arrow-right' />&nbsp;{truncateChars(customerBasicInfoList?.params?.ppiDetails?.ppi,8)}
                    </Typography>
                  </FxLink>
                </Grid>}
              </Grid>}
              {!getKey("selectedAccount")?.id && getKey("kycPending") !== 'true' && getKey("customerKYC") === 'continue' && customerBasicInfoList?.params?.accountList?.totalCount === 0 && 
                <Grid className='fx-accountinfo-inactive-account'>  
                  <Grid item xs>
                      <Typography id="account-info" className="fx-account-summary-card-center-no-account-text" >Your account is yet to be opened, fill in KYC details to view your account summary</Typography>
                  </Grid>
              </Grid>}
            </Grid>
            {isMobileScreen && getKey("selectedAccount")?.id && getKey("selectedAccount")?.status !== 'INACTIVE' &&<Grid item container xs={3} alignContent="flex-start" justifyContent={'flex-end'}>
              <FxButton
                permissions={accountStatementPermission}
                variant="contained"
                className="fx-button fx-button-rounded"
                title='Download Statement'
                id="account-statement"
                onClick={handleOpen}
              > <DownloadIcon />
              </FxButton>
            </Grid>}
          </Grid>      

            {/** Avaialble Balance Text + amount */}
            <Grid className={`fx-accountinfo-available-balance-text-space fx-accountinfo-section-space
                              ${!getKey("selectedAccount")?.id && getKey("kycPending") !== 'true' && getKey("customerKYC") === 'continue' && customerBasicInfoList?.params?.accountList?.totalCount === 0 ? 'fx-accountinfo-balance-inactive':''}
                              `}>
              <Grid container item xs direction='row'>
                  <Grid item xs={11} className={clsx('fx-accountinfo-available-balance-text', isDebitCardDisplayed ? 'fx-accountinfo-rect-color-debit':'') + ' fx-accountinfo-balance-text'}>
                    <Typography>ACTUAL BALANCE</Typography>
                  </Grid>
                  {ShowInfoToolTip('fx-actual-balance-tooltips','The total amount of money recorded in your account, including funds not yet available for you to use. This includes holds that are not yet posted.')}
              </Grid>
              <Grid container item xs={12}>
                <Grid item className="fx-account-summary-currency">
                  <FxCurrencyView className="balance-amount" id="home-account-info-card-currencyview" value={balanceDetails?.actualBalance?.amount ? balanceDetails.actualBalance.amount : '0.00'} prefix={"$"} />
                </Grid>
              </Grid>

              {/** Reserves and Holds Typography */}
              <Grid container xs className="fx-accountinfo-typography-sub-section">
                {
                  balanceDetails?.reserveAndHoldBalance > 0 ?
                  <FxLink className="fx-accountinfo-hold-split-link" onClick={handleHoldReserveDetailModalOpen}>
                    <span>
                      <Grid item>
                        <Typography className='fx-reserve-balance-grey'>Pending Balance</Typography>
                      </Grid>

                      <Grid item className="fx-accountinfo-typography-sub-section-currency">
                        <FxCurrencyView id="hold-and-reserve-balance" className="fx-account-summary-card-balance-view fx-reserve-balance-grey" value={balanceDetails?.reserveAndHoldBalance ? balanceDetails.reserveAndHoldBalance : '0.00'} prefix={"$"} />
                      </Grid>
                    </span>  
                  </FxLink> : 
                  <Grid display={'flex'}>
                    <Grid item>
                      <Typography className='fx-reserve-balance-grey'>Pending Balance</Typography>
                    </Grid>

                    <Grid item className="fx-accountinfo-typography-sub-section-currency">
                      <FxCurrencyView id="hold-and-reserve-balance" className="fx-account-summary-card-balance-view fx-reserve-balance-grey" value={balanceDetails?.reserveAndHoldBalance ? balanceDetails.reserveAndHoldBalance : '0.00'} prefix={"$"} />
                    </Grid>
                </Grid> 
                }
                <FxModalGeneric
                      id="account-info-holds-reserves-modal"
                      open={props?.holdSplitModalOpen}
                      onClose={handleHoldReserveDetailModalClose}
                      componentProps={{}}
                      className={'fx-modal-small2medium'}
                      component={ViewBalanceDetails}
                    />
                
              </Grid>
              <Grid container item xs direction='row'>
                  <Grid item>
                    <Typography className='fx-reserve-balance-grey'>Available Balance</Typography>
                  </Grid>

                  <Grid item className="fx-accountinfo-typography-sub-section-currency">
                    <FxCurrencyView id="available-balance" className="fx-account-summary-card-balance-view fx-reserve-balance-grey" value={data?.availableBalance ? data?.availableBalance : 0} prefix={"$"} />
                  </Grid>
                  {ShowInfoToolTip('fx-actual-balance-tooltips','The total amount of money in your account that is currently accessible for transactions.')}
                </Grid>
            </Grid>
           
            {/** Faster Funds Access Text & Button */}
            {uiFeat.getFasterFunding().available && props?.fasterFunding && <Grid item container xs={12} className='fx-accountinfo-balance-details fx-accountinfo-section-space'>

                <Grid container display={'flex'} alignItems={'center'}>

                  <Grid item>
                    <span className='fx-metadata-icon'>
                      <ThunderIconCard/>
                    </span>
                  </Grid>

                  <Grid item marginLeft={'0.3rem'}>
                    <Grid item>
                        <Grid item container alignItems={'center'} xs={'auto'} className={'fx-available-faster-funds fx-available-faster-funds-account-info'}>
                            <Typography className="fx-reserve-balance fx-available-faster-funds-account-info-title">Faster Funds Available</Typography>
                            <FxInfoTooltip arrow placement={'bottom'}
                                           className={'fx-available-faster-funds-info-tooltip'}
                                           title={'Your available Faster Funds is a calculation based on several factors assessed by Priority and may change over time'}>
                                <DarkInfoIcon className="fx-dark-grey-icon fx-hover-cursor-pointer" />
                            </FxInfoTooltip>
                        </Grid>
                      <FxCurrencyView id="hold-and-reserve-balance" className="fx-account-summary-card-balance-view fx-reserve-balance-grey fx-reserve-balance-grey-aligned" value={props?.creditData ? props?.creditData : '0.00'} prefix={"$"} />
                    </Grid>
                  </Grid>

                  <Grid item xs className='fx-accountinfo-balance-details-avail-section'>
                  <FxButton id={'fx-ledger-detail-avail-button'} permissions={availFundsPopupPermission} className="fx-button fx-button-theme fx-thunder-icon-svg fx-accountinfo-balance-details-avail-section-button" onClick={handleAvailFund}>
                     ACCESS FUNDS
                    </FxButton>
                    <FxModalGeneric
                      id="account-info-avail-funds-modal"
                      open={props?.isOpen}
                      onClose={handleCloseModal}
                      componentProps={{fasterFundsAvailable: props?.creditData}}
                      className={'fx-modal-small2medium'}
                      component={AvailFunds}
                    />
                  </Grid>
                </Grid>
            
            </Grid>}
  
            {/** Debit Card Section */}
            {((getKey('isIssueDebitCardIndividualAllowed') === "TRUE" && individualCustomer)||(getKey('isIssueDebitCardBusinessAllowed') === "TRUE" && businessCustomer))&& 
            <Grid item xs={12} className="fx-account-info-debit-card-section">
              
              <Grid container direction="row" item xs={12} marginTop={'1.5rem'}>
                <DebitCardList id="debit-card-link-passport" data={customerBasicInfoList?.params?.debitCardlist}/>
              </Grid>
            </Grid>}

        </Grid> 
        
        : 
        
        <FxAccessDenied id={'passport-account-access-denied'} title={'You are not authorized to access this card'} />}

      </Grid>

      {/** Account Statement Download Modal */}
      {props?.open && <AccountStatementDownload id="account-info-asd" open={props?.open} onClose={handleAccountStatementClose} />}
      {props?.editNicknameModalOpen && 
        <FxModalGeneric
            id={"edit-account-nickname"}
            open={props?.editNicknameModalOpen}
            onClose={handleEditNicknameModalClose}
            componentProps={{ accountId: getKey('selectedAccount')?.id, nickname: getKey('selectedAccount')?.nickName}}
            className={'fx-modal-small2medium'}
            component={EditNicknameModal}
            title='Edit Nickname'
        >
        </FxModalGeneric>
      }
    </>
  )
})
