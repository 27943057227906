/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react';
import { Grid, CircularProgress, Divider } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { clean, renderStateError } from '../../Utils/CommonBaseClass';
import { useForm } from 'react-hook-form';
import { AUTH_STRINGS } from '../../../constants/strings';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';
import currencies from '../../Utils/currencies.json';
import countries from '../../../libs/utils/country/country.json';
import usa_state from '../../Utils/usa_states.json';
import FxMaterialMultipleSelect from '../../Input/FxSelect/FXMaterialMultipleSelect';
import { FxSwiftCode } from '../../Input/FxSwiftCode/FxSwiftCode';
const httpClient = HttpClient.getClient();

interface InputErrType {
    type: string;
    message: string;
}

Logger.debug("CreateInternationalExternalAccount.tsx", "create external international account new initializing");
/**
 * Component: CreateInternationalExternalAccountCard
 * Usage: create multiple international external account
 */
// Interface: errors
interface InputErrType {
    type: string;
    message: string;
}
export const CreateInternationalExternalAccountCard: React.FC<any> = React.memo(
    (props) => {
        //#region Varables Declarations
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch();
        const { register, formState: { errors }, handleSubmit, setValue, control, watch } = useForm();
        const [isloading, setIsloading] = React.useState(false);
        const submitButton = 'Add International External Account';
        const history = useHistory();
        const [routingCodeDetails, setRoutingCodeDetails] = React.useState<any>();
        const params = useParams<any>();
        const currencyFixedOptions = [{value:'USD', label:'USD'}];

        //#region Assigning values to variable
        const contactId = params.id;
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Add International External Account', backButton: '/payee/view/' + contactId } } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        // account type data
        const accountTypeData = [
            { label: 'Checking', value: 'CHECKING' },
            { label: 'Savings', value: 'SAVINGS' }
        ];
        // account purpose data
        const accountPurposeData = [
            { label: 'Consumer', value: 'CONSUMER' },
            { label: 'Corporate', value: 'CORPORATE' }
        ];

        //#region Handle Functions
        /**
         * Method to render account number error
         * @param err
         */
        const renderAccountNumberError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.ACCOUNT_NUMBER_IWP_INVALID_LENGTH;
            }
            return err.message;
        };

        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleClose = () => {
            history.push('/payee/view/' + contactId)

        };

        // account number validation constant
        const setAccountNumberValidation = {
            required: true,
            maxLength: 34
        }

        //#region Functions

        /**
         * Method to handle on submit request
         * @param data : form data
         */
        async function onSubmit(data: any) {
            let req: any = {};
            let status: any;
            req = createEIARequest(data)
            const successMessage = 'International External Account Created Successfully!';
            status = await CreateInternationalExternalAccount(clean(req));
            status = processAPIResponse(status);
            if (status.status) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                setTimeout(() => {
                    history.push('/payee/view/' + contactId);
                }, 1000);
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }
            setIsloading(false);
        }

        /**
        * Method to Create the International External Account Request
        * @param data
        * @returns
        */
        function createEIARequest(data: any) {
            const object: any = {};
            const internationalExternalAccountRequest: any = {
                holderType: data.internationalHolderType,
                type: data.internationalAccountType,
                swiftCode: data.swiftCode,
                ...(routingCodeDetails?.internationalCodeLabel && data.internationalRoutingCode && {internationalRoutingCode: data.internationalRoutingCode}),
                holderName: data.internationalAccountHolderName,
                accountNumber: data.internationalAccountNumber,
                acceptedCurrency: data?.acceptedCurrency?.map((ele:any)=>ele.value).filter((currency: any) => currency !== "Select All") || currencyFixedOptions.map((ele:any)=>ele.value),
                "holderAddress": {
                    state: data.internationalCountry !== "US" ? data?.nonUnitedState : data?.unitedState,
                    country: data.internationalCountry,
                    city: data.internationalCity,
                    zip: data.internationalZip,
                    addressLine1: data.internationalAddressLine1,
                    addressLine2: data.internationalAddressLine2,
                }
            }
            if(internationalExternalAccountRequest?.holderAddress?.state === ''){
                delete internationalExternalAccountRequest.holderAddress.state;
            }
            if(internationalExternalAccountRequest?.holderAddress?.zip === ''){
                delete internationalExternalAccountRequest.holderAddress.zip;
            }
            if (data.purpose && data.purpose !== '') {
                internationalExternalAccountRequest.purpose = data.purpose;
            }
            object.internationalExternalAccount = [internationalExternalAccountRequest];
            return object;
        }

        /**
         * Method to call api for create international external account
         * @param paylaoddata : request payload
         */
        async function CreateInternationalExternalAccount(paylaoddata: any) {
            try {
                const url = '/contact/id/' + contactId;
                return await httpClient.post(getCustomerUrl(url, false), paylaoddata);
            } catch (err) {
                Logger.error("CreateInternationalExternalAccount.tsx", "error", err);
                return err;
            }
        }

        return (
            <Grid container id="create-payees-eia-main-grid" xs={12} className='fx-form-grid'>
                <Grid id="create-payees-eia-first-grid" item xs={12} sm={8}>
                    <Grid id="create-payees-eia-sub-grid" container spacing={1} >
                        <Grid id="create-payees-eia-second-grid" item xs={12}>
                        <div className="fx-form-edit-profile flex column">
                                {<form id="create-payees-eia-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard id="create-payees-eia-form-card" className="fx-theme-passport">
                                        <FxCardHeader id="create-payees-eia-form-card-header">

                                        </FxCardHeader>
                                        <FxCardBody id="create-payees-eia-form-card-body" className="fx-info-card fx-margin-top-reducer" >
                                            <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                                {
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <FxLabelView id="customer-signup-individual-info-label-account-iea" className="" >ACCOUNT HOLDER INFORMATION</FxLabelView>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} container spacing={2}>
                                                            <>

                                                                <Grid item xs={12} sm={6}>
                                                                    <FxMaterialSelect
                                                                        register={{ ...register("internationalHolderType") }}
                                                                        control={control}
                                                                        rules={{
                                                                            required: props.isEdit ? false : true,
                                                                        }}
                                                                        className={
                                                                            errors.internationalHolderType
                                                                                ? "border-error-input fx-input-edit"
                                                                                : "fx-input-edit"
                                                                        }
                                                                        id="create-payee-iea-form-card-account-purpose"
                                                                        data={accountPurposeData}
                                                                        name="internationalHolderType"
                                                                        readOnly={props.isEdit ? true : false}
                                                                        label="Holder Type"
                                                                        setValue={setValue}
                                                                        value={
                                                                            context?.data?.body?.destination?.internationalExternalAccount
                                                                                ?.holderType
                                                                                ? context?.data?.body?.destination?.internationalExternalAccount?.holderType
                                                                                : "CORPORATE"
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FxTextEdit
                                                                        register={{ ...register("internationalAccountHolderName") }}
                                                                        className={
                                                                            errors.internationalAccountHolderName
                                                                                ? "border-error-input fx-input-edit"
                                                                                : "fx-input-edit"
                                                                        }
                                                                        control={control}
                                                                        rules={{ required: props.isEdit ? false : true }}
                                                                        id="create-payee-iea-holdername-form-card-amount-textbox"
                                                                        label="Holder Name*"
                                                                        name="internationalAccountHolderName"
                                                                        type="text"
                                                                        variant="outlined"
                                                                        defaultValue={
                                                                            context?.data?.body?.destination?.internationalExternalAccount?.holderName
                                                                                ? context?.data?.body?.destination?.internationalExternalAccount?.holderName
                                                                                : ""
                                                                        }
                                                                        isEditable={props.isEdit ? false : true}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        </Grid>
                                                        <fieldset className="fx-container-create-schdule-summery">
                                                            <legend>Holder Address Details</legend>
                                                            <Grid item container xs={12} sm={12} spacing={2}>
                                                                <Grid item xs={12} sm={12}>
                                                                    <FxTextEdit register={{ ...register('internationalAddressLine1') }} control={control} className={errors['internationalAddressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={'payee-iea-add-addressline1-textbox'} label="Address Line 1*" name={'internationalAddressLine1'} defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.addressLine1 ? context?.data?.body?.destination?.internationalExternalAccount.holderAddress?.addressLine1 : ''}
                                                                        isEditable={props.isEdit ? false : true} />
                                                                </Grid>
                                                                <Grid item xs={12} sm={12}>
                                                                    <FxTextEdit register={{ ...register('internationalAddressLine2') }} control={control} className={errors['internationalAddressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} id={'payee-iea-add-addressline2-textbox'} label="Address Line 2" name={'internationalAddressLine2'} defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.addressLine2 ? context?.data?.body?.destination?.internationalExternalAccount.holderAddress?.addressLine2 : ''}
                                                                        isEditable={props.isEdit ? false : true} />
                                                                </Grid>

                                                                <Grid item xs={12} sm={6}>
                                                                    <FxTextEdit register={{ ...register("internationalCity") }} control={control} rules={{ required: true }} className={errors.internationalCity ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-iea-add-city-textbox" name="internationalCity" label="City*" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.city ? context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.city : ''} isEditable={props.isEdit ? false : true} />
                                                                </Grid>
                                                                {watch('internationalCountry') === "US" ? <Grid item xs={12} sm={6}>
                                                                    <FxMaterialSelect register={{ ...register("unitedState") }} rules={{ required: false }} className={errors.unitedState ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-iea-add-state-textbox" name="unitedState" data={usa_state} label="State" value={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.state || ""}
                                                                        setValue={setValue} readOnly={props.isEdit ? true : false} />
                                                                </Grid> : <Grid item xs={12} sm={6}>
                                                                    <FxTextEdit register={{ ...register("nonUnitedState") }} control={control} rules={{ required: false , maxLength: 3}} className={errors.nonUnitedState ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-iea-add-city-textbox" name="nonUnitedState" label="State" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.state || ""} isEditable={props.isEdit ? false : true} />
                                                                    <div className={'error-message'}>{errors.nonUnitedState && renderStateError(errors.nonUnitedState)}</div>
                                                                </Grid>}
                                                                <Grid item xs={12} sm={6}>
                                                                    <FxMaterialSelect register={{ ...register("internationalCountry") }} rules={{ required: true }} className={errors.internationalCountry ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-iea-add-country-textbox" name="internationalCountry" data={countries} label="Country*" value={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.country ? context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.country : ""}
                                                                        setValue={setValue} readOnly={props.isEdit ? true : false}/>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FxTextEdit register={{ ...register("internationalZip") }} control={control} rules={{ required: false }} className={errors.internationalZip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-iea-add-zip-textbox" name="internationalZip" label="Zip Code" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.holderAddress?.zip || ""} isEditable={props.isEdit ? false : true} />
                                                                </Grid>
                                                            </Grid>
                                                        </fieldset>
                                                        <Grid item xs={12} sm={12}>
                                                            <FxLabelView id="customer-signup-individual-info-label-primary-payee-iea-bank" className="" >BANK ACCOUNT INFORMATION</FxLabelView>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} container spacing={2}>
                                                            <>

                                                                <Grid item container xs={12} sm={12} spacing={2}>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <FxMaterialSelect
                                                                            register={{ ...register("internationalAccountType") }}
                                                                            className={
                                                                                errors.internationalAccountType
                                                                                    ? "border-error-input fx-input-edit"
                                                                                    : "fx-input-edit"
                                                                            }
                                                                            rules={{ required: true }}
                                                                            control={control}
                                                                            id="create-payee-iea-form-card-account-type"
                                                                            name="internationalAccountType"
                                                                            data={accountTypeData}
                                                                            value={
                                                                                context?.data?.body?.destination?.internationalExternalAccount?.type
                                                                                    ? context?.data?.body?.destination?.internationalExternalAccount?.type
                                                                                    : "SAVINGS"
                                                                            }
                                                                            label="Account Type*"
                                                                            setValue={setValue}
                                                                            readOnly={props.isEdit ? true : false}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} className="fx-contact-user-card">
                                                                    <FxMaterialMultipleSelect name='acceptedCurrency'
                                                                        selectAllLabel={'Select All' }
                                                                        control={control}
                                                                        isEdit={props.isEdit ? true : false}
                                                                        id="iea-contact-form-acceptedcurrency"
                                                                        data={currencies}
                                                                        rules={{ required: currencyFixedOptions ? false : true }}
                                                                         limitTags={4}
                                                                         fixedOptions={currencyFixedOptions}
                                                                         readOnly={props.isEdit ? true : false}
                                                                         label='Accepted Currency *'
                                                                         canSearch= {false}
                                                                         register={{ ...register("acceptedCurrency") }}
                                                                         setValue={setValue} />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <FxSwiftCode register={{ ...register("swiftCode") }} setValue={setValue} className={errors.swiftCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="payee-eia-add-swift-number-textbox" label="Swift Code*" name="swiftCode" variant="outlined"
                                                                            defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.swiftCode ? context?.data?.body?.destination?.internationalExternalAccount?.swiftCode : ""}
                                                                            isEditable={props.isEdit ? false : true}
                                                                            onSwiftCodeChange={(data: any)=> setRoutingCodeDetails(data)} />
                                                                    </Grid>
                                                                    {routingCodeDetails?.internationalCodeCharacterLength !== 0 && routingCodeDetails?.internationalCodeLabel &&<Grid item xs={12} sm={12}>
                                                                        <FxTextEdit register={{ ...register("internationalRoutingCode") }} className={errors.internationalRoutingCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control}
                                                                            rules={{ required: false }}  id="iea-contact-add-routingcode-number-textbox"
                                                                            label={routingCodeDetails?.internationalCodeLabel || "International Routing Code"}
                                                                            name="internationalRoutingCode" variant="outlined"
                                                                            defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.internationalRoutingCode ? context?.data?.body?.destination?.internationalExternalAccount?.internationalRoutingCode : ''}
                                                                             />
                                                                    </Grid>}
                                                                    <Grid item xs={12} sm={12}>
                                                                        <FxTextEdit register={{ ...register("internationalAccountNumber") }} className={errors.internationalAccountNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={setAccountNumberValidation} control={control} id="payee-eia-add-account-number-textbox" label="International Bank Account Number*" name="internationalAccountNumber" variant="outlined" defaultValue={context?.data?.body?.destination?.internationalExternalAccount?.accountNumber ? context?.data?.body?.destination?.internationalExternalAccount?.accountNumber : ''} isEditable={props.isEdit ? false : true} />
                                                                        <div className={'error-message'}>
                                                                            {errors.internationalAccountNumber && renderAccountNumberError(errors.internationalAccountNumber)}
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <FxTextEdit register={{ ...register("purpose") }} className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="create-payees-eia-purpose" label="Purpose" name="purpose" variant="outlined" isEditable={props.isEdit ? false : true} />
                                                                    </Grid>
                                                                </Grid>

                                                            </>
                                                        </Grid>
                                                    </>}
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </FxCardBody>
                                        <FxCardFooter id="create-payees-eia-form-card-footer" className="fx-footer">
                                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                                <FxButton variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="payees-eia-cancel-button"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </FxButton>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme"
                                                    id={"payees-add-eia-submit-button"}
                                                    type="submit"
                                                >
                                                    {isloading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    ) : (
                                                        submitButton
                                                    )}
                                                </FxButton>
                                            </Grid>
                                        </FxCardFooter>
                                    </FxCard>
                                </form>}
                            </div>
                        </Grid >
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} ></Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    });