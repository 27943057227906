/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Grid, Typography, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Logger } from "../../../libs/utils/logger";
import HttpClient from "../../../libs/utils/httpClient";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { FxButton } from "../../Action/FxButton";
import FxSnackBar from "../../Utils/fx-snack-bar";
import { getKey } from "../../../libs/utils/storageManager";
import { updateComponentParams } from "../../Utils/CommonBaseClass";
import { FxCurrencyView } from "../../Input/FxCurrency/FxCurrencyView";
const httpClient = HttpClient.getClient();
Logger.debug("AvailFundsCard.tsx", "availing funds for the customer");

/**
 * Component: This Component handles the Avail Funds Popup - managing all the fields and buttons in it
 * Usage: To avail the available faster funds after deducting the fees
 */
export const AvailFunds: React.FC<any> = React.memo((props) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    control,
  } = useForm();
  const [amount,setAmount] = useState("");

  const dispatch = useDispatch();

  /**
   * Method to rest the component on unmount.so that when redirect back page it will call the api again
   * */
  useEffect(
    () => () => {
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
      setCanSubmit(false);
    },[]
  );

  /**
   * Method to handle form submit event
   * @param data :form data
   */
  async function onSubmit(data: any) {
    let status: any;
    const req = {
      amount: data?.amount,
    };
    status = await availFunds(req);
    status = processAPIResponse(status);
    updateComponentParams(dispatch, props?.id, { 'isLoading': false })
    if (status.status) {
      //api success
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: "success",
        text: "Funds Availed Successfully!",
      });
      props.onClose()
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'account-ledger-first-grid' } }); 
    } else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
  }
  /**
   * Method to to call API for amount and fee
   * @param paylaoddata : request payload
   */
  async function getfunds(paylaoddata: any) {
    try {
      const url =
        "account/id/" +
        getKey("selectedAccount")?.id +
        "/fasterFunds/fee?amount=" +
        paylaoddata;
      const data: any = await httpClient.get(getCustomerUrl(url, false));
      if (data) {
        setValue("fee", data?.data?.instantFee);
        setValue("netAmount", data?.data?.remainingAmount);
        setCanSubmit(true);
      }
      return data;
    } catch (err) {
      Logger.error("FxAvailFundsCard.tsx", "error", err);
      return false;
    }
  }

  /**
   * To call the api only when the number of digits exceed 2
   * @param e 
   */
  function onBlurAmount(value: any) {
    if (Number(value) <= Number(props?.metadata?.fasterFundsAvailable)) {
      setValue('amount',value);
      getfunds(value);
      setAmount(value);
    }else{
      setCanSubmit(false);
      setValue("fee", 0.00);
      setValue("netAmount", 0.00);
    }
  }
  

  /**
   * Method to to call API for creation of avail funds
   * @param paylaoddata : request payload
   */
  async function availFunds(req: any) {
    try {
      const url =
        "account/id/" +
        getKey("selectedAccount")?.id +
        "/fasterFunds/access";
      const data: any = await httpClient.post(getCustomerUrl(url, false), req);
      return data;
    } catch (err) {
      Logger.error("FxAvailFundsCard.tsx", "error", err);
      return err;
    }
  }

  // function for closing the modal
  const handleDeleteClose = () => {
    if (props?.onClose) {
        props.onClose()
    }
};

  return (
    <>
      {
        <form onSubmit={handleSubmit(onSubmit)}>
          <FxCard className="avail-funds-popup-modal">
            <FxCardHeader id="avail-funds-header">
              <Grid
                container
                item
                xs={12}
                className="avail-funds-modal-main-grid"
              >
                <Grid xs={11} paddingBottom={'0.75rem'}>
                  <Typography className='avail-funds-header-title'>
                    Access your Faster Funds
                  </Typography>
                </Grid>
                <Grid item container xs={11}>
                  <Typography className="sub-title-avail-funds-modal">
                  Faster Funds Available: ${props?.metadata?.fasterFundsAvailable && props?.metadata?.fasterFundsAvailable >= 0 ? props?.metadata?.fasterFundsAvailable : props?.metadata?.fasterFundsAvailable ? props?.metadata?.fasterFundsAvailable : "0.00"}
                  </Typography>
                </Grid>
               
              </Grid>
            </FxCardHeader>
            <FxCardBody id="avail-funds-modal-body">
              <Grid container item spacing={2} className="fx-info-card-form">
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid container item xs={12}>
                  <FxTextEdit
                    register={{ ...register("amount") }}
                    className={
                      errors.amount
                        ? "border-error-input fx-input-edit clear-number-field-arrow"
                        : "fx-input-edit clear-number-field-arrow"
                    }
                    rules={{ required: false }}
                    prefix="$"
                    type="number"
                    control={control}
                    id="amount"
                    label={"Enter Amount"}
                    showDecimal={true}
                    name="amount"
                    variant="outlined"
                    placeholder="0.00"
                    onBlur={(val) => onBlurAmount(val)}
                    onWheel={(e:any)=>e.target.blur()}
                    setValue={setValue}
                    valuePattern={/-|\+|e|E/} min={0.01}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FxTextEdit
                    register={{ ...register("fee") }}
                    className={"fx-input-edit"}
                    rules={{ required: false }}
                    prefix="$"
                    type="number"
                    control={control}
                    id="fee"
                    label={"Faster Funding Fee"}
                    name="fee"
                    placeholder="0.00"
                    variant="outlined"
                    setValue={setValue}
                    isEditable={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FxTextEdit
                    register={{ ...register("netAmount") }}
                    className={"fx-input-edit"}
                    rules={{ required: false }}
                    prefix="$"
                    type="number"
                    control={control}
                    id="netAmount"
                    label={"Net Amount (After Fee Deduction)"}
                    name="netAmount"
                    variant="outlined"
                    placeholder="0.00"
                    setValue={setValue}
                    isEditable={false}
                  />
                </Grid>
              </Grid>
              <Grid container paddingTop={'0.75rem'}>
              <Grid item xs={12}>
                  <Typography className="avail-funds-modal-notes">
                    NOTE: The faster funded amount of <FxCurrencyView className='fx-account-summary-card-balance-view avail-funds-description-text' value={amount} prefix={"$"}/> will be deducted from your Passport Account at the time of funds settlement.
                  </Typography>
                </Grid>
                <Grid item xs={12} paddingTop={'1.3125rem'}>
                  <Divider />
                </Grid>
                <span className="fx-padding-right-16" />
              </Grid>
            </FxCardBody>

            <FxCardFooter id="avail-funds-Footer" className="fx-footer">
              <Grid
                container
                direction="row"
                display={'flex'}
                justifyContent="flex-end"
                className="fx-modal-footer"
              >
                  <FxButton variant="contained"
                        className="fx-button fx-button-cancel"
                        id={"cancel-avail-funds-popup-button"}
                        onClick={handleDeleteClose}>
                        Cancel
                    </FxButton>
                    <span className="fx-padding-right-16" />
                  <FxButton
                    disableRipple={false}
                    id={"avail-funds-submit-button"}
                    isSubmitting={isSubmitting} 
                    className={canSubmit === true ? "fx-button fx-button-theme":"fx-button fx-button-disabled"}
                    disabled={canSubmit === true ? false : true}
                    type="submit"
                  >
                    Confirm
                  </FxButton>
                </Grid>
            </FxCardFooter>
          </FxCard>
        </form>
      }
    </>
  );
});
