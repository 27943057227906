/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Breadcrumbs, BreadcrumbsProps } from "@mui/material";
import { Logger } from "../../libs/utils/logger";
import { FxLink } from "../Action/FxLink";
import FxLabelView from "../Input/FxLabel/FxLabelView";

Logger.debug("FxBreadCrumb.tsx", "Fx Breadcrumbs initializing")

interface IMenuItem {
    title: string;
    link: string;
}

interface FxBreadCrumbsProps extends BreadcrumbsProps {
    menuItems: IMenuItem[];
}

/**
 * fx bread crumb component
 */

const FxBreadCrumbs: React.FC<FxBreadCrumbsProps> = React.memo(props => {
    function breadCrumbData(i: number, item: any) {
        if ((i + 1) < props.menuItems.length) { return <FxLink id={props.id + '-bread-crumb-item-' + i} redirect={item.link} >{item.title}</FxLink> } else {
            return <FxLabelView id={props.id + '-bread-crumbs-last-item' + i} value={item.title} />
        }
    }
    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb" className={props.className ? props.className : "fx-breadcrumbs"}>
            {props.menuItems &&
                props.menuItems.map((item: any, i: number) => (
                    breadCrumbData(i, item)
                ))}
        </Breadcrumbs>
    );

});

export default FxBreadCrumbs
