/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { FxModalBaseView } from './FxModalBaseView';
import { IDeferredPromise, createDeferredPromise } from '../../../libs/utils/utils';

export type FxModalOptionsType<T = any> = {
    modalClassName?: string;
    metadata?: T;
}

export type FxModalCloseReason = 'cancel' | 'custom';
export type FxModalReturnType<T = any> = {
    reason: FxModalCloseReason;
    payload?: T;
}

export interface IFxModalBase<R = any> {
    show(Content: React.ComponentType<FxModalContentProps>, options?: FxModalOptionsType): Promise<FxModalReturnType<R>>;
}

export type FxModalContentProps<Metadata = any, Return = any> = {
    locked?: boolean;
    metadata?: Metadata;
    onLockedChange?(locked: boolean): void;
    onCloseForce(reason: FxModalCloseReason, payload?: Return): void;
    onClose?: any
}

type StateType = {
    open: boolean;
    locked: boolean;
    options?: FxModalOptionsType;
    Content?: React.ComponentType<FxModalContentProps>;
}

const defaultState: StateType = {
    open: false,
    locked: false,
    options: undefined,
    Content: undefined,
}

const defaultReturn: FxModalReturnType = {
    reason: 'cancel',
}

/**
 * Component for controlling generic modal.
 * For internal purposes only!
 */
export class FxModalBaseController extends React.Component<object, StateType> implements IFxModalBase {
    private _showPromise: IDeferredPromise<FxModalReturnType> | undefined;
    constructor(props: any) {
        super(props);
        this.state = defaultState;
    }
    public show = async (Content: React.ComponentType<FxModalContentProps>, options?: FxModalOptionsType) => {
        if(this.state.open) {
            return defaultReturn;
        }
        this._showPromise = createDeferredPromise();
        this.setState({
            open: true,
            options,
            Content,
        });

        return this._showPromise;
    }

    _handleModalClose = (reason?: FxModalCloseReason, payload?: any) => {
        if(this.state.locked && !reason) {
            return;
        }
        this.setState({
            ...defaultState,
        });
        this._showPromise!.resolve!({
            reason: reason || 'cancel',
            payload,
        });
    }

    _handleLockedChange = (locked: boolean) => {
        this.setState({
            locked,
        });
    }

    render = () => {
        const {open, locked, Content, options} = this.state;
        if(!Content) {
            return null;
        }

        return (
            <FxModalBaseView
                closeDisabled={locked}
                className={options?.modalClassName}
                open={open}
                onClose={this._handleModalClose}
            >
                <Content
                    locked={locked}
                    onCloseForce={this._handleModalClose}
                    onLockedChange={this._handleLockedChange}
                    metadata={options?.metadata}
                />
            </FxModalBaseView>
        );
    }
}
