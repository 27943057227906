import React, { useEffect } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { IFileInfo } from '../../Data/FxAgreementLinkCard';
import { FxAgreementLinkCard } from '../../Data/FxAgreementLinkCard';
import { getKey } from '../../../libs/utils/storageManager';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxButton } from '../../Action/FxButton';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { useForm } from 'react-hook-form';
import HttpClient from '../../../libs/utils/httpClient';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { processAPIResponse } from '../../../libs/utils/utils';
import { FxTextEdit } from '../../Input';
import { FxApplicationBanner } from '../Treasure/FxApplicationBanner';
import { FxAccountLeftLayout } from './FxAccountLeftLayout';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';
Logger.debug("FxAccountAgreement .tsx", "Account Creation")

interface IAccountAgreement {
    id?: string;
    type?: string;
    btnDisabled?: boolean;
    accountAgreementInfo?: Array<IFileInfo>;
    onChange: (event: any) => void;
    onDrawerClose: (event: any) => void;
}

/**
 * @author Vineetha
 * Component: FxAccountAgreement to show account creation agreement slide
 */
export const FxAccountAgreement: React.FC<IAccountAgreement> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const { setValue, control, register, formState: { errors, isSubmitting }, handleSubmit } = useForm();
    const httpClient = HttpClient.getClient();
    const dispatch = useDispatch();

    //to initilize required props
    useEffect(() => {
        updateComponentParams(dispatch, props?.id, { 'btnDisabled': true, accountAgreementInfo: [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * On change handler to enable/disable accept button
     */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        updateComponentParams(dispatch, props?.id, { 'btnDisabled': !event.target.checked })
    }

    /**
     * Method used to create req and call api for account creation
     * @param data request data
     */
    const createCustomerAccount = async (data: any) => {

        const linkedDocuments = props?.accountAgreementInfo?.map((file: IFileInfo) => {
            return {
                purpose: 'AUTHORIZATION',
                document: {
                    type: file?.fileType,
                    name: 'Terms&Condition.pdf',
                    base64encodedContent: file?.base64
                }
            }
        })

        let requestBody: any = {
            isCustomerOwned: true, //will always be true for account
            linkedDocument: linkedDocuments
        }

        if (data?.nickname) {
            requestBody = {
                ...requestBody,
                nickName: data?.nickname
            }
        }

        try {
            const data: any = await httpClient.post('/customer/id/' + getKey('customerId') + '/account', requestBody)
                .then((response) => {
                    return response
                }).catch((error) => {
                    return { ...error };
                })
            return data;
        }
        catch (e) {
            Logger.error('FxAccountAgreement.tsx', 'createCustomerAccount', 'error', e);
        }
    }

    /**
     * Method used to request to create account on clicking on accept
     * @param data request data
     */
    async function handleAccept(data: any) {
        let status = await createCustomerAccount(data);
        status = processAPIResponse(status)
        if (status.status) {
            props.onChange(2);
        } else {
            //api failed
            FxSnackBar.show({
                text: status.message,
            });
        }
    }

    //Terms condition text
    const FeeDetailsTerms = [
        {
            label: (
                <span>By submitting this form, you agree to the Passport Account agreement, to receiving electronic communication from
                    Passport and you certify that the information provided is complete and correct.
                </span>),
            value: 'yes'
        }
    ];

    // Method to reset the component
    useEffect(() => () => {
        updateComponentParams(dispatch, props?.id, { 'btnDisabled': true, accountAgreementInfo: [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container id="create-customer-account-agreement" className="fx-investment-account-agreement">
            <Grid item className={'fx-drawer-left-content fx-drawer-left-content-grey-background'}>
                <FxAccountLeftLayout />
            </Grid>
            <Grid item xs className={'fx-drawer-right-content'}>
                <Grid item xs={12} >
                    <form onSubmit={handleSubmit(handleAccept)}>
                        <Grid container item >
                            <Grid item xs={12}>
                                <FxApplicationBanner text={'Passport Account Agreement'} />
                            </Grid>
                            <Grid item xs={12} className='fx-investment-account-agreement-header fx-margin-left-right-0'>
                                <Typography>ACCOUNT INFO</Typography>
                            </Grid>
                            <Grid item xs={12} marginTop={'2.33rem'} className='fx-form-edit-profile'>
                                <FxTextEdit
                                    register={{ ...register('nickname') }}
                                    className={errors.nickname ? 'border-error-input fx-input-edit' : 'fx-input-edit'}
                                    control={control}
                                    id="create-account-identifier-form-card-nickname"
                                    label="Account Nickname"
                                    name="nickname"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} marginTop={'2.33rem'}>
                                <FxAgreementLinkCard
                                    agreementTypes={['PassportAccount']}
                                    onFileInfoReady={(data) => updateComponentParams(dispatch, props?.id, { accountAgreementInfo: data })}
                                />
                            </Grid>
                            <Grid item container xs={12} className="fx-investment-account-agreement-terms fx-margin-left-right-0">
                                {!!props?.accountAgreementInfo?.length && <Grid item xs={12} marginTop={'2.33rem'} className="fx-investment-account-agreement-terms-text fx-submit-agree">
                                    <FxCheckEdit
                                        rules={{ required: 'Please click Agree' }}
                                        control={control}
                                        id="create-customer-account-terms-condition"
                                        name="CreateAccountTerms"
                                        className="fx-privacy-text"
                                        data={FeeDetailsTerms}
                                        row="vertical"
                                        onChange={(e: any) => {
                                            handleChange(e);
                                        }} setValue={setValue} />
                                </Grid>}
                            </Grid>
                            <Grid item xs={12} sm={12} className="fx-investment-account-agreement-divider"><Divider /></Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={'2rem'}>
                            <Grid item xs={12} textAlign={'right'} paddingBottom='4rem'>
                                <FxButton variant='contained' className='fx-button fx-button-cancel' id="create-customer-back-btn" onClick={props.onDrawerClose}>Back</FxButton>
                                <span className="fx-padding-right-16" />
                                <FxButton
                                    className={`fx-accept-button ${props?.btnDisabled ? 'fx-button-disabled' : 'fx-button-theme'}`}
                                    disabled={props?.btnDisabled}
                                    type='submit'
                                    isSubmitting={isSubmitting}
                                > Accept</FxButton>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Grid>
    )
})