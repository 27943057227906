/* eslint-disable  @typescript-eslint/no-explicit-any */
import { removeKey } from "../../../libs/utils/storageManager";
import { updateComponentParams } from "../../Utils/CommonBaseClass";

/**
   * Method used to handle home icon click
   */
export const handleHomeClick = (dispatch: any) => {
    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'account-list-sub-menu' } });
    updateComponentParams(dispatch, 'layout-passport', { 'accountBasedMenu': false });
    removeKey('selectedAccount');
}

/**
 * Method to handle logo Click
 */
export const handleLogoClick = (history: any, selectedAccount: any, accountList: any, dispatch: any) => {
    if (selectedAccount?.type === 'CASH_BUILDER_PLUS') {
        history.push('/cashbuilder')
    } else if(selectedAccount?.type === 'CASH_BUILDER') {
        history.push('/portfolio')
    }else if (accountList?.resources?.length >= 1) {
        handleHomeClick(dispatch);
        history.push("/home");
    } else {
        history.push("/dashboard");
    }
}