/* eslint-disable  @typescript-eslint/no-explicit-any */
import React  from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { EditRecurring } from './EditRecurring';
import { EditCollectContactRecurring } from './EditCollectContactRecurring';


/**
 * This component loads the Edit Send component
 */

export const EditRecurringHome: React.FC =
    () => {
        const location = useLocation();
        const type = location.pathname.split("/")[1];
        let recurring_source: any = {}
        const params = useParams<any>();
        const id = params.id;
        recurring_source = {
            url: "/transaction/recurring/id/" + id,
            method: "GET"
        }

        const rand = Math.floor(Math.random() * 100) + 1;

        return (
            <Grid item container xs={12}>
                <Grid item xs={12} lg={12} >
                    {
                        type.includes('contact-recurring') ? <EditCollectContactRecurring key="editContactRecurring" id={rand + "-collect-via-contact-edit-recurring-component-" + id} source={recurring_source}></EditCollectContactRecurring> :
                            <EditRecurring key="editRecurring" id={rand + "-edit-recurring-component-" + id} source={recurring_source}></EditRecurring>
                    }
                </Grid>
            </Grid>

        )
    }
