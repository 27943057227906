import React, { useEffect } from 'react'; // we need this to make JSX
import { getKey, logout, setKey } from '../../../libs/utils/storageManager';
import AuthService from '../../../libs/services/authService';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useDispatch } from 'react-redux';
/**
 * Component: Logout
 * Usage: For user to log out
 */
const Logout: React.FC = () => {
    const dispatch = useDispatch();

        /**
         * Method used to handle logout
         */
        useEffect(() => {
            dispatch({ type: "LOGOUT" }); // Reset Redux store
            const theme = getKey("theme");
            logout();
            const protocol = window.location.protocol;
            const host = window.location.host;
            const redirectUrl   = protocol + '//' + host;
            AuthService.doLogout({redirectUri: redirectUrl});
            setKey("theme", theme);
            FxSnackBar.show({
                autoHideDuration: 1000,
                severity: 'success',
                text: 'Signed out successfully',
            });
        }, []);

        return (
            <>
            </>
        );
    };

export default Logout;
