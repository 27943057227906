/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SagaHelper } from "../../libs/utils/sagaHelper";

const lock: any = {};
/**
 *
 * @param id
 * @param dispatch
 * @param cb
 * @returns image
 */
export function getImage(id: any, dispatch: any, cb: any): any {
  if (lock[id]) {
    return;
  }
  lock[id] = true;
  new SagaHelper(dispatch).getAPIAsync('file/' + id, { responseType: 'blob' }, function (data: any, error: any) {
    lock[id] = false;
    if (data && data.body) {
      //let imageURL;
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(data.body);
      return cb(imageUrl);
      /* data.body.blob().then((blob: any) => {
          imageURL = window.URL.createObjectURL(blob);
      }); */
    }
    else {
      return cb("/svg/no-photo.svg");
    }
  })
}


//get image from a source also support the def image
export function getImageSrc(id: any, dispatch: any, defimage: string, cb: any): any {
  new SagaHelper(dispatch).getAPIAsync('file/' + id, { responseType: 'blob' }, function (data: any, error: any) {
    if (data && data.body) {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(data.body);
      return cb(imageUrl);
    }
    else {
      return cb(defimage ? defimage : "/svg/logo.svg");
    }
  })
}