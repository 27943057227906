import React from "react";
import { Button, Grid, Modal, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Logger } from "../../libs/utils/logger";
import HttpClient from "../../libs/utils/httpClient";
import FxCardHeader from "../Container/FxCardHeader";
import FxCard from "../Container/FxCard";
import FxCardBody from "../Container/FxCardBody";
import FxCardFooter from "../Container/FxCardFooter";
import { getCustomerUrl, processAPIResponse } from "../../libs/utils/utils";
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete-icon.svg";
import { useSelector } from "react-redux";
import FxSnackBar from "../Utils/fx-snack-bar";
import { useDispatch } from 'react-redux';
import { getTargetBalancePrefrences } from "../Utils/CommonBaseClass";
import { useHistory } from "react-router";
const httpClient = HttpClient.getClient();

Logger.debug("FxDisableTBA.tsx", "fx Disable TBA");

/**
 * @author Vishesh
 * Component used to disable the TBA account
 */
interface FxDisableTBAProps {
  header: string;
  description?: string;
  buttonTitle?: string;
  id?: string;
  close: () => void;
  buttonName?: String;
  disableAllAccounts?: boolean;
}


/**
 * Generic modal to disable tba
 */
export const FxDisableTBA: React.FC<FxDisableTBAProps> = React.memo((props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * Selector to retrieve the 'subAccounts' from the Redux store.
   * @returns {Array} - Returns the `subAccounts` array from the Redux store if it exists, otherwise `undefined`.
   */
  const targetBalanceSweep = useSelector((state: any) => {
    if (state.data['customer-basic-info']) {
      return state.data['customer-basic-info']?.params?.targetBalanceSweep
    }
  });

  const subAccounts = targetBalanceSweep?.subAccounts;

  /**
   * Method will be called on cancel/close of popup
   */
  const handleClose = () => props?.close();

  /**
   * Method to call api for disable target balance rule on submit
   */
   const handleSubmit = async () => {
    try {
      // Filter out the subAccounts based on the condition
      const filteredAccounts = subAccounts?.filter((subAccount: any) => subAccount?.account?.id !== props?.id);
  
      // Prepare the payload
      const payload = {
        targetBalanceSweep: props?.disableAllAccounts
          ? []
          : [{
              ...targetBalanceSweep,
              subAccounts: filteredAccounts,
            }],
      };
  
      // Make the API call and process the response
      const apiResponse = await disableTargetBalanceRule(payload);
      const processedStatus = processAPIResponse(apiResponse);
  
      // Handle success or failure cases
      if (processedStatus.status) {
        FxSnackBar.show({
          autoHideDuration: 2000,
          text: processedStatus?.responseHeaders?.warning ||  processedStatus.message,
          severity: processedStatus?.responseHeaders?.warning ? 'warning' : 'success',
        });
        handleClose();
        history.push('/home');
        getTargetBalancePrefrences(getCustomerUrl("preference"), dispatch);
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'account-list-sub-menu' } });
      } else {
        FxSnackBar.show({
          text: processedStatus.message,
          severity: 'error',
        });
      }
    } catch (error) {
      // Handle any unexpected errors
      Logger.error("Error occurred during handleSubmit:", error);
      FxSnackBar.show({
        text: 'An unexpected error occurred. Please try again later.',
        severity: 'error',
      });
    }
  };

  /**
   * Method to call api for disable target balance rule
   * @param payloadData : request payload
   */
  async function disableTargetBalanceRule(payloadData: any) {
    try {
      const url = '/preference';
      const data: any = await httpClient.post(getCustomerUrl(url, false), payloadData)
      return data;
    } catch (err) {
      Logger.error("disableTargetBalanceRule.tsx", "error", err);
      return err;
    }
  }

  return (
    <>
      <Modal
        style={{ overflow: "scroll" }}
        open={true}
        onClose={handleClose}
        disableEnforceFocus={true}
        disableAutoFocus={false}
        className="fx-modal fx-modal-small"
      >
        <div className="modal-content">
          <div className="modal-body">
            <FxCard className="fx-container-tax-wire-checkbox">
              <FxCardHeader
                id="create-del-header"
                className="fx-card-header"
              >
                <Grid container className="fx-modal-close-icon" justifyContent="flex-end">
                  <IconButton onClick={handleClose} id="ea-modal-close-icon" size="large">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </FxCardHeader>

              <FxCardBody
                id="del-create-modal-body"
                className="fx-info-card fx-delete-info"
              >
                <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                  <Grid item container justifyContent="center" xs={12}>
                    <IconButton
                      id="del-modal-delete-icon"
                      className="fx-modal-delete-icon"
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  <Grid container justifyContent="center" item xs={12} className="fx-delete-title">
                    <Typography id="title-delete-modal" variant="h3">
                      {props.header}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="center" item xs={12} className="fx-delete-description">
                    <Typography id="title-delete-grid-two" variant="h5" sx={{ fontWeight: "500 !important", pt: 1 }}>
                      {props.description}
                    </Typography>
                  </Grid>
                </Grid>
              </FxCardBody>

              <FxCardFooter id="ea-modal-Footer" className="fx-footer">
                <Grid container direction="row" justifyContent="center" className="fx-modal-footer fx-margin-bottom-16">
                    <Button variant="contained"
                        className="fx-button fx-button-cancel"
                        id="cancel-button"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <span className="fx-padding-right-16" />
                    <Button
                        disableRipple={false}
                        className="fx-button fx-button-theme fx-button-delete"
                        id={"disable-button"}
                        onClick={handleSubmit}
                    >
                        {props?.buttonName}
                    </Button>
                </Grid>
            </FxCardFooter>
            </FxCard>
          </div>
        </div>
      </Modal>
    </>
  );
});
