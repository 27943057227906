/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { FxLink } from "../Action/FxLink";
import { FxPopOver } from "../Data/FxPopOver";
import { checkMenuDrawerPermission } from "./Passport";
import { RegisterComponent } from "../../libs/saga/dataSaga";
import { updateComponentParams } from "../Utils/CommonBaseClass";
import { ReactComponent as SettingsIcon } from '../../assets/svg/settings-menu-icon.svg';
import { ReactComponent as TriangleDown } from '../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as TriangleUp } from '../../assets/svg/triangle-up-icon.svg';
import { getKey } from "../../libs/utils/storageManager";
import { useIsMobileScreen } from "../../libs/utils/utils";
import UIFeatureImpl from "../../libs/services/uiFeatures";

/**
 * This component handles the Settings Menu Options
 */
export const SettingsMenu: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));

    const { drawerExpand, anchorEl, onMenuSelect, submenuExpand } = props
    const dispatch = useDispatch()
    const isSmallScreen = useIsMobileScreen();
    const uiFeat = UIFeatureImpl.getInstance();
    const jointTennancy=uiFeat.getJointTenancyFeature().available;

    /**
     * handle click away from popover
     */
    const handleClose = (event: any,item:any) => {
        event.stopPropagation();
        item !== 'backdropClick' && onMenuSelect && onMenuSelect(event);
        updateComponentParams(dispatch, props?.id, { anchorEl: null, submenuExpand: false })
    };
    const open = Boolean(anchorEl);

    /**
     * Options for settings menu
     */
    const menuOptions = [
        !jointTennancy && checkMenuDrawerPermission('company') && { id: 'Company Profile', label: 'Company Profile', url: '/company' },
        !jointTennancy && checkMenuDrawerPermission('authorisedusers') && { id: 'Auth Users', label: 'Auth Users', url: '/authorizedusers' },
        getKey('userType') === 'BUSINESS' && getKey('enableTargetBalanceAccount') === 'true' && checkMenuDrawerPermission('targetBalanceRule') && { id: 'Target Balance Service', label: 'Target Balance Service', url: '/target-balance-service' },
        !jointTennancy && checkMenuDrawerPermission('roles') && { id: 'Roles & Permissions', label: 'Roles & Permissions', url: '/roles' },
        !jointTennancy && (getKey('isTransactionApprovalEnabled') === 'true' && checkMenuDrawerPermission('approvalpolicy')) && { id: 'Approval Policy', label: 'Approval Policy', url: '/approvalpolicy' },
        !jointTennancy && checkMenuDrawerPermission('webhooks') && { id: 'Webhooks', label: 'Webhooks', url: '/webhooks' },
        !jointTennancy &&checkMenuDrawerPermission('apitoken') && { id: 'Generate API Key', label: 'Generate API Key', url: '/customerapi/list' },
        jointTennancy &&{ id: 'Owner Profile', label: 'Owners', url: '/co-owner/profile' },
    ].filter(Boolean);
    /**
      * Method to handle settings icon click
      */
    const handleSettingsIconClick = (event: any) => {
        event.stopPropagation();
        !!drawerExpand && updateComponentParams(dispatch, props?.id, { submenuExpand: true })
        updateComponentParams(dispatch, props?.id, { anchorEl: event.currentTarget })
    }

    return (<>
        {!drawerExpand && <>
            {(checkMenuDrawerPermission('company') || checkMenuDrawerPermission('authorisedusers') || checkMenuDrawerPermission('roles') || checkMenuDrawerPermission('approvalpolicy') || checkMenuDrawerPermission('apitoken'))
                && <Grid item xs={12} className='fx-sidenav-collapse-close-popover' onClick={(e) => handleSettingsIconClick(e)}>
                    <Grid item xs={12} container justifyContent='end'>
                        <FxLink id="menu-left-settings"><SettingsIcon /></FxLink>
                    </Grid>
                </Grid>}
        </>}
        {drawerExpand && <>
            {(checkMenuDrawerPermission('company') || checkMenuDrawerPermission('authorisedusers') || checkMenuDrawerPermission('roles') || checkMenuDrawerPermission('approvalpolicy') || checkMenuDrawerPermission('apitoken'))
                &&
                <Grid item className={`fx-sidenav-bottommenu-item ${submenuExpand ? 'fx-sidenav-expanded-theme-sel' : 'fx-sidenav-expanded-theme-not-sel'}`} onClick={(e) => handleSettingsIconClick(e)}>
                    <FxLink id="menu-left-portal-switch" className={`fx-sidenav-bottommenu-main `} >
                        <Grid item xs={12} container justifyContent='space-between' alignItems='center' paddingTop='0.5rem'>
                            <SettingsIcon style={{ marginLeft: '1rem' }} />
                            <Grid className='fx-sidenav-bottommenu-middle' item xs={!isSmallScreen ? 7 : 11}>Settings</Grid>
                            <Grid item justifyContent='center' alignItems='center' display='flex' xs={!isSmallScreen ? 2 : 1} className='fx-sidenav-bottommenu-main-right'>{!submenuExpand ? <TriangleUp /> : <TriangleDown />}</Grid>
                        </Grid>
                    </FxLink>
                </Grid>}
        </>}
        <Grid item container xs={12}>
            <FxPopOver
                id={'settings-menu-list'}
                open={open}
                refElement={anchorEl}
                onClose={handleClose}
                className={`${!drawerExpand ? 'fx-sidenav-collapse-portal-switch-menu settings-nav' : 'fx-sidenav-expanded-popup'}`}
                anchorOrigin={{
                    vertical: drawerExpand ? 'top' : 'bottom',
                    horizontal: drawerExpand ? 'center' : 'right',
                }}
                transformOrigin={{
                    vertical: drawerExpand ? 'bottom' : 'bottom',
                    horizontal: drawerExpand ? 'center' : 'left',
                }}
                data={menuOptions}
            >
            </FxPopOver>
        </Grid>
    </>)
})