/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'; // we need this to make JSX compile
import clsx from 'clsx';
import { SwipeableDrawer, Grid, Popover, AppBar, Toolbar, CssBaseline, IconButton } from '@mui/material';
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { clearSession, getKey } from "../../libs/utils/storageManager";
import { ReactComponent as CloseIconRight } from '../../assets/svg/rightmenu-close.svg';
import { ReactComponent as MoneyIcon } from '../../assets/svg/move-money-new.svg';
import { ReactComponent as DropDownIcon } from '../../assets/svg/dropdown-icon.svg';
import { ReactComponent as LogoutIcons } from '../../assets/svg/logout-icon.svg';
import { ReactComponent as SendDownIcon } from '../../assets/svg/send-down-arrow.svg';
import { ReactComponent as SendUpIcon } from '../../assets/svg/send-up-arrow.svg';
import FxRightDrawer from '../Utils/fx-right-drawer';
import  FxTopBanner from '../Utils/fx-top-banner';
import { TopBarActions } from './TopBarActions';
import { RegisterComponent } from '../../libs/saga/dataSaga';
import { AccountStatementDownload } from '../Page/Index/Passport/AccountStatementDownload';
import { FxMenu } from '../Data/FxMenu';
import { FxButton } from '../Action/FxButton';
import { FxPageHeader } from './FxPageHeader';
import { checkPermission, getScheduleType, updateComponentParams } from '../Utils/CommonBaseClass';
import {  isAnyAchControl } from '../Utils/ThemeChecker';
import ShowReadme from '../Utils/ReadMeLink';
import { DrawerExpand } from './Drawer/DrawerExpand';
import { DrawerCollapse } from './Drawer/DrawerCollapse';
import { PartnerDrawerExpand } from './Drawer/PartnerDrawerExpand';
import { PartnerDrawerCollapse } from './Drawer/PartnerDrawerCollapse';
import { fetchPortalData } from '../Utils/ThemeCheckerPartner';
import { AlertDrawer } from '../Page/Alerts/AlertDrawer';
import { AlertsButtonComponent } from '../Page/Alerts/AlertButtonComponent';
import UIFeatureImpl from '../../libs/services/uiFeatures';

const drawerWidth = '20.5rem';
const mobileDrawerWidth = '100vw';
let selectedAccount:any;


export const LayoutPassport: React.FC<any> = (props: any) => {

    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch()
    const location = useLocation();

    //special treatment require for mobile screens
    const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
    const uiFeat = UIFeatureImpl.getInstance();
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available
    const individualCustomer = uiFeat.getIndividualCustomerFeature().available
    const allowSendTransaction = uiFeat.getAllowSendFeature().available;

  /**
   * Getting the selected portal
   */
  const selectedPortalState = useSelector((state: any) =>
    fetchPortalData(state)
  );
    const useStyles = makeStyles<{ display: string, width: string }>()((theme, { display, width }) =>{
        return {
        root: {
          display,
          width: '100%',
        },
        appBar: {
          zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        },
        appBarShift: {
          // marginLeft: drawerWidth,
          // width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
        menuButton: {
          marginRight: 0,
          padding: 0,
          marginLeft: 0
        },
        hide: {
          display: 'none',
        },
        drawer: {
          width: width,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          minHeight: '100vh'
        },

        drawerOpen: {
          width: width,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
        drawerClose: {
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowX: 'hidden',
          width: theme.spacing(7) + 44,
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 28,
          },
        },

        content: {
          flexGrow: 1,
          marginTop: '7.5rem',
        },
        nested: {
          paddingLeft: theme.spacing(4),
        },
      }});

    const displayRoot = props?.showTerms && props.showTerms === 'NO' ? 'flex' : 'none';
    const { classes } = useStyles({ "display": displayRoot, width: isSmallScreen ? mobileDrawerWidth :  drawerWidth });
    const [open, setOpen] = useState(false);
    const [accountStatementOpen, setAccountStatementOpen] = useState(false);
    const history = useHistory();
    const userName: any = getKey('firstName');
    const [anchorEl, setAnchorEl] = useState(null);
    //which caret to draw based on button selection

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [customerType, setCustomerType] = useState(getKey('userType'));

    const [collectUrl, setCollectUrl] = useState<any>();
    const [sendUrl, setSendUrl] = useState<any>();
    const [moveMoneyBtn, setMoveMoneyBtn] = useState<boolean>(true)

    const accountListInfo = useSelector((state: any) => state.data['account-list-sub-menu'] || null);
    selectedAccount = accountListInfo?.params?.selectedOption;
    const accountType = selectedAccount?.type;

    /**
     * Hide Move Money Button for Investment Account Dashboard
     */
    useEffect(()=>{
      dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'menu-drawer-expand' } });
      updateComponentParams(dispatch, 'menu-drawer-expand', { 'selectedOption': selectedAccount })
      updateComponentParams(dispatch, 'menu-drawer-collapse', { 'selectedOption': selectedAccount })
      if((['CASH_BUILDER_PLUS', 'CASH_BUILDER'].includes(accountType)) || !props?.accountBasedMenu ){
        setMoveMoneyBtn(false)
      }
      else{
        setMoveMoneyBtn(true)
      }
      
    }, [selectedAccount, props?.accountBasedMenu])

    //close the popover
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    //show hide drawer
    const toggleDrawer = (event: any) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setOpen(!open)
    };

    /*
    * Method handles the onclick event for opening modal
    */
    const handleOpen = () => {
      setAccountStatementOpen(true);
    };
    // Method handles the closing of the account statement modal
    const handleAccountStatementClose = () => {
      setAccountStatementOpen(false);
    }

    /**
     * create collect button  permission
     */
    const collectPermission = useMemo(() => {
      let hasPermission = false;
      const permissionData: any = [ ]
        permissionData.push({
          entity: "Accounts",
          name: "Card Collect",
          operation: "CREATE"
        },
        {
          entity: "Accounts",
          name: "Check Collect",
          operation: "CREATE"
        },
        {
          entity: "Recurring Transaction",
          name: "CARD Collect",
          operation: "CREATE"
        })

        if(businessCustomer && (isAnyAchControl(getKey('collectAchControl')))){
          permissionData.push({
            entity: "Accounts",
            name: "ACH Collect",
            operation: "CREATE"
          },
            {
              entity: "Recurring Transaction",
              name: "ACH Collect",
              operation: "CREATE"
            }
          )
        }
      
      if ((checkPermission({ entity: "Accounts", name: "Ach Collect", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Ach Collect", operation: "Create" })) && isAnyAchControl(getKey('collectAchControl'))) {
          setCollectUrl('/transaction/collect/add/my-account-ach');
      } else if (checkPermission({ entity: "Accounts", name: "Check Collect", operation: "Create" })) {
        setCollectUrl('/transaction/collect/add/mobile-check-deposit');
      } else if (checkPermission({ entity: "Accounts", name: "Card Collect", operation: "Create" })) {
        setCollectUrl('/transaction/collect/add/onetime-card');
      }

      permissionData?.forEach((item: any) => {
        if (checkPermission(item) === true) {
          hasPermission = true
          return;
        }
      })
      return hasPermission
    }, [businessCustomer, individualCustomer, getKey("isUserDetailsAvailable")])

    /**
     * create send button  permission
     */
    const sendPermission = useMemo(() => {
      let hasPermission = false;
      const permissionData: any = [
        {
          entity: "Accounts",
          name: "Card Send",
          operation: "Create"
        },
        {
          entity: "Accounts",
          name: "Virtual Card Send",
          operation: "Create"
        },
        {
          entity: "Recurring Transaction",
          name: "Virtual Card Send",
          operation: "Create"
        },
        {
          entity: "Accounts",
          name: "Book Send",
          operation: "Create"
        },
        {
          entity: "Recurring Transaction",
          name: "Book Send",
          operation: "Create"
        }, {
          entity: "Accounts",
          name: "Check Send",
          operation: "Create"
        },
        {
          entity: "Recurring Transaction",
          name: "Check Send",
          operation: "Create"
        }, {
          entity: "Accounts",
          name: "Wire Send",
          operation: "Create"
        }, {
          entity: "Recurring Transaction",
          name: "Wire Send",
          operation: "Create"
        }, {
          entity: "Accounts",
          name: "Ach Send",
          operation: "Create"
        },
        {
          entity: "Recurring Transaction",
          name: "Ach Send",
          operation: "Create"
        }, {
          entity: "Accounts",
          name: "International Wire Send",
          operation: "Create"
        },
        {
          entity: "Recurring Transaction",
          name: "International Wire Send",
          operation: "Create"
        }
      ];

      const contactPermission= {
        entity: "Other Entities",
        name: "Contact",
        operation: "VIEW"
      };

      if (checkPermission({ entity: "Accounts", name: "Ach Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Ach Send", operation: "Create" })) {
        setSendUrl('/transaction/send/add/ach');
      } else if (checkPermission({ entity: "Accounts", name: "Wire Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Wire Send", operation: "Create" })) {
        setSendUrl('/transaction/send/add/wire');
      } else if (checkPermission({ entity: "Accounts", name: "International Wire Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "International Wire Send", operation: "Create" })) {
        setSendUrl('/transaction/send/add/internationalWire');
      } else if (checkPermission({ entity: "Accounts", name: "Check Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Check Send", operation: "Create" })) {
        setSendUrl('/transaction/send/add/check');
      } else if (checkPermission({ entity: "Accounts", name: "Book Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Book Send", operation: "Create" })) {
        setSendUrl('/transaction/send/transfer');
      } else if (checkPermission({ entity: "Accounts", name: "Card Send", operation: "Create" })) {
        setSendUrl('/transaction/send/card');
      } else if (checkPermission({ entity: "Accounts", name: "Virtual Card Send", operation: "Create" }) || checkPermission({ entity: "Recurring Transaction", name: "Virtual Card Send", operation: "Create" })) {
        if (businessCustomer && getKey("sendViaVirtualMethod")?.toLowerCase() === 'true') {
          setSendUrl(checkPermission(contactPermission) ? '/transaction/send/contact/add/virtualcard' : '/transaction/send/onetime/add/virtualcard');
        }
      }

      permissionData?.forEach((item: any) => {
        if (checkPermission(item) === true) {
          hasPermission = true
          return;
        }
      })
      return hasPermission
    }, [businessCustomer, individualCustomer, getKey("isUserDetailsAvailable")])

    /**
     * to get page titles
     */
    const pageTitle = useSelector((state: any) => {
      if (state.data['page-title-details']) {
        return state.data['page-title-details']
      } else {
        return { config: { params: {} } };
      }

    });


    const [anchorMenuEl, setAnchorMenuEl] = React.useState<any>(false);
    const openMenu = Boolean(anchorMenuEl);

    //menu options
    const menuOptions = []
    if(collectPermission){
        menuOptions.push({ id: 'collect-money', title: getScheduleType(businessCustomer,'title') + ' Money', icon: <SendDownIcon/> })
    }
    if(sendPermission){
      menuOptions.push({ id: 'send-money', title: 'Send Money', icon: <SendUpIcon/> })
    }

    /**
     * handle click event of menu
     * @param event
     */
    const handleOptionClick = (event: any) => {
      const mapper: any = {
        "Collect Money": collectUrl,
        "Send Money": sendUrl,
        "Deposit Money": collectUrl
      }
      setAnchorMenuEl(null);
      history.push(mapper[event]);


    };

  /**
   * useEffect to set leads dropdown 
   */
  useEffect(() => {
    location?.pathname === '/leads-home' && updateComponentParams(dispatch, "layout-passport", { 'selectedPage': 'LEADS' })
  }, [location?.pathname])


    /**
     * handle menu button click
     * @param event
     */
    const handleButtonClick = (event: any) => {
      setAnchorMenuEl(event.currentTarget)
    };
    /**
    * Method handles the closing of the menus
    */
    const handleClose = () => {
      setAnchorMenuEl(null);
    };

    //drawer component
    const drawer =
      <div
        className={clsx(classes.drawer, "fx-appmenu", {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}

        role="presentation"
        onClick={toggleDrawer}
      >
        {open && (props?.selectedPage === undefined || props?.selectedPage === 'ACCOUNT') && <DrawerExpand id={'menu-drawer-expand'} userName={userName} customerType={customerType} handleOpen={handleOpen} toggleDrawer={toggleDrawer} selectedPortal={selectedPortalState?.data?.selectedPortal} accountBasedMenu={props?.accountBasedMenu}></DrawerExpand>}
        {open && props?.selectedPage === 'LEADS' && <PartnerDrawerExpand userName={userName} toggleDrawer={toggleDrawer} selectedPage={props?.selectedPage} selectedPortal={selectedPortalState?.data?.selectedPortal}/>}
      </div>
      
  const [customAnchorEl, setCustomAnchorEl] = useState(null);
  const alertsOpen = Boolean(customAnchorEl);
      
  /**
  * Method to handle click custom object access
  * @param event 
  */
  const handleAlertsOpen = (event: any) => {
    setCustomAnchorEl(event.currentTarget);
  };

  /**
  * Method to handle close custom object access
  * @param event 
  */
  const handleAlertsClose = () => {
    setCustomAnchorEl(null);
  };

  /**
   * method to handle Go Back
   */
  const handleGoBack = () => {
    window.location.href = getKey('goBackUrl');
    clearSession();
  }

    //items like profile,company,sign out
    const toprightActions = <Grid item className="fx-top-right-actions">
        <Grid container alignItems={'center'} item spacing={1} flexWrap={'nowrap'}>
             {getKey('isProxyLogin')===true && <Grid item >
            <FxButton className="fx-profile-icon fx-profile-icon-admin"
              id="menu-top-go-back-button"
              onClick={handleGoBack}
              title={'Go Back'}
              aria-label={'Go Back'}
            > {getKey('goBackPortal')==='PM'?'Back to PM Portal':'Back to Admin Portal'}
            </FxButton>
          </Grid>}
            <Grid item >
                {uiFeat.getSSOUserFeatures().available && uiFeat.getSSOUserFeatures().button}
            </Grid>
            <Grid item >
              {((collectPermission || sendPermission) && moveMoneyBtn) && props?.selectedPage !== 'LEADS' && <FxButton onClick={handleButtonClick} id="menu-top-move-money-icon-button" className="fx-top-move-money"
                title={'Move Money'}
                aria-label="more"
                aria-controls={openMenu ? 'long-menu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup="true">
                <MoneyIcon className="fx-svg-up" />&nbsp; {!isSmallScreen &&  <> Move Money  &nbsp; &nbsp;</>}<DropDownIcon className='fx-top-move-money-dropdown-fill-color'/>
              </FxButton>}
              <FxMenu id={"move-money-options"} className ="fx-top-move-money-menu" icons={true} open={openMenu} anchorEl={anchorMenuEl} options={menuOptions} onClose={handleClose} onMenuClick={handleOptionClick} menuShadow ></FxMenu>          </Grid>
              {props?.selectedPage !== 'LEADS' && <Grid item>
                <ShowReadme id="read-me" ></ShowReadme>
              </Grid>}
            {props?.selectedPage !== 'LEADS' && getKey('customerId') && getKey('authUserId') && <AlertsButtonComponent handleAlertsOpen={handleAlertsOpen} />}  
            <Grid item>
                {isSmallScreen ?
                    <Grid className={'mobile-view-expand-logo-icon'} onClick={toggleDrawer} id="expand-menu">
                        <CloseIconRight />
                    </Grid>
                    :
                    <IconButton
                        component="a"
                        className="fx-profile-icon fx-logout"
                        id="menu-top-signout-button"
                        onClick={() => history.push('/logout')}
                        title={'Logout'}
                        aria-label={'Logout'}
                        size="large"> <LogoutIcons />
                    </IconButton>}
            </Grid>
        </Grid>
    </Grid>

    /**
     * define the passport layout
     */

    return (
      <Grid container className="fx-main-grid">
          <React.Suspense fallback="Loading...">
            <div className={clsx(classes.root, 'fx-passport-root')}>
              <CssBaseline />
              <FxTopBanner />
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                }, "fx-appbar-top-passport")}
              >
                <Toolbar >

                  <Grid container item xs={12} justifyContent={'space-between'} className="fx-top-area fx-top-area-passport">
                    <Grid container item xs={6} className="fx-top-area-passport-left" spacing={2} alignItems={'center'}>
                      <Grid item >
                        {pageTitle &&
                          <FxPageHeader id="page-header-info" data={pageTitle?.data} />
                        }
                      </Grid>
                    </Grid>
                    <Grid container item xs={5} className="fx-top-area-passport-right">
                      <Grid container item xs={12} alignItems="flex-start" justifyContent="flex-end"  >
                        {toprightActions}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Popover
                    id={id}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    className="home-popover-menu"
                  >
                    <TopBarActions handleClose={handlePopoverClose} ></TopBarActions>

                </Popover>
              </Toolbar>
            </AppBar>
                  <FxRightDrawer/>
                  <AlertDrawer id='alerts-right-drawer' alertsOpen={alertsOpen} handleAlertsClose={handleAlertsClose}/>
            <SwipeableDrawer
              anchor={isSmallScreen ? "right" : "left"}
              open={open}
              onClose={toggleDrawer}
              onOpen={toggleDrawer}
              className="fx-drawer"
            >
              {drawer}
            </SwipeableDrawer>

              <main className={clsx(classes.content, "fx-passport-content")}>
                <Grid item container>
                  <Grid item className={isSmallScreen ? "fx-hidden" : "fx-passport-content-top"}>
                    {!open && (props?.selectedPage === undefined || props?.selectedPage === 'ACCOUNT') && <DrawerCollapse id={'menu-drawer-collapse'} userName={userName} customerType={customerType} handleOpen={handleOpen} toggleDrawer={toggleDrawer} selectedPortal={selectedPortalState?.data?.selectedPortal} accountBasedMenu={props?.accountBasedMenu}></DrawerCollapse>}
                    {!open && props?.selectedPage === 'LEADS' && <PartnerDrawerCollapse userName={userName} toggleDrawer={toggleDrawer} selectedPortal={selectedPortalState?.data?.selectedPortal} />}
                  </Grid>
                  <Grid item xs className="fx-passport-content-main">{props.routes}</Grid>
                </Grid>
              </main>
            </div>
          <AccountStatementDownload id="passport-account-statement-download" open={accountStatementOpen} onClose={handleAccountStatementClose} />
        </React.Suspense>


      </Grid>
    )
  }

/** function using to highlight the menu based on url
* @param path
* @returns the match index
*/
export function getSelectedPath(location:any) {
   const path = location.pathname?.split('/')[1]
   const mapper: any = {
     "" : 1,
     "dashboard": 1,
     "leads-home": 1,
     "leads": 2,
     "lead":2,
     "customers": 3,
     "profile":1,
     "account":1,
     "changepassword":1,
     "portfolio":1,
     "authorizedusers": 2,
     "debit-card/physical":2,
     "accountledgers": 3,
     "ledgerBatchDetails":3,
     "batchedviewledgers":3,
     "transaction":4,
     "transactionsHistory":4,
     "transactions":4,
     "investmentInstructions":5,
     "recurring": 5,
     "externalaccounts": 6,
     "internationalexternalaccounts":6,
     "internationalexternalaccount":6,
     "externalaccount":6,
     "payees": 7,
     "payee":7,
     "roles":8,
     "role":8,
     "company":9,
     "profile/edit":9,
     "merchant":9,
     "approvals": 10,
     "pendingApprovals": 10,
     "approvedApprovals": 10,
     "rejectApprovals": 10,
     "debitcards": 11,
     "home":12,
     "cashbuilder":1
   }
   return mapper[path]
 }

 /**
 * function to check transaction approval permission
 */
 export function checkTransactionApprovalPermission(){
     const uiFeat = UIFeatureImpl.getInstance();
     const businessCustomer = uiFeat.getBusinessCustomerFeature().available
     return  getKey('isTransactionApprovalEnabled') === 'true' && 
     (checkMenuDrawerPermission('transactions_wire_send_approve') || checkMenuDrawerPermission('transactions_book_send_approve') || 
     checkMenuDrawerPermission('transactions_check_send_approve') || checkMenuDrawerPermission('transactions_ach_send_approve') || 
     checkMenuDrawerPermission('transactions_internationalWire_send_approve') || checkMenuDrawerPermission('transactions_virtual_card_send_approve')) && 
     (selectedAccount?.type !== 'DEPOSIT' && businessCustomer)
 }

/**
 * function to check permission
 */
export function checkMenuDrawerPermission(data:any) {
  const permissionObject: any = {
    'accounts':{
      entity: "Accounts",
      name: "Account",
      operation: "View"
    },
    'authorisedusers': {
      entity: "Authorized User",
      name: "User",
      operation: "View"
    },
    'accountledgers': {
      entity: "Accounts",
      name: "Ledger",
      operation: "View"
    },
    'transactions_card_collect': {
      entity: "Accounts",
      name: "Card Collect",
      operation: "View"
    },
    'transactions_check_collect': {
      entity: "Accounts",
      name: "Check Collect",
      operation: "View"
    },
    'transactions_ach_collect': {
      entity: "Accounts",
      name: "Ach Collect",
      operation: "View"
    },
    'transactions_wire_send': {
      entity: "Accounts",
      name: "Wire Send",
      operation: "View"
    },
    'transactions_book_send': {
      entity: "Accounts",
      name: "Book Send",
      operation: "View"
    },
    'transactions_check_send': {
      entity: "Accounts",
      name: "Check Send",
      operation: "View"
    },
    'transactions_ach_send': {
      entity: "Accounts",
      name: "Ach Send",
      operation: "View"
    },
    'transactions_internationalWire_send': {
      entity: "Accounts",
      name: "International Wire Send",
      operation: "View"
    },
    'recurring_wire_send': {
      entity: "Recurring Transaction",
      name: "Wire Send",
      operation: "View"
    },
    'recurring_check_send': {
      entity: "Recurring Transaction",
      name: "Check Send",
      operation: "View"
    },
    'recurring_book_send': {
      entity: "Recurring Transaction",
      name: "Book Send",
      operation: "View"
    },
    'recurring_ach_send': {
      entity: "Recurring Transaction",
      name: "Ach Send",
      operation: "View"
    },
    'recurring_internationalWire_send': {
      entity: "Recurring Transaction",
      name: "International Wire Send",
      operation: "View"
    },
    'externalaccounts': {
      entity: "Other Entities",
      name: "External Account",
      operation: "View"
    },
    'payees': {
      entity: "Other Entities",
      name: "Contact",
      operation: "View"
    },
    'roles': {
      entity: "Role",
      name: "Role",
      operation: "View"
    },
    'transactions_virtual_card_send': {
      entity: "Accounts",
      name: "Virtual Card Send",
      operation: "View"
    },
    'recurring_virtual_card_send': {
      entity: "Recurring Transaction",
      name: "Virtual Card Send",
      operation: "View"
    },
    'transactions_wire_send_approve': {
      entity: "Accounts",
      name: "Wire Send",
      operation: "Approve"
    },
    'transactions_book_send_approve': {
      entity: "Accounts",
      name: "Book Send",
      operation: "Approve"
    },
    'transactions_check_send_approve': {
      entity: "Accounts",
      name: "Check Send",
      operation: "Approve"
    },
    'transactions_ach_send_approve': {
      entity: "Accounts",
      name: "Ach Send",
      operation: "Approve"
    },
    'transactions_internationalWire_send_approve': {
      entity: "Accounts",
      name: "International Wire Send",
      operation: "Approve"
    },
    'transactions_virtual_card_send_approve': {
      entity: "Accounts",
      name: "Virtual Card Send",
      operation: "Approve"
    },
    'debitcards': {
      entity: "Accounts",
      name: "Debit Card",
      operation: "View"
    },
    'recurring_ach_collect': {
      entity: "Recurring Transaction",
      name: "ACH Collect",
      operation: "VIEW"
    },
    'recurring_card_collect': {
      entity: "Recurring Transaction",
      name: "CARD Collect",
      operation: "VIEW"
    },
    'webhooks': {
      entity: "Other Entities",
      name: "Webhook",
      operation: "View"
    },
    'targetBalanceRule': {
      entity:"Business Customer",
      name:"Preference",
      operation:"CREATE"
    }
  }
  return checkPermission(permissionObject[data]);
}