import { init as initApm, ApmBase } from '@elastic/apm-rum';

declare global {
  interface Window {
    ELASTICSEARCH_APM_CONFIG: {
      serviceName: string,
      serverUrl: string,
      environment: string
    };
  }
}

const elasticSearchConfig: any = window.ELASTICSEARCH_APM_CONFIG;

const apm: ApmBase = initApm({
  serviceName: elasticSearchConfig['serviceName'], // Service Name
  serverUrl: elasticSearchConfig['serverUrl'], // Elastic APM server URL
  environment: elasticSearchConfig['environment'], // 'production' or 'development'
  serviceVersion: '1.0.0', // Optional: version of your app
});

export default apm;