import React from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { Logger } from "../../../libs/utils/logger";
import { AccountInfoCard } from "../Index/Passport/AccountInfoCard";

/**
 * @author Vishesh
 * Component: FxAccountinfoCard to show Treasury accounts on home screen
 */

/**
 * Interface for the props of FxAccountInfoCard component
 */
interface AccountInfoCardProps {
  accounts: {
    id: string;
    icon: React.ReactNode;
    title?: string;
    subtitle?: string;
    description: string;
    subdescription?: string;
    callback?: () => void;
    buttonText: string;
    accountData?: any;
  }[];
  id: string;
  handleAccountChange?: (id: string, type: string) => void;
}

Logger.debug(
  "FxAccountInfoCard.tsx",
  "FxAccountInfoCard initializing"
);
/**
 * This component handles the account info cards on home screen
 */
export const FxAccountInfoCard: React.FC<AccountInfoCardProps> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  
  return (
    <>
      {props?.accounts
        ?.sort((a: any, b: any) => (b.accountData ? 1 : 0) - (a.accountData ? 1 : 0))
        ?.map(({ id, icon, title, subtitle, description, subdescription, callback, buttonText, accountData }: any, index: number) => {
          if (accountData) {
            return <AccountInfoCard data={accountData} id={`account-info-card-${index}`} handleAccountChange={props?.handleAccountChange} />
          }

          return <Grid key={id} id={id} container className={ props?.accounts?.length > 1 ? "fx-margin-bottom-2" : ''}>
            { props?.accounts?.length > 1 && <Divider className="fx-width-100" />}
            {title && <Grid item xs={12} container alignItems="center" className={'fx-account-info-icon ' + (props?.accounts?.length > 1 ? "fx-account-info-card fx-margin-bottom-16" : '')}>
              {icon}
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 1 }}>{title}</Typography>
              </Grid>
            </Grid>}
            <Typography sx={{ fontStyle: 'italic', fontSize: '14px !important' }}>{subtitle}</Typography>
          <span>
              <Typography className="fx-margin-top-2 fx-font-size">{description}</Typography>
              <Typography className="fx-font-size" >{subdescription}</Typography>
          </span>
            <Grid container alignItems="left">
              <Button
                variant="outlined"
                onClick={callback}
                className="fx-margin-top-2 account-info-button">
                {buttonText}
              </Button>
            </Grid>
          </Grid>
      })}
    </>
  );
});
