import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as AccountCreationIconPassport } from '../../../assets/svg/onboarding-image.svg';
import { ReactComponent as AccountCreationIcon } from '../../../assets/svg/onboarding-image-mxm.svg';
import { getKey } from '../../../libs/utils/storageManager';
import { LogoAccount } from '../../Utils/Logo';
import { Logo } from '../../Utils/Logo';
import { RegisterComponent } from '../../../libs/saga/dataSaga';

interface IFxBaseLayout {
}

/**
 * @author Vineetha
 * Component used inside the account creation drawer as a left layout 
 */
export const FxAccountLeftLayout: React.FC<IFxBaseLayout> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const theme = getKey('theme');
    const customerName = getKey("CustomerName") || getKey('CustomerIndividualFullName');

    return (
        <Grid container id="account-creation-drawer-left-layout" className={'fx-treasure-drawer-left-layout'}>
            <Grid item xs={12} className={'fx-treasure-left-layout-header'}>
                <Grid item xs={2} className={'fx-logo'}>
                    <LogoAccount />
                </Grid>
                <Grid item xs={10} className={'fx-left-layout-header'}>
                    <h2>Welcome!</h2>
                    <h2 className='fx-account-creation-name'>{customerName}</h2>
                </Grid>
            </Grid>
            <Grid item className='fx-treasure-left-layout-header-icon fx-padding-top-bottom-45'>
                <Grid item xs={12} className="fx-treasure-passport-icon">
                    <h1>Open a new</h1>
                    <div className='fx-drawer-left-logo'><Logo showFull /></div>
                    <h1>Account Now! &nbsp;</h1>
                </Grid>

                <Grid className="fx-treasure-banner fx-padding-top-bottom-80">
                    {theme === 'mxm' ? <AccountCreationIcon /> : <AccountCreationIconPassport />}
                </Grid>
                <Grid item xs={12}>
                    <Typography className='fx-new-invite-sub-header'>Simple Pricing, No Hidden Charges</Typography>
                </Grid>
                <hr />
            </Grid>
        </Grid>
    )
})