/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { put, takeEvery } from "redux-saga/effects";

function* formDataProcessor(action: any) {
    const componentId = action.payload.id;
    const source = action.payload.source;
    let api = prepareAPIEndpoint(source.api.url);
    api = typeof api == "function" ? api(action.payload.id, action.payload.props) : api;
    const payload = {
        method: source.method,
        url: api,
        id: componentId,
        data: action.payload.data
    };
    yield put({
        type: "CALL_API",
        payload: payload
    });
}
function prepareAPIEndpoint(api: string): any {

    if (api.trim().match(/^function\(/)) {
        const functionName = "apiTransformer";
      // eslint-disable-next-line prefer-const
        let apiTransformer = function (id: any, data: any) { };
        eval(functionName + " = " + api);
        return apiTransformer;
    }
    return api;
}

export default function* formActionSaga() {
    yield takeEvery("FORM_ACTION", formDataProcessor);
}