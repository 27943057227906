/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { Logger } from '../../../libs/utils/logger';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { InternationalExternalAccountInfoCard } from './InternationalExternalAccountInfoCard';
import { useDispatch } from 'react-redux';

Logger.debug("AccountConfigurationsCard.tsx", "Account Configuration initializing");
/**
 * Component: internationalExternalAccountCard
 * Usage: To display external account profile cards
 */

export const InternationalExternalAccountCard: React.FC<any> = React.memo((props) => {
        let context: any;
        let account_info: any = {};
        const dispatch = useDispatch();
        //check if the user is on mobile device for responsive content
        let accountNumber: any = '';
        ({ context, props } = RegisterComponent(props));
        /**
         * Check if international external account data available
         */
        if (context && context.data && context.data.body) {
            account_info = context.data.body;
            if (account_info?.card?.cardNumberLast4) {
                accountNumber = account_info.card.cardNumberLast4
            } else if (account_info?.accountNumberLast4) {
                accountNumber = account_info.accountNumberLast4
            }
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'International External Account ** ' + accountNumber,backButton:'/internationalexternalaccounts' } }});
        }

        /**
         * Method to rest the component on unmount.so that when redirect back page it will call the api again
         */
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        },[])

        //check api call completed or not
        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
            <>
                <Grid container className={"fx-container"}>
                    <Grid container xs={12} alignItems="flex-start" justifyContent="space-between">
                        <Grid container xs={12} sm={8} className="fx-container-left" >
                            {dataLoadStatus ?
                                (context?.data?.body && <InternationalExternalAccountInfoCard id="international-external-account-details-info-card" data={account_info} />)
                                :
                                <div className={'fx-margin-bottom-2 fx-width-100'}>
                                    <FxSkeltonList height="25rem" />
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    })