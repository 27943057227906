/* eslint-disable  @typescript-eslint/no-explicit-any */    
/* eslint no-case-declarations: "off" */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SagaHelper } from "./sagaHelper";
import { getFormHelper } from "../../components/Container/FxForm";
import { Logger } from "./logger";
Logger.debug("actionHandler.tsx", "Action Handler initializing")

export interface ActionArgs {
    type: string;
    payload: any;
    code?: string;
    path?: string;
}
export interface ActionDef {
    function: string;
    args: any;
}

export default function handleAction(action: any, dispatch: any, event: any = null, history: any = null, callback: any = null, formHelper: any = null, request: any = null) {
    if (!action) {
        return;
    }
    if (typeof action == "string") {
        const actionF = compileFunction(action);
        Logger.info("actionHandler.tsx", "info", "req", request);
        return actionF(event, new SagaHelper(dispatch, history), formHelper, request);
    }
    const source = action.args && action.args.source ? action.args.source : '';

    switch (action.function) {
        case "redux_action":
            return dispatch(action.args);
        case "open_popup":
            /* let payload = {
                id:action.args.popid,
                data:{open:true,args:action.args.data}
            }
            return dispatch({"type": "CONTEXT_COMPONENT_UPDATE_PARAM",payload:payload}); */
            return new SagaHelper(dispatch).openPopup(action.args.popid, action.args.data)
        case "open_popover":
            /* let payload = {
            id:action.args.popid,
            data:{open:true,args:action.args.data}
            }
            return dispatch({"type": "CONTEXT_COMPONENT_UPDATE_PARAM",payload:payload}); */
            return new SagaHelper(dispatch).openPopover(action.args.popid, action.args.data)
        case "redirect":
            /* var regex = new RegExp('^(?:[a-z]+:)?//', 'i');
            if (regex.test(action.args.path)) {
                if (action.args.target) {
                    return window.open(action.args.path, '_blank');
                }
                return window.location.href = action.args.path;
            } else {
                return history.push(action.args.path);
            } */
            return new SagaHelper(dispatch, history).redirect(action.args.path, action.args.target)
        case "moveNextAndSaveId":
            /* var regex = new RegExp('^(?:[a-z]+:)?//', 'i');
            if (regex.test(action.args.path)) {
                if (action.args.target) {
                    return window.open(action.args.path, '_blank');
                }
                return window.location.href = action.args.path;
            } else {
                return history.push(action.args.path);
            } */
            return new SagaHelper(dispatch).moveNextAndSaveId(action.args.id, action.args.key, action.args.value)
        case "previous":
            //check for navigation history and go back to previous page or close tab
            try {
                if (document.referrer === "") {
                    //if browser refuses to close window by script, reload before close
                    const objWindow: any = window.open(window.location.href, "_self");
                    objWindow.close();
                    break;
                }
            } catch (e) {
                //go back to last page on error or if browser refuses to close window
                Logger.error("actionHandler.tsx", "error", e);
                return history.goBack();
            }
            return history.goBack();
        case "custom_function": {
            const func = function (e: any, f: any = undefined) { };
            eval(" func = " + action.args.code);
            return func(event, { formHelper: getFormHelper(), sagaHelper: new SagaHelper(dispatch) });
        }
        case "call_api":
            const asyncAPI:any = source.method === 'DELETE' ? new SagaHelper(dispatch).deleteAPIAsync : (source.method === "PUT") ? new SagaHelper(dispatch).putAPIAsync : new SagaHelper(dispatch).postAPIAsync;
            asyncAPI(source.api, event, source.header ? source.header : null, function (data: any, error: any) {
                if (callback) {
                    return callback(data, error);
                }
            })
            break;
        case "call_get_api":
            const asyncGetAPI:any = new SagaHelper(dispatch).getAPIAsync;
            asyncGetAPI(source.api, source.header ? source.header : null, function (data: any, error: any) {
                if (callback) {
                    return callback(data, error);
                }
            })
            break;

    }
}


export function compileFunction(code: any) {
    const functionName:any = function (data: any, sh: any, fh: any = null, req: any = null) { };
    eval("functionName = " + code);
    return functionName;
}


//this can be use where we are using the generic data node params
//such as data.session, data.request etc 
export function compileFunctionData(code: any) {
    if (code && code.trim().match(/^function\(/)) {
        const functionName:any = function (props: any) { };
        eval("functionName = " + code);
        return functionName;
    }
    else {
        return code;
    }
}
//prepare source for the request
export function prepareSource(events: any, eventname: any, props: any, data: any) {
    //let source = JSON.parse(JSON.stringify(context.config.events.onSubmit.args.source));
    const source:any = JSON.parse(JSON.stringify(events[eventname].args.source));
    source.method = source.api.method ? source.api.method : source.method;
    source.header = source.api.header ? source.api.header : source.header;
    //let onSubmit = JSON.parse(JSON.stringify(context.config.events.onSubmit));
    const event:any = JSON.parse(JSON.stringify(events[eventname]))
    const api:any = prepareAPIEndpoint(source.api.url);
    if (source.api.transformer) {
        const transformer:any = prepareTransformer(source.api.transformer);
        data = transformer(props.id, data);
    }
    source.api = typeof api == "function" ? api(source, props) : api;
    event.args.source = source;
    event['mode'] = props.mode;
    return { event: event, data: data }
}


export function prepareAPIEndpoint(apiData: string): any {
    if (apiData.trim().match(/^function\(/)) {
        const functionName = "api";
        const api:any = function (id: any, data: any) { };
        eval(functionName + " = " + apiData);
        return api;
    }
    return apiData;
}
export function prepareTransformer(code: string): any {
    const functionName = "transformed";
    const transformed:any = function (id: any, data: any) { };
    eval(functionName + " = " + code);
    return transformed;
}

export function prepareAPIBody(body: string): any {
    if (typeof body == "object") {
        return body;
    }
    if (body && body.trim().match(/^function\(/)) {
        const functionName = "data";
        const data:any = function (id: any, data: any, SagaHelper: any) { };
        eval(functionName + " = " + body);
        return data;
    }
    return body;
}