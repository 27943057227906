import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { matchPath } from 'react-router';
import { Logger } from './libs/utils/logger';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './libs/store';
import AuthService from './libs/services/authService';
import { BrowserRouter as Router } from "react-router-dom";
import './apm';

Logger.debug("index.tsx","Application index init")

/**
 * the index file
 */


const renderApp = () => ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <Router  >
    <App id={'app-global-store'}/>
    </Router>
  </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

/**
 * Bypassing keyclocks for certain url which not uder login
 */

const excludedPaths = ["/auth/user-registration/:id", "/auth/upload-avatar/:id", "/auth/terms/:id","/signup/invitation/:token","/signup/success",'/virtual-card/:token'];
  let isExcludedPath = false;
  for (let i = 0; i < excludedPaths.length; i++) {
    const match = matchPath(window.location.pathname, { path: excludedPaths[i], exact: true, strict: false }); // match path with :id
    if (match) {
      isExcludedPath = true;
      break;
    }
  }

/**
 * Call renderApp() to by pass keyclock flow
 */

if (isExcludedPath) {
  renderApp()
} else {
  //   /**
  //    * Inititialing keyclock by passing react app
  //    */
  AuthService.initKeycloak(renderApp);
}

//renderApp();
//AuthService.initKeycloak(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

