/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { IFilterColumns } from '../../../types/common.interfaces';
import { DataGrid } from '../../Data/DataGrid';
import { Logger } from '../../../libs/utils/logger';

Logger.debug("VirtualCardPaymentHistoryList.tsx", "Virtual Card Payment history initializing")

/**
 * This component handles the list of Transaction List
 */
export const VirtualCardPaymentHistoryList: React.FC<any> = React.memo(
    (props) => {       

        const [paymentHistoryList, setPaymentHistoryList] = useState([]);

        const column: IFilterColumns = {
            "title": "Select Columns to Show",
            "columns": [                
                {
                    "label": "DATE",
                    "enable": true,
                    "fieldName": "scheduleDate",
                    "type": "fri://fieldtype/date",
                    "default": true,
                    
                },
                {
                    "label": "PURPOSE",
                    "enable": true,
                    "fieldName": "purpose",
                    "type": "fri://fieldtype/custom",
                    "default": true
                }, 
                {
                    "label": "AMOUNT",
                    "enable": true,
                    "fieldName": "amount",
                    "type": "fri://fieldtype/currency",
                    "default": true                    
                }                            
            ]
        }
        
        /**
         * Method to format transaction list.
         */
        function transactionList(data: any) {
            const newData: any = [];
            data?.map((item: any, i: number) => {
                newData[i] = { ...data[i] }                                
                
            });
            return newData;
        }       
     
        useEffect(() =>
        {
            if(props?.data){
                const list = transactionList(props.data)
                setPaymentHistoryList(list)
            }
           
        },[props?.data])

        return (

            <Grid id="virtual-card-payment-history-list-grid" item xs={12}>
                <Grid container xs={12} className="fx-layout-list" >
                {<Grid id="virtual-card-payment-history-list-grid" item xs={12}>
                                <DataGrid id="virtual-card-payment-history-list"  tableClass="fx-table-passport" data={paymentHistoryList} column={column} pagination={false} pageNumber={1} pageSize={25} resultPerPage={25} />
                            </Grid>}
                </Grid>
            </Grid>
        )
    })

