import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxAccountLeftLayout } from './FxAccountLeftLayout';
import { FxDialogCard } from '../../Utils/FxDialogCard';
import { ReactComponent as CongratulationIconPassport } from '../../../assets/svg/CongratulationCard.svg';
import { ReactComponent as CongratulationIcon } from '../../../assets/svg/congratulations-card-mxm.svg';
import { useDispatch } from 'react-redux';
import { getKey } from '../../../libs/utils/storageManager';

Logger.debug("FxAccountCongratulation.tsx", "Account Creation")

interface IAccountDrawer {
    id: string,
    onDrawerClose: (event: any) => void;
}

/**
 * @author Vineetha
 * Component: FxAccountCongratulation to show congratulations page up on sucessful account creation
 */
export const FxAccountCongratulation: React.FC<IAccountDrawer> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const theme = getKey('theme');

    /**
     * Function to refresh the accounts section and close opened drawer
     * @param event 
     */
    const goToDashboard = (async (event: any) => {
        props.onDrawerClose(event);
    })

    // Method to reset the component
    useEffect(() => () => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'account-list-sub-menu' } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container id="account-sucess-slide" >
            <Grid item className={'fx-drawer-left-content fx-drawer-left-content-grey-background'}>
                <FxAccountLeftLayout />
            </Grid>
            <Grid item xs className={'fx-drawer-right-content'}>
                <Grid xs={12} className={'fx-congratulation-card'}>
                    <FxDialogCard
                        messageContent={
                            <Grid container justifyContent={'center'}>
                                <p className='fx-congratulation-card-text1'> Your new Passport Account is ready to use.</p>
                            </Grid>
                        }
                        Icon={theme === 'mxm' ? CongratulationIcon : CongratulationIconPassport}
                        title={'Congratulations!'}
                        buttonText={'Continue'}
                        onButtonClick={goToDashboard}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
})