/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useIsMobileOrTabletScreen } from '../../../../libs/utils/utils';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { FxCurrencyView } from '../../../Input/FxCurrency/FxCurrencyView';
import { ReactComponent as DashedLine } from '../../../../assets/svg/dashed-line.svg';

/**
 * Component used to draw leads insight
 */
export const LeadsInsight: React.FC<any> = React.memo(props => {
  let context: any;
  ({ context, props } = RegisterComponent(props));

  const isMobileOrTabScreen = useIsMobileOrTabletScreen();
  const tempArray=context?.data?.body?.invitationSummary;
 

/**
 * Invitation summary object
 */
const invitationSummary:any={
  LEAD:{
    label:'TOTAL LEADS',
    value:0
  },
  SENT:{
    label:'INVITED',
    value:0
  },
  ACCEPTED:{
    label:'PROSPECTING',
    value:0
  },
  ONBOARDED:{
    label:'CUSTOMERS',
    className:'digest-grid fx-theme-color-currency',
    value:0
  },
}


const newInvitationSummaryData = useMemo(() => {
  let sortedArray = [];
  if (tempArray) {
    const order = ["LEAD", "SENT", "ACCEPTED", "ONBOARDED"];
    // Create a shallow copy of the array before sorting
    sortedArray = [...tempArray].sort((item1, item2) => {
      return order.indexOf(item1.status) - order.indexOf(item2.status);
    });
  }
  return sortedArray;
  }, [context?.data?.body?.invitationSummary]);



  return <Grid id="lead-insights-main-grid" item container xs={12} className={'fx-lead-insights'}>
    <FxCard id="lead-insights-card" className="fx-theme-passport">
      <FxCardHeader>
        <Typography variant="h3">Lead Insights</Typography>
      </FxCardHeader>
      <FxCardBody id="lead-insights-card-body" className="fx-info-card fx-margin-top-reducer fx-lead-insights-card-body">
        <Grid container justifyContent={'space-between'} spacing={2}>
          <Grid item  xs={isMobileOrTabScreen ? 12 : 8} >
            <Grid className={'fx-lead-insights-card-body-items'} container justifyContent={'space-between'} >
            {/* {context?.data?.body && getleadInsights(context?.data?.body?.invitationSummary)} */}
              {context?.data?.body?.invitationSummary && newInvitationSummaryData.map((item:any,index:any)=>{
                      if (item?.status !== 'DISABLED' && item?.status !== 'ONBOARDED') {
                return <><Grid xs={'auto'} item>
                  <Typography className='fx-lead-insights-card-body-items-title'>
                    <span>{invitationSummary[item?.status]?.label}</span>
                  </Typography>
                  <Grid item className="fx-digest-grid">
                    <FxCurrencyView hideDecimal={true} value={item?.count} />
                  </Grid>
                </Grid>
                  {index !==2 &&<Grid xs={'auto'} >
                    <span><DashedLine /></span>
                  </Grid>}</>
                      } else{
                        return null
                      }
              })}
            </Grid>
          </Grid>

          <Grid item xs={isMobileOrTabScreen ? 12 : 4} >
            <Grid className={'fx-lead-insights-card-body-items'}>
            {context?.data?.body?.invitationSummary && context.data.body.invitationSummary.map((item:any)=>{
              if (item?.status ==='ONBOARDED') {
              return<Grid xs={'auto'} item>
                <Typography className='fx-lead-insights-card-body-items-title'>
                  <span>{invitationSummary[item?.status]?.label}</span>
                </Typography>
                <Grid item className="fx-digest-grid fx-theme-color-currency">
                  <FxCurrencyView hideDecimal={true} value={item?.count} />
                </Grid>
              </Grid>}
              else{
                return null
              }
            })
          }
            </Grid>
          </Grid>
        </Grid>
      </FxCardBody>
    </FxCard>
  </Grid>
})
