/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { createDeferredPromise } from '../../../libs/utils/utils';
import { IDeferredPromise } from '../../../libs/utils/utils';
import { FxRightDrawerContentType, FxRightDrawerView } from './FxRightDrawerView';

interface IState<ReturnType> {
    Content?: FxRightDrawerContentType<ReturnType>;
    open: boolean;
}

export interface IFxRightDrawer<ReturnType> {
    show(Content: FxRightDrawerContentType<ReturnType>): Promise<ReturnType | undefined>;
    closeForce(): void;
}

export class FxRightDrawerController<ReturnType> extends React.Component<object, IState<ReturnType>> implements IFxRightDrawer<ReturnType> {

    private _returnResultPromise: IDeferredPromise<ReturnType | undefined> | undefined;

    public show = async(Content: FxRightDrawerContentType<ReturnType>) => {
        this._returnResultPromise = createDeferredPromise();
        this.setState({
            Content,
            open: true,
        });
        return this._returnResultPromise;
    }

    public closeForce = () => {
        this.setState({
            Content: undefined,
            open: false,
        });
    }

    _handleClose = (_: React.SyntheticEvent<any> | Event, payload?: ReturnType) => {
        this.setState({
            Content: undefined,
            open: false,
        });
        this._returnResultPromise?.resolve?.(payload)
    }

    render = () => {
        return (
            <FxRightDrawerView
                {...this.state}
                onClose={this._handleClose}
            />
        );
    }
}
