import React, { useCallback, useEffect } from 'react'
import { Grid, Button, Typography } from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { ReactComponent as TShape } from '../../../../assets/svg/T-shape.svg';
import { ReactComponent as LIcon } from '../../../../assets/svg/L-shape.svg';
import HttpClient from '../../../../libs/utils/httpClient';
import { clean, getTargetBalancePrefrences, maskAccountNumber, updateComponentParams } from '../../../Utils/CommonBaseClass';
import { Logger } from '../../../../libs/utils/logger';
import { FxButton } from '../../../Action/FxButton';
import { useDispatch, useSelector } from 'react-redux';
import FxMaterialSelect from '../../../Input/FxSelect/FxMaterialSelect';
import { ReactComponent as AddIcon } from '../../../../assets/svg/add-new-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/delete-icon.svg';
import FxCheckEdit from '../../../Input/FxCheck/FxCheckEdit';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { getCustomerUrl, processAPIResponse } from '../../../../libs/utils/utils';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import { FxSkeltonList } from '../../Cards/FxSkelton';
import { useHistory } from 'react-router';
import FxCardBody from '../../../Container/FxCardBody';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import { FxTextEdit } from '../../../Input';
import { ReactComponent as EditIcon } from '../../../../assets/svg/transaction-edit.svg';
import { FxDisableTBA } from '../../../Data/FxDisableTBA';

const httpClient = HttpClient.getClient();

interface ISelectData {
    label: number | string | React.ReactElement;
    value: string | number;
    disabled?: boolean
}
interface ITargetBalanceRule {
    id?: string;
    key?: string;
    btnDisabled?: boolean;
    isDataLoaded?: boolean;
    CashBuilderPassportAcc?: Array<ISelectData>;
    passportAcc?: Array<ISelectData>;
    actualPassportAcc?: Array<ISelectData>;
    currentMasterAccount?: Array<any>
    currentSubAcounts?: Array<any>
    metadata?: any;
    enableEdit?: boolean;
    onClose?: () => void;
    disableTBAModalOpen?: boolean;
}

Logger.debug("TargetBalanceRule.tsx", "TBR Modal initializing")

/**
 * @author Vineetha
 * This component handles the Target Balance Service
 */
export const TargetBalanceRule: React.FC<ITargetBalanceRule> = React.memo((props) => {
    ({ props } = RegisterComponent(props));

    const { register, formState: { errors, isSubmitting }, handleSubmit, watch, setValue, control, clearErrors, reset } = useForm();
    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * to get targetBalanceSweep details
     */
    const customerInfo = useSelector((state: any) => {
        if (state.data['customer-basic-info']) {
            return state.data['customer-basic-info']?.params
        }
    });

    const { targetBalanceSweep, accountList: { resources: accountList } } = customerInfo
    const isTargetBalanceEnabled = targetBalanceSweep?.masterAccount?.id ? true : false;

    //to initilize required props
    useEffect(() => {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Target Balance Service' } } });
        updateComponentParams(dispatch, props?.id, { 'btnDisabled': true, 'isDataLoaded': false, enableEdit: false });
        getCustomerAccountList(accountList)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect to update the master account
    useEffect(() => {
        isTargetBalanceEnabled && setValue('masterAccount', props?.currentMasterAccount?.[0] || '');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.currentMasterAccount])

    // useEffect to update the subsidary accounts
    useEffect(() => {
        if (isTargetBalanceEnabled && props?.currentSubAcounts && props?.currentSubAcounts?.length > 0) {
            for (let i = 0; i < props?.currentSubAcounts?.length; i++) {
                update(i, props?.currentSubAcounts[i]);
                setValue(`subAccounts.${i}.newSubAccount`, props?.currentSubAcounts[i]?.accountId)
                setValue(`subAccounts.${i}.targetBalanceAmount`, props?.currentSubAcounts[i]?.targetBalance)
            }
            passportDropdownData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.currentSubAcounts])

    //Create a Form Field Array
    const { fields, append, remove, update } = useFieldArray({
        control: control,
        name: 'subAccounts',
        shouldUnregister: true
    });

    //Method to get account list
    const getCustomerAccountList = async (accounts: any) => {
        const allPassportAccounts = passportAccountsOnly(accounts);
        updateComponentParams(dispatch, props?.id, { 'CashBuilderPassportAcc': expectCashBuilderPlusAccounts(accounts) })
        updateComponentParams(dispatch, props?.id, { 'passportAcc': allPassportAccounts, 'actualPassportAcc': allPassportAccounts })
        if (isTargetBalanceEnabled) {
            getExistingPreferences();
        } else {
            addNewSubsidiaryAccount();
        }
    }

    /**
     * Function transforms label Account according to the nickname, cash builder and passport account
     * @param data : data
     * @returns : transformed data
     */
    const formatAccountLabel = (data: any) => {
        const title = data?.nickName ? (data?.nickName + ' A/c ') : (data?.type === 'CASH_BUILDER' ? 'Cash Builder A/c ' : 'Passport A/c ');
        return title + maskAccountNumber(data?.accountNumber, false);
    }

    // Method to transform configured accounts of target balance rule
    const getExistingPreferences = async () => {
        updateComponentParams(dispatch, props?.id, { 'isDataLoaded': true });
        const masterAcc = targetBalanceSweep?.masterAccount?.id || '';
        const subAcc = targetBalanceSweep?.subAccounts?.map((ele: any) => {
            return { accountId: ele?.account?.id, targetBalance: ele?.targetBalance }
        }) || [];
        if (subAcc?.length === 0) {
            addNewSubsidiaryAccount();
        }

        updateComponentParams(dispatch, props?.id, { 'currentMasterAccount': [masterAcc], 'currentSubAcounts': subAcc })
    }

    // Method to reset the component
    useEffect(() => () => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props?.id } });
        updateComponentParams(dispatch, props?.id, { 'currentMasterAccount': [], 'currentSubAcounts': [] });
        reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // Method to add new sub account dropdown
    const addNewSubsidiaryAccount = () => {
        append({});
    }

    /**
     * Async function handles the form submission
     * @param event : user submitted data
     */
    async function onSubmit(event: any) {
        let status: any;
        let request: any;

        request = {
            "targetBalanceSweep": [
                {
                    "masterAccount": {
                        id: event?.masterAccount
                    },
                    "subAccounts": getSubAccountsReq(event?.subAccounts)
                }
            ]
        };

        status = await createTargetBalanceRule(clean(request));
        status = processAPIResponse(status)

        if (status.status) {
            FxSnackBar.show({
                autoHideDuration: 2000,
                text: status?.responseHeaders?.warning || "Target Balance Service has been successfully enabled",
                severity: status?.responseHeaders?.warning ? 'warning' : 'success',
            });
            handleNavigation();
            getTargetBalancePrefrences(getCustomerUrl("preference"), dispatch);
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'account-list-sub-menu' } });
        } else {
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
        }
    }

    /**
    * Method to create request for subaccounts onbject
    * @param data : user submitted subaccounts form values
    */
    const getSubAccountsReq = (data: any) => {
        return data?.map((ele: any, index: any) => {
            if (ele?.newSubAccount) {
                return {
                    targetBalance: ele?.targetBalanceAmount || 0,
                    account: {
                        id: ele?.newSubAccount
                    }
                }
            }
        }).filter((ele: any) => ele);
    };

    /**
     * Method to call api for creating target balance rule
     * @param payloadData : request payload
     */
    async function createTargetBalanceRule(payloadData: any) {
        try {
            const url = '/preference';
            const data: any = await httpClient.post(getCustomerUrl(url, false), payloadData)
            return data;
        } catch (err) {
            Logger.error("createTargetBalanceRule.tsx", "error", err);
            return err;
        }
    }

    //Terms condition text
    const targetBalanceRuleTerms = [
        {
            label: (
                <span>By accepting, I agree to the terms and conditions outlined in the Passport Account
                    Agreement, including any associated fees, to enable the Target Balance Service.
                </span>),
            value: 'yes'
        }
    ];

    /**
     * Handler to enable/disable enable button
     * @param event : terms event
     */
    const acceptTerms = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        updateComponentParams(dispatch, props?.id, { 'btnDisabled': !event.target.checked })
    }

    /**
     * Function transforms data for master and subsidary accounts
     * @param accounts : accounts which needs to be transformed
     * @returns : transformed data
     */
    const accountTransformation = (accounts: any) => {
        return accounts?.map((item: any) => ({ value: item?.id, label: formatAccountLabel(item), disabled: false }));
    }

    /**
     * Function to filter out otherthan cashbuilderplus accounts
     * @param data : all accounts list of customer
     * @returns : allaccounts except cashbuilderplus accounts
     */
    const expectCashBuilderPlusAccounts = (data: any) => {
        const accounts = data?.filter((option: any) => option?.status === 'ACTIVE' && (option?.type !== 'CASH_BUILDER_PLUS'));
        return (accounts && accountTransformation(accounts)) || []
    }

    /**
     * Function to filter out passport accounts
     * @param data : all accounts list of customer
     * @returns : only passport accounts
     */
    const passportAccountsOnly = (data: any) => {
        const accounts = data?.filter((option: any) => option?.status === 'ACTIVE' && option?.type !== 'CASH_BUILDER_PLUS' && option?.type !== 'CASH_BUILDER');
        return (accounts && accountTransformation(accounts)) || []
    }

    //To disable already selected dropdown values of master and subsidary accounts
    const passportDropdownData = useCallback(() => {
        const data = watch();
        const currentSelectedAccounts = data?.subAccounts?.map((ele: any) => ele?.newSubAccount);
        const allPassportAccounts = props?.actualPassportAcc;
        const updatedPassportAccounts = allPassportAccounts?.map((acc: any) => {
            return { ...acc, disabled: currentSelectedAccounts?.includes(acc?.value) || data?.masterAccount === acc?.value || false };
        })
        updateComponentParams(dispatch, props?.id, {
            'passportAcc': updatedPassportAccounts
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch()]);

    /**
     * Function to remove sub account on clicking on delete icon of each row
     * @param index : element at which index to removed
     */
    const removeSubAccount = (index: any) => {
        remove(index);
        passportDropdownData();
    }

    //To disable add button based on already disabled dropdown values
    const countDisabledOptions = () => {
        return props?.passportAcc?.filter((ele: any) => ele?.disabled)?.length;
    }

    //To disable the add button based on conditions like fields length, passport acc selected
    const disableAddButton = () => {
        return (countDisabledOptions() === props?.passportAcc?.length) || (fields.length === props?.passportAcc?.length) || (isMasterPassportAccount() === fields.length)
    }

    //To disable add button based on whether passport account is selected as masteraccount
    const isMasterPassportAccount = () => {
        // get all current form values
        const data = watch();
        const currentPassportAccounts = props?.passportAcc || [];
        const currentMasterAccount = currentPassportAccounts.filter((ele: any) => ele?.value === data?.masterAccount)?.length;
        return currentPassportAccounts?.length - currentMasterAccount;
    }

    /**
     * Function to be called on chaning master account and to manupulate the subaccounts field array
     * @param event : selected master account
     */
    const handleMasterAccountChange = (event: any) => {
        const currentMasterAccount = event?.target?.value;
        const data = watch();
        const removeIndex = data?.subAccounts?.findIndex((ele: any) => ele?.newSubAccount === currentMasterAccount);
        removeIndex !== -1 && remove(removeIndex);
        //if index is 0 and current subaccounts form values are of length 1 then append one empty subaccount field
        removeIndex === 0 && data?.subAccounts?.length === 1 && addNewSubsidiaryAccount();
        passportDropdownData();
    }

    /**
    * Method handles the closing of disable tba modal
    */
    const handleDisableTBAModalClose = () => {
        updateComponentParams(dispatch, props?.id, {'disableTBAModalOpen': false})
    }

    /**
     * handle click event of disable zba button
     * @param event
     */
    const handleDisableZBA = (event: any) => {
        event.stopPropagation(); 
        updateComponentParams(dispatch, props?.id, {'disableTBAModalOpen':true})
    }

    /**
     * handle click event of edit transfer rule button
     * @param event
     */
    const handleEditTransferRule = (event: any) => {
        event.stopPropagation();
        updateComponentParams(dispatch, props?.id, { enableEdit: true });
    }

    /**
     * Handles navigation logic: redirects if isTargetBalanceEnabled is false and updates component props.
     */
    const handleNavigation = () => {
        if (!isTargetBalanceEnabled) {
            history.push('/home')
        } 
        updateComponentParams(dispatch, props?.id, { enableEdit: false });
    }

    /**
     * Handles cancel action: resets state, navigates, and fetches preferences.
     */
    const handleCancel = () => {
        reset();
        handleNavigation();
        getExistingPreferences();
    }

    return (
        <>
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                <Grid container xs={12} sm={8} className="fx-container-left" >
                    <FxCard id="target-balance-rule-card" className="fx-theme-passport">
                        <>
                            <FxCardHeader id={'target-balance-rule-card-header'}>
                            <Grid item container xs={12} spacing={1} alignItems="center">
                                <Grid item xs className="fx-flex-grow">
                                    <Grid id="target-balance-rule-card-header-grid" item xs justifyContent="flex-start">
                                        <Typography className='fx-target-balance-modal-title'>Target Balance Service</Typography>
                                    </Grid>
                                </Grid>
                                {isTargetBalanceEnabled && !props?.enableEdit && <Grid id="passport-account-list-actions" item marginRight={'1rem'} className='fx-padding-top-0'>
                                    <FxButton id={"tbr-edit-transfer-rule-button"} variant="contained" className="fx-button fx-button-action fx-mr-4" onClick={handleEditTransferRule}><EditIcon /> &nbsp;EDIT</FxButton>
                                    <FxButton id={"tbr-disable-transfer-rule-button"} variant="contained" className="fx-button fx-button-action" onClick={handleDisableZBA}><EditIcon /> &nbsp;DISABLE</FxButton>
                                </Grid>}
                            </Grid>
                            </FxCardHeader>
                            <FxCardBody id="target-balance-rule-card-body" className="fx-info-card">
                                {!props?.isDataLoaded && !accountList && <FxSkeltonList height="20rem" />}
                                {(props?.isDataLoaded || accountList) && <><Grid id="target-balance-rule-card-header-grid" item xs={12} >
                                    {isTargetBalanceEnabled ?
                                        <Typography className='fx-target-balance-modal-sub-title'>
                                            <span>
                                                Note: The configuration changes will take effect starting the next business day.
                                            </span>
                                        </Typography> :
                                        <Typography className='fx-target-balance-modal-sub-title'>
                                            <span>
                                                Note: This feature automatically transfers any excess funds from your Passport deposit
                                                account (subsidiary account) to your Cashbuilder account or another designated deposit
                                                account (master account) at the end of each day.
                                            </span>
                                        </Typography>}
                                </Grid>
                                <br />
                                <form id="target-balance-rule-form" onSubmit={handleSubmit(onSubmit)} className='fx-form-edit-profile'>
                                    <Grid container item xs direction='column' className='fx-submit-agree'>
                                        <Grid container className={"fx-modal-form flex column" + props?.enableEdit ? "fx-color-white" : ""} paddingTop={'1.25rem'}>
                                            <Grid item xs={12} sm={12} className={'fx-no-padding'}>
                                                <FxMaterialSelect
                                                    control={control}
                                                    rules={{ required: true }}
                                                    value={accountList?.filter((ele: any) => ele?.isPrimary)?.[0]?.id || ''}
                                                    register={{ ...register("masterAccount") }}
                                                    clearError={clearErrors}
                                                    readOnly={(!props?.enableEdit && isTargetBalanceEnabled)}
                                                    className={
                                                        `${errors.masterAccount
                                                            ? "border-error-input fx-input-edit"
                                                            : "fx-input-edit"} 
                                                            ${(!props?.enableEdit && isTargetBalanceEnabled) ? "fx-target-balance-modal-dropdown":""}`
                                                    }
                                                    id="master-account"
                                                    name="masterAccount"
                                                    data={[...props?.CashBuilderPassportAcc || []]}
                                                    label="Select Master Account*"
                                                    setValue={setValue}
                                                    onChange={handleMasterAccountChange}
                                                />
                                            </Grid>
                                            <Grid container item xs={12} direction="row" className={`fx-ledger-summary-total-value  ${fields?.length > 3 ? 'fx-target-balance-modal-acc-container' : ''}`} paddingTop={'1rem'}>
                                                {fields?.length > 0 && fields?.map((account, index) => (
                                                    <Grid key={account?.id} container item direction="row" spacing={1}>
                                                        {fields?.length !== index + 1 ? <Grid item xs={1} className='fx-padding-top-0 fx-target-balance-modal-align-lshape'>
                                                            <TShape />
                                                        </Grid> :
                                                            <Grid item xs={1} className='fx-padding-top-0 fx-target-balance-modal-align-lshape'>
                                                                <LIcon />
                                                            </Grid>}
                                                        <Grid item xs={(props?.enableEdit || !isTargetBalanceEnabled) ? 6 : 7} marginTop={'1rem'} className={'fx-padding-left-0'}>
                                                            <FxMaterialSelect
                                                                control={control}
                                                                rules={{ required: true }}
                                                                register={{
                                                                    ...register(`subAccounts.${index}.newSubAccount`)
                                                                }}
                                                                value={''}
                                                                readOnly={(!props?.enableEdit && isTargetBalanceEnabled)}
                                                                className={
                                                                    `${errors["subAccounts"]?.[index]?.["newSubAccount"]
                                                                        ? "border-error-input fx-input-edit"
                                                                        : "fx-input-edit"}
                                                                        ${(!props?.enableEdit && isTargetBalanceEnabled) && "fx-target-balance-modal-dropdown"}`
                                                                }
                                                                id={`subAcounts.${index}.newSubAccount`}
                                                                name={`subAccounts.${index}.newSubAccount`}
                                                                data={props?.passportAcc}
                                                                label={`Select Subsidiary Account *`}
                                                                setValue={setValue}
                                                                placeholder='Select Subsidiary Account'
                                                                onFocus={passportDropdownData}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} marginTop={'1rem'}>
                                                            <FxTextEdit
                                                                rules={{ required: false }}
                                                                register={{
                                                                    ...register(`subAccounts.${index}.targetBalanceAmount`)
                                                                }}
                                                                isEditable={(props?.enableEdit || !isTargetBalanceEnabled)}
                                                                className={
                                                                    errors[`subAccounts`]?.[index]?.targetBalanceAmount
                                                                        ? "border-error-input fx-input-edit"
                                                                        : "fx-input-edit"
                                                                } type="number"
                                                                variant="outlined"
                                                                prefix="$"
                                                                valuePattern={/-|\+|e|E/}
                                                                onWheel={(e: any) => e.target.blur()}
                                                                control={control}
                                                                id={`subAcounts.${index}.targetBalanceAmount`}
                                                                name={`subAccounts.${index}.targetBalanceAmount`}
                                                                label={`Enter Target Amount`}
                                                                placeholder='Enter Target Amount'
                                                            />
                                                        </Grid>
                                                        {(props?.enableEdit || !isTargetBalanceEnabled) && <Grid item xs={1} marginTop={'1rem'}>
                                                            <FxButton
                                                                id={index + '-delete-sub-account'}
                                                                title="Delete"
                                                                className={`fx-button fx-approval-button-icon ${fields.length === 1 ? 'fx-button-disabled' : ''}`}
                                                                variant="contained"
                                                                disabled={fields?.length === 1}
                                                                onClick={() => removeSubAccount(index)}
                                                                startIcon={<DeleteIcon />}
                                                            />
                                                        </Grid>}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            {(props?.enableEdit || !isTargetBalanceEnabled) && <FxButton
                                                disableRipple={false}
                                                className={`fx-button fx-button-passport-border fx-svg-theme fx-margin-top-left-16
                                                    ${disableAddButton() ? 'fx-button-disabled' : ''} `}
                                                id="add-new-subsidiary-account"
                                                startIcon={<AddIcon />}
                                                disabled={disableAddButton()}
                                                onClick={addNewSubsidiaryAccount}
                                            > Add
                                            </FxButton>}
                                        </Grid>
                                    </Grid>
                                    {!isTargetBalanceEnabled && <Grid item container xs={12} className="fx-margin-left-right-0">
                                        <Grid item xs={12} marginTop={'2.33rem'} className=" fx-submit-agree">
                                            <FxCheckEdit
                                                rules={{ required: 'Please click Agree' }}
                                                control={control}
                                                id="create-customer-account-terms-condition"
                                                name="CreateAccountTerms"
                                                className="fx-privacy-text"
                                                data={targetBalanceRuleTerms}
                                                row="vertical"
                                                onChange={(e: any) => {
                                                    acceptTerms(e);
                                                }}
                                                setValue={setValue} />
                                        </Grid>
                                    </Grid>}
                                    {(props?.enableEdit || !isTargetBalanceEnabled) && <><Grid className='fx-padding-top-bottom-16'>
                                        <hr className='fx-target-balance-modal-divider' />
                                    </Grid>
                                        <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                            <Button
                                                variant="contained"
                                                className="fx-button fx-button-cancel"
                                                id="enable-cancel-button"
                                                onClick={() => handleCancel()}
                                            >Cancel
                                            </Button>
                                            <span className="fx-padding-right-16" />
                                            <FxButton
                                                className={`fx-button ${props?.btnDisabled && !isTargetBalanceEnabled ? 'fx-button-disabled' : 'fx-button-theme'}`}
                                                disabled={isTargetBalanceEnabled ? false : props?.btnDisabled}
                                                type='submit'
                                                id={"enable-submit-button"}
                                                isSubmitting={isSubmitting}
                                            > {isTargetBalanceEnabled ? 'SAVE' : 'ENABLE'}</FxButton>
                                        </Grid></>}
                                </form>
                                </>}
                            </FxCardBody>
                        </>
                    </FxCard>
                </Grid>
                <Grid container xs={12} sm={4} className="fx-container-right">
                </Grid>
            
                {props?.disableTBAModalOpen &&
                    <FxDisableTBA
                        header="Are you sure you want to disable target balance service?"
                        description="The changes will take effect starting the next business day."
                        buttonName='Yes, Disable it'
                        disableAllAccounts={true}
                        close={handleDisableTBAModalClose}
                    />
                }
            </Grid>
        </>
    );
});
