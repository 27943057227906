/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable eqeqeq */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { FxButton } from '../../Action/FxButton';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { MerchantAccountCard } from './MerchantAccountCard';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/download-home.svg';
import { AccountStatementDownload } from '../Index/Passport/AccountStatementDownload';
import { updateComponentParams } from '../../Utils/CommonBaseClass';

//icons
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { MerchantLocationCard } from './MerchantLocationCard';


Logger.debug("MerchantInfoCard.tsx", "Merchant InfoCard initializing");
/**
 * Component: Merchant Info Card
 * Usage: To display merchant information
 */

export const MerchantInfoCard: React.FC<any> = React.memo(
    (props) => {
        const dispatch = useDispatch()
        let context: any;
        ({ context, props } = RegisterComponent(props));
        let merchant_information_data: any = {};
        let merchant_account_source: any;
        let merchant_location_source: any;

        // Helper function to create section columns
        const createSectionColumns:any = (columns: { label: string, value: string }[]) => columns;
        
        if (context && context.data && context.data.body) {
            try{
                merchant_information_data = { ...context.data.body }
                merchant_information_data.priorBankruptcy = merchant_information_data.priorBankruptcy == false ? 'No' : 'Yes';
                merchant_information_data.isMarketplace = merchant_information_data.isMarketplace == false ? 'No' : 'Yes';
                merchant_information_data.dateOfDischarge = merchant_information_data.dateOfDischarge ?? '';
                merchant_information_data['createdInfo'] = merchant_information_data['createdBy']['username'] + '\nOn ' + moment(merchant_information_data['createdOn']).format('MM/DD/YYYY');
                merchant_information_data['updatedInfo'] = merchant_information_data['lastUpdatedBy']['username'] + '\nOn ' + moment(merchant_information_data['lastUpdatedOn']).format('MM/DD/YYYY');
                merchant_information_data.pos = `${merchant_information_data.saleDetails.saleMethod.pos ? merchant_information_data.saleDetails.saleMethod.pos : '0.00'} %`;
                merchant_information_data.ecom = `${merchant_information_data.saleDetails.saleMethod.ecom ? merchant_information_data.saleDetails.saleMethod.ecom : '0.00'} %`;
                merchant_information_data.moto = `${merchant_information_data.saleDetails.saleMethod.moto ? merchant_information_data.saleDetails.saleMethod.moto : '0.00'} %`;
                if(merchant_information_data.saleDetails.saleMethod.moto && merchant_information_data.saleDetails.saleMethod.ecom)
                    merchant_information_data.cardNotPresentFieldsAllowed = ((parseFloat(merchant_information_data.ecom) + parseFloat(merchant_information_data.moto)) > 20 ? true : false)
                merchant_information_data.internetBusinessType = merchant_information_data.cardNotPresent.internetBusinessType ?? '';
                if(merchant_information_data.internetBusinessType === 'OTHER')
                    merchant_information_data.specificType = merchant_information_data.cardNotPresent.specificType ?? '';
                merchant_information_data.advertisingMethod = merchant_information_data.cardNotPresent.advertisingMethod ?? '';
                
                merchant_information_data.monthly = `${merchant_information_data.cardNotPresent.billingMethod.monthly ? merchant_information_data.cardNotPresent.billingMethod.monthly : '0.00'} %`;
                merchant_information_data.yearly = `${merchant_information_data.cardNotPresent.billingMethod.yearly ? merchant_information_data.cardNotPresent.billingMethod.yearly : '0.00'} %`;
                merchant_information_data.onetime = `${merchant_information_data.cardNotPresent.billingMethod.onetime ? merchant_information_data.cardNotPresent.billingMethod.onetime : '0.00'} %`;
                const merchant_account_source_url = merchant_information_data.merchantAccount.url.split('/v1/')[1]
                const merchant_location_source_url = merchant_information_data.location.url.split('/v1/')[1]
                merchant_account_source = {
                    url: merchant_account_source_url,
                    method: "GET",
                    baseUrl:true
                }
                merchant_location_source = {
                    url: merchant_location_source_url,
                    method: "GET",
                    baseUrl:true
                }
            }
            catch(e){
                Logger.error("MerchantInfoCard.ts", "error", e);
            }
        }
        
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const history = useHistory();
        
        // declare sales information left section columns
        const merchant_sales_information_section_left = createSectionColumns([
            {
                label: 'Average Purchase',
                value: 'saleDetails.averagePurchase',
                type: 'fri://fieldtype/currency'
            },
            {
                label: 'Average Monthly Sales Volume',
                value: 'saleDetails.averageSalesVolumes',
                type: 'fri://fieldtype/currency'
                
            },
        ]);
        // declare contact information right section columns
        const merchant_sales_information_section_right = createSectionColumns([
            {
                label: 'Product Description',
                value: 'saleDetails.productDescription'
               
            },
            {
                label: 'Average Delivery Time',
                value: 'saleDetails.averageDeliveryTime'
               
            }
        ]);

        // declare basic information left section columns
        const basic_information_section_left = createSectionColumns([
            {
                label: 'Category Type',
                value: 'categoryType'
            },
            {
                label: 'Prior Bankruptcy',
                value: 'priorBankruptcy'
            },
            {
                label: 'Accept payments for other sellers',
                value: 'isMarketplace'
            } 
        ]);
        if(merchant_information_data.priorBankruptcy === 'Yes' ){
            basic_information_section_left.push(
                {
                    label: 'Date of Discharge',
                    value: 'dateOfDischarge'
                }
            )
        }

        // declare basic information right section columns
        const basic_information_section_right = createSectionColumns([
            {
                label: 'Category Code',
                value: 'categoryCode'
            },
            {
                label: 'Underwriting Status',
                value: 'underwritingStatus',
                type: 'fri://fieldtype/status'
            }
        ]);
        // declare supported sales method information left section columns
        const merchant_suppored_sales_method_information_section_left = createSectionColumns([
            {
                label: 'POS',
                value: 'pos'
            },
            {
                label: 'ECOM',
                value: 'ecom'
            },
            {
                label: 'MOTO',
                value: 'moto'
            }
        ]);

        const audit_fields_left_section = createSectionColumns([
            {
                label: 'Created',
                value: 'createdInfo'
            }
        ])
        const audit_fields_right_section = createSectionColumns([
            {
                label: 'Last Update',
                value: 'updatedInfo'
            }
        ])
        // Declare supported sales method information left section columns
        const merchant_billing_summary_information_section_left = createSectionColumns([
            { label: 'Monthly', value: 'monthly' },
            { label: 'Yearly', value: 'yearly' },
            { label: 'Onetime', value: 'onetime' }
        ]);

        // Declare supported card not present information left section columns
        const merchant_card_not_present_information_section_left = createSectionColumns([
            { label: 'Internet Business Type', value: 'internetBusinessType' }
        ]);

        // Add specific type if Internet Business Type is 'OTHER'
        if (merchant_information_data.cardNotPresent?.internetBusinessType === 'OTHER') {
            merchant_card_not_present_information_section_left.push({ label: 'Specific Type', value: 'specificType' });
        }

        // Declare supported card not present information right section columns
        const merchant_card_not_present_information_section_right = createSectionColumns([
            { label: 'Advertising Method', value: 'advertisingMethod' }
        ]);

        //check api call completed or not
        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        const accountStatementPermission = {
            entity: "Other Entities",
            name: "Merchant",
            operation: "Create"
        }

        // Method handles the onclick event for opening modal
        const handleOpen = () => {
          updateComponentParams(dispatch, props?.id, { 'open': true });
        };

        // Method handles the onclick event for closing modal
        const handleAccountStatementClose = () => {
          updateComponentParams(dispatch, props?.id, { 'open': false });
        }

        return (
            <>
                <Grid id="merchant-info-Grid " className="fx-margin-bottom-16" item xs={12}  >
                    {dataLoadStatus ?
                        <FxCard id="merchant-info-card" className="fx-theme-passport">
                            <FxCardHeader id={'merchant-info-card#card-header'}>
                                <Grid id="merchant-Grid2" item container justifyContent="flex-end" xs={12}>
                                    <Grid id="filter-Grid" item container className="fx-view-action fx-inline-flex">
                                    {(merchant_information_data.status === 'ACTIVE' || merchant_information_data.status === 'CANCELLED') && merchant_information_data.type === 'DIRECT_FUNDED' &&
                                        <FxButton
                                            permissions={accountStatementPermission}
                                            variant="contained"
                                            className="fx-account-insight-ac-statement-btn"
                                            title='Download Statement'
                                            id="account-statement"
                                            onClick={handleOpen}
                                        >   
                                            <DownloadIcon/>
                                            <span className='fx-account-insight-ac-statement'>A/C STATEMENT</span>
                                        </FxButton>
                                    }
                                        {merchant_information_data.status === 'INACTIVE' && merchant_information_data.type === 'DIRECT_FUNDED' &&
                                            <Grid item><FxButton id="edit-merchant-button" variant="contained"
                                                                 className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme"
                                                                 startIcon={<EditIcon />}
                                                                 onClick={() => history.push("/merchant/edit/" + context?.data?.body?.id)}>Edit</FxButton></Grid>}
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="merchant-info-card#card-body"
                                        className="fx-info-card fx-margin-top-reducer">
                                {/* Info card section */}
                                <Grid container item xs={12} direction="row">
                                    <><Grid item xs={12} direction="column">
                                        {merchant_information_data && <FxInfoCard
                                            id={"merchant-account-information-info-card" + merchant_information_data['id']}
                                            title="MERCHANT ACCOUNT INFORMATION"
                                            leftcolumn={basic_information_section_left}
                                            rightcolumn={basic_information_section_right}
                                            data={merchant_information_data} />}

                                    </Grid>
                                        <Grid item xs={12} direction="column">
                                            {merchant_information_data && merchant_account_source &&
                                                <MerchantAccountCard
                                                    id={"merchant-account-account-information-info-card" + merchant_information_data['id']}
                                                    source={merchant_account_source} />}
                                        </Grid>

                                        <Grid item xs={12} direction="column">
                                            {merchant_information_data && merchant_location_source &&
                                                <MerchantLocationCard
                                                    id={"merchant-account-location-information-info-card" + merchant_information_data['id']}
                                                    source={merchant_location_source} />}
                                        </Grid>       

                                        <Grid item xs={12} direction="column">
                                            {merchant_information_data && <FxInfoCard
                                                id={"merchant-account-sales-information-info-card" + merchant_information_data['id']}
                                                title="SALES INFORMATION"
                                                leftcolumn={merchant_sales_information_section_left}
                                                rightcolumn={merchant_sales_information_section_right}
                                                data={merchant_information_data} />}
                                        </Grid>
                                        <Grid item xs={12} direction="column">
                                            {merchant_information_data && <FxInfoCard
                                                id={"merchant-supported-sales-method-info-card" + merchant_information_data['id']}
                                                title="SUPPORTED SALES METHOD"
                                                leftcolumn={merchant_suppored_sales_method_information_section_left}
                                                data={merchant_information_data} />}
                                            
                                            {/** Card Not Present Section */}
                                            {merchant_information_data && merchant_information_data.type === 'DIRECT_FUNDED' && merchant_information_data.cardNotPresentFieldsAllowed && <FxInfoCard
                                                id={"merchant-card-not-present-info-card" + merchant_information_data['id']}
                                                title="CARD NOT PRESENT QUESTIONNAIRE"
                                                leftcolumn={merchant_card_not_present_information_section_left}
                                                rightcolumn={merchant_card_not_present_information_section_right}
                                                data={merchant_information_data} />}

                                            {/** Billing Summary Section */}
                                            {merchant_information_data && merchant_information_data.type === 'DIRECT_FUNDED' && merchant_information_data.cardNotPresentFieldsAllowed && <FxInfoCard
                                                id={"merchant-billing-summary-info-card" + merchant_information_data['id']}
                                                title="BILLING SUMMARY"
                                                leftcolumn={merchant_billing_summary_information_section_left}
                                                data={merchant_information_data} />}

                                            {merchant_information_data && <FxInfoCard
                                                id="company-profile-company-information-card-audit-one-section-info-card"
                                                title="AUDIT FIELDS" leftcolumn={audit_fields_left_section}
                                                rightcolumn={audit_fields_right_section}
                                                data={merchant_information_data}></FxInfoCard>}
                                        </Grid>
                                        <Grid container xs={12} alignItems="flex-start" justifyContent="space-between">
                                            <Grid container xs={12} sm={8} className="fx-container-left">

                                            </Grid>
                                        </Grid>
                                    </>
                                </Grid>
                            </FxCardBody>
                        </FxCard>
                        :
                        <FxSkeltonList height="25rem" />
                    }
                </Grid >

                {/** Account Statement Download Modal */}
                {props?.open && (
                    <AccountStatementDownload
                        id="merchant-info-asd" 
                        entity="merchant"
                        entityId={merchant_information_data?.id}
                        entityActivationDate={merchant_information_data?.createdOn}
                        open={props?.open} 
                        onClose={handleAccountStatementClose} 
                    />
                )}
            </>
        )
    })
