/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';
import FxCardBody from '../../../Container/FxCardBody';
import { FxStatus } from '../../../Input/FxStatus/FxStatus';
import FxNoGridDataImage from '../../../Data/FxNoGridDataImage';
import { useIsMobileScreen } from '../../../../libs/utils/utils';
import FxCardFooter from '../../../Container/FxCardFooter';
import { FxButton } from '../../../Action/FxButton';
import { useHistory } from 'react-router';

//icons
import { ReactComponent as IndividualCustomerIcon } from './../../../../assets/svg/individual-customer-icon.svg';
import { ReactComponent as BusinessCustomerIcon } from './../../../../assets/svg/business-customer-icon.svg';
import { ReactComponent as MdArrowForward } from "./../../../../assets/svg/view-all-arrow-icon.svg";
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { truncateChars } from '../../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';
/**
 * component used to list recent Leads 
 */
export const RecentLeads: React.FC<any> = React.memo((props) => {
    const history = useHistory();
    const isSmallScreen = useIsMobileScreen();
    let context: any;
    const dispatch = useDispatch();
  ({ context, props } = RegisterComponent(props));
  
    /**
    *  To get the list data.
    */
   const redirectDetails=(row:any)=>{
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'lead-details', data: row?.invitationStatus} });
    history.push(`/lead/details/${row?.id}`)

   }
    const listData = useMemo(() => {
        return <Grid item container xs={12} justifyContent="space-between" direction={'row'}>
            {context?.data?.body?.resources.length > 0 ? context?.data?.body?.resources?.map((item: any, index: any) => {
                return (<Grid item container xs={12} className='fx-grid-list-content fx-recent-invite-card'>
                    {index === 0 && <Grid item container xs={12} direction={'row'} justifyContent={'center'} className='fx-recent-invite-card-list-headerText' >
                        <Grid item xs={1}></Grid>
                        <Grid item container xs={3} >
                            <Typography variant='h6'>LEAD NAME</Typography>
                        </Grid>
                        <Grid item container xs={4} >
                            <Typography variant='h6'>SHARED WITH</Typography>
                        </Grid>
                        <Grid item container xs={2}>
                            <Typography variant='h6'>INVITE STATUS</Typography>
                        </Grid>
                        <Grid item  container xs={2} >
                            <Typography variant='h6'>{!isSmallScreen ? 'CREATED ON' : 'Date'}</Typography>
                        </Grid>
                    </Grid>}
                   {index<=3 && <> <Grid item container xs={12} justifyContent="space-between" direction={'row'} onClick={()=>{redirectDetails(item)}} className='fx-cursor-pointer'>
                        <Grid item xs={1}>
                            {item?.customerType==='BUSINESS'? <BusinessCustomerIcon />: <IndividualCustomerIcon />}
                        </Grid>
                         <Grid item xs={3}>
                                <Typography variant='h3'> {`${truncateChars(item?.name?item?.name:'',20)}`}</Typography>
                            </Grid>
                        <Grid item container xs={4} direction={'column'}>
                                <Typography variant='h3'> {`${ item?.email?truncateChars(item?.email,30):item?.phoneNumber|| ' '}`}</Typography>
                        </Grid>
                        <Grid item container xs={2} alignContent={'center'} >
                            <FxStatus id="status" value={item?.invitationStatus} fill={true} className="fx-status"></FxStatus>
                        </Grid>
                        <Grid item xs={2} container alignContent={'center'} >
                            <Typography variant='h4'>{moment(new Date(item?.createdOn)).format('MMM DD, YYYY')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} className='fx-row-divider'>
                        <Divider />
                    </Grid>
                    </>}
                </Grid>)
            }) : <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={12} textAlign={'center'}>
                    <FxNoGridDataImage />
                    <Typography className='fx-no-data'> No data to show </Typography>
                </Grid>
            </Grid>}
        </Grid>
    }, [context?.data?.body])

    return <><FxCardBody id="recent-leads-list-Home-Card-Body" className="fx-home-ledger-list">
        <Grid item xs={12} className="fx-flex-grow">
            {listData}
        </Grid>
    </FxCardBody>
        {context?.data?.body?.resources?.length > 0 && <FxCardFooter id="recent-leads-list-Home-Card-Footer" className="fx-footer fx-footer-middle">
            <Grid container xs={12} justifyContent="center" alignItems="flex-start">
                <FxButton variant="contained" className="fx-button fx-button-view-all" id="recent-leads-list-view-all-button" onClick={() => history.push('/leads')}> View All <MdArrowForward className="fx-footer-icon" /></FxButton>
            </Grid>
        </FxCardFooter>}
    </>
})


