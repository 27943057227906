/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { ReactComponent as ProfileDetailIcon } from '../../assets/svg/profile.svg';
import { ReactComponent as UserDetailIcon } from '../../assets/svg/user.svg';
import { ReactComponent as ShareIcon } from '../../assets/svg/share.svg';
import { ReactComponent as OptionIcon } from '../../assets/svg/option.svg';
import { ReactComponent as AddIcon } from '../../assets/svg/add.svg';
import { ReactComponent as EditIcon } from '../../assets/svg/edit.svg';
import { ReactComponent as ActionIcon } from '../../assets/svg/action.svg';
import { ReactComponent as FilterIcon } from '../../assets/svg/filter.svg';
import { ReactComponent as SortIcon } from '../../assets/svg/sort.svg';
import { ReactComponent as ColumnsIcon } from '../../assets/svg/columns.svg';
import { ReactComponent as LogoIcon } from '../../assets/svg/logo.svg';
import { ReactComponent as ProfileIcon } from '../../assets/svg/complogo.svg';
import { ReactComponent as UserIcon } from '../../assets/svg/compuser.svg';
import { ReactComponent as BackIcon } from '../../assets/svg/BackArrow.svg';
import { ReactComponent as DefaultPhotoIcon } from '../../assets/svg/no-photo.svg';
import { ReactComponent as SelectedIcon } from '../../assets/svg/selected.svg';
import { ReactComponent as RefreshIcon } from '../../assets/svg/refresh-icon.svg';
import { ReactComponent as ProgramCalcIcon } from '../../assets/svg/program-calc.svg';
import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/svg/minus.svg';
import { ReactComponent as StarIcon } from '../../assets/svg/star.svg';
import { ReactComponent as EmptyStarIcon} from '../../assets/svg/empty-star.svg'
import { ReactComponent as LockIcon } from '../../assets/svg/lock.svg';
import { ReactComponent as UnLockIcon } from '../../assets/svg/unlock.svg'
import { ReactComponent as Team } from '../../assets/svg/team.svg'
import { ReactComponent as ImageHead } from '../../assets/svg/imagehead.svg'
import { ReactComponent as AvatarHead } from '../../assets/svg/avatar.svg'
import { ReactComponent as DustBin } from '../../assets/svg/dust-bin.svg'
import { ReactComponent as OtherProfile } from '../../assets/svg/profile-other.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-shape.svg'
import { ReactComponent as MngStarHeadIcon } from '../../assets/svg/mng-star-head.svg';
import { ReactComponent as exportIcon } from '../../assets/svg/export.svg';
import { ReactComponent as ClockIcon } from '../../assets/svg/clock.svg';
import { ReactComponent as check } from '../../assets/svg/check.svg';
import { ReactComponent as AvatarProfile } from '../../assets/svg/avatar-info.svg'
import { ReactComponent as File } from '../../assets/svg/file.svg'
import { ReactComponent as OpenBook } from '../../assets/svg/book-open.svg'
import { ReactComponent as AddIconBig } from '../../assets/svg/add-big.svg';
import { ReactComponent as Activity } from '../../assets/svg/activity.svg';
import { ReactComponent as Chevron } from '../../assets/svg/chevron-down.svg';
import { ReactComponent as Info } from '../../assets/svg/blueInfo.svg';

import { Dictionary } from '.';

export const iconDictionary:Dictionary = {
    ProfileDetailIcon: ProfileDetailIcon,
    UserDetailIcon: UserDetailIcon,
    profile: ProfileIcon,
    user: UserIcon,
    share: ShareIcon,
    option: OptionIcon,
    add: AddIcon,
    edit: EditIcon,
    action: ActionIcon,
    filter: FilterIcon,
    sort: SortIcon,
    columns: ColumnsIcon,
    logo: LogoIcon,
    defaultPhoto: DefaultPhotoIcon,
    selected: SelectedIcon,
    back: BackIcon,
    calculator: ProgramCalcIcon,
    refreshIcon: RefreshIcon,
    plus: PlusIcon,
    minus: MinusIcon,
    starIcon: StarIcon,
    emptyStarIcon: EmptyStarIcon,
    lock: LockIcon,
    unlock: UnLockIcon,
    team: Team,
    imageHead: ImageHead,
    avatarHead:AvatarHead,
    DustBin:DustBin,
    otherProfile: OtherProfile,
    delete: DeleteIcon,
    mngStarHeadIcon: MngStarHeadIcon,
    export:exportIcon,
    check:check,
    timeline:ClockIcon,
    avatarProfile:AvatarProfile,
    file:File,
    openBook:OpenBook,
    addIconBig:AddIconBig,
    activity:Activity,
    chevron:Chevron,
    info:Info

}

/*get the icon
    icon getting based on string value. 
    if the parameter is object itself then just return that
    the second option is helpful in natively generating controls
*/

export function getIcon(icon:any){ 
    if (typeof icon == "string"){
        if(iconDictionary[icon.toString()]) {
            const Comp:any = iconDictionary[icon.toString()]
            return (<Comp/>)
        } else {
            return icon
        }
    }else{
        return icon
    }
}