import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxAccountAgreement } from './FxAccountAgreement';
import { FxAccountCongratulation } from './FxAccountCongratulation';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';

Logger.debug("FxAccountDrawer.tsx", "Customer Account Creation")

interface IAccountDrawer {
    id: string;
    step?: number;
    onDrawerClose: (event: any) => void;
}

/**
 * @author Vineetha
 * Component: FxAccountDrawer to open drawer for account creation and change slides based on user interaction
 */
export const FxAccountDrawer: React.FC<IAccountDrawer> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch();

    //to initilize required props
    useEffect(() => {
        updateComponentParams(dispatch, props?.id, { 'step': 1 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * On change handler to change slides on drawer
     */
    const onChange = (step: number) => {
        updateComponentParams(dispatch, props?.id, { 'step': step })
    }

    /**
     * Show dashboard on clicking on continue on congrats page
     */
    const showDashboard = (async (event: any) => {
        props.onDrawerClose(event);
    })

    return (
        <Grid container id="create-new-account" >
            {props?.step === 1 && <FxAccountAgreement id={props?.id} onChange={onChange} onDrawerClose={showDashboard} />}
            {props?.step === 2 && <FxAccountCongratulation id={props?.id} onDrawerClose={showDashboard} />}
        </Grid>
    )
})