/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react"; // we need this to make JSX compile
import { Grid } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { ISource } from '../../../types/common.interfaces';
import { useForm } from "react-hook-form";
import FxVerifyPPI from "../../Data/FxVerifyPPI";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import {
  removeUnderScoreConvertTitleCase,
  getPPISupportedPaymentMethods,
  customErrorMessage,
  updateComponentParams,
  deleteUnwantedRequest,
  createCommonVerifyProps
} from "../../Utils/CommonBaseClass";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FxAutoComplete } from "../../Input/FxAutoComplete/FxAutoComplete";
import { clean } from "../../Utils/CommonBaseClass";
import { processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { getKey } from "../../../libs/utils/storageManager";
import { ScheduleCreateMethodTab } from "./ScheduleCreateMethodTab";
import { accountLabelTransformation, getLocationData } from "./ScheduleGlobalFunctions";
import {
  createSendMyAccount,
  dataSourceTransformation,
} from "./ScheduleGlobalFunctions";
import { AUTH_STRINGS } from "../../../constants/strings";
import { TransactionSummaryFieldType } from "./TransactionSummaryTemplate";
import { ScheduleTopSection } from "./ScheduleTopSection";
import FxSnackBar from "../../Utils/fx-snack-bar";
import { FxSummaryCurrency } from "../../Input/FxCurrency/FxSummaryCurrency";
import ScheduleLayout from "../../Layout/ScheduleLayout";
import FxLocationSelectComponent from "../Location/FxLocationSelectComponent";
import UIFeatureImpl from "../../../libs/services/uiFeatures";

interface InputErrType {
  type: string;
  message: string;
}

Logger.debug(
  "CreateSendOneTimeACH.tsx",
  "create send Account Ach initializing"
);

const acc_src: ISource = {
  url: "account/list",
  method: "POST",
  data: {
    pageSize: 25,
    pageNumber: 1,
    sortOptions: {
      sortOrder: "DESC",
      sortBy: "createdOn",
    },
    criteria: {
      filters: [
        {
          key: "status",
          operator: "nin",
          values: ["INACTIVE"],
        },
      ],
    },
  },
};

const processingModeData = [
  { label: "Same Day", value: "SAME_DAY" },
  { label: "Next Day", value: "FORWARD" },
];
const accountType = [
  { label: "Savings", value: "SAVINGS" },
  { label: "Checking", value: "CHECKING" },
];

const holderTypeData = [
  { label: "Consumer", value: "CONSUMER" },
  { label: "Corporate", value: "CORPORATE" },
];

const bank_src = {
  url: "list/bank",
  method: "POST",
  expiry: 300,
  baseUrl: true,
  data: {
    pageSize: 25,
    pageNumber: 1,
    criteria: {
      filters: [],
    },
  },
};

// routing number validation constant
const setRoutingNumberValidation = {
  required: false,
  minLength: 9,
  maxLength: 9,
  pattern: {
    value: /^[0-9-_&,.]*$/,
    message: AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_REGEXP,
  },
};

/**
 * This component handles the send creation through my account for one time ach method
 */
export const CreateSendOneTimeACH: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    resetField,
    control,
    clearErrors,
    setError,
    watch,
  } = useForm();
  const params = useParams<any>();
  const withdrawalInfo: any = context?.data?.body;
  let sendId: any = "";
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available
  const scheduleId = params?.id;
  const method_Tab = useMemo(() => {
    const tabs = [
      {
        className: "fx-link-sel",
        title: "ACH",
        link: "/transaction/send/onetime/add/ach",
        disabled: !!scheduleId,
        permission: {
          entity: "Accounts",
          name: "Ach Send",
          operation: "CREATE"
      }
      },
      {
        title: "WIRE",
        link: "/transaction/send/onetime/add/wire",
        disabled: !!scheduleId,
        permission: {
          entity: "Accounts",
          name: "Wire Send",
          operation: "CREATE"
      }
      },
      {
        title: 'INTERNATIONAL WIRE',
        link: '/transaction/send/onetime/add/internationalWire',
        disabled: !!scheduleId,
        permission: {
            entity: "Accounts",
            name: "International Wire Send",
            operation: "CREATE"
        }
      },
      // {
      //   title: "CHECK",
      //   link: "/transaction/send/onetime/add/check",
      //   disabled: !!scheduleId,
      //   permission: {
      //     entity: "Accounts",
      //     name: "Check Send",
      //     operation: "CREATE"
      // }
      // },
      {
        title: "BOOK",
        link: "/transaction/send/onetime/add/book",
        disabled: !!scheduleId,
        permission: {
          entity: "Accounts",
          name: "Book Send",
          operation: "CREATE"
      }
      }
    ];

    if (businessCustomer) {
      tabs.push({
        title: 'CARD',
        link: '/transaction/send/onetime/add/card',
        disabled: !!scheduleId,
        permission: {
            entity: "Accounts",
            name: "Card Send",
            operation: "CREATE"
        }
      })
    }

    // VIRTUAL CARD method is only shown for "BUSINESS" customer and PM setting 'sendViaVirtualMethod' should be ture.
    if(getKey("sendViaVirtualMethod")?.toLowerCase() === 'true' && businessCustomer){
      tabs.push({
        title: "VIRTUAL CARD",
        link: "/transaction/send/onetime/add/virtualcard",
        disabled: !!scheduleId,
        permission: {
          entity: "Accounts",
          name: "Virtual Card Send",
          operation: "CREATE"
        }
      })
    }

    return tabs;
  }, [scheduleId]);

  const [isloading, setIsloading] = useState(false);
  const [viaPpi, setViaPpi] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState<boolean | undefined>(
    true
  );
  const [location, setLocation] = useState<any>();

  // account number validation constant
  const setAccountNumberValidation = {
    required: true,
    maxLength: 17,
  };

  /**
   * Method to render account number error
   * @param err
   */
     const renderAccountNumberError = (err: InputErrType): string => {
      if (err.type === "maxLength") {
        return AUTH_STRINGS.ERRORS.ACCOUNT_NUMBER_NOT_VALID_LENGTH;
      }
      return err.message;
    };

  // routing number validation error messages
  const renderRoutingNumberError = useCallback((err: InputErrType): string => {
    if (err.type === "minLength") {
      return AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_LENGTH;
    } else if (err.type === "maxLength") {
      return AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_LENGTH;
    }
    return err.message;
  }, []);

  /**
   * Method to transform routing number data as dropdown options
   * @param data : routing number data
   */
  const routingNumberTransformer = useCallback((data: any) => data?.map((item: any) => ({
    label: item.routingNumber + " (" + item.name + ")",
    value: item.routingNumber,
  })) || [], []);

  /**
   * Function to be called before loading the component
   */
  useEffect(() => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    dispatch({
      type: "DATA_COMPONENT_RESET",
      payload: { id: "create-send-onetime-ach-form-card-destination" },
    });
    if (scheduleId) {
      const src = {
        url: "/transaction/id/" + scheduleId,
        method: "GET",
      };
      dispatch({
        type: "DATA_API_PARAM_CHANGED",
        payload: { id: "edit-send-to-one-time-ACH", source: src },
      });
      updateComponentParams(dispatch, "edit-send-to-one-time-ACH", {
        isEdit: true,
      });
    } else {
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: {
          id: "page-title-details",
          data: { title: "Send Money", backButton: "/dashboard" },
        },
      });
    }
    setValue("type", "REGULAR");
    const verifyProps = {
      open: false
  }
  updateComponentParams(dispatch, props.id, { verifyProps })
  }, []);

  /**
   * useEffect to set edit data details
   */
  useEffect(() => {
    if (context?.data?.body) {
      setFormValues(context?.data?.body);
    }
    if(context?.data?.body?.processingDetail?.location){
      getLocationData(context?.data?.body?.processingDetail?.location,setLocation,setValue)
    }
  }, [context?.data?.body]);

  /**
   * Method setting the default values for the text edit component from the context data
   */
  function setFormValues(data: any) {
    setValue("amount", data?.amount);
    setValue("purpose", data?.purpose);
    setValue(
      "addenda",
      data?.processingDetail?.addenda && data?.processingDetail?.addenda?.[0]
    );
    setValue(
      "achCompanyDescription",
      data?.processingDetail?.companyDescription
    );
    setValue("processingMode", data?.processingDetail?.processingMode);
    if(data?.destination?.externalAccount) {
      setValue("holderType", data?.destination?.externalAccount?.type)
      setValue("accountHolderName", data?.destination?.externalAccount?.holderName)
      setValue("accountNumber", '****'+data?.destination?.externalAccount?.accountNumberLast4)
    } else {
      if(data?.destination?.ppi) {
        setViaPpi(true)
        setValue("ppi", data?.destination?.ppi.replace("@ppi", ""))
        setSaveEnabled(true)
      }
    }
  }

  /**
   * Async function handles the create send form submission
   * @param data : form request
   */
  async function onSubmit(data: any) {
    let req: any;
    req = {
      source: {
        account: {
          id: data.source,
        },
      },
      method: "ACH",
      type: "REGULAR",
      amount: data.amount,
      purpose: data.purpose,
    };
    if (!scheduleId) {
      if (data.accountHolderName) {
        req["destination"] = {
          externalAccount: {
            type: data.accountType,
            routingNumber: data.routingNumber,
            holderName: data.accountHolderName,
            accountNumber: data.accountNumber,
            purpose: data.accountPurpose,
            holderType: data.holderType,
          },
        };
      } else if (data.ppi) {
        req["destination"] = {
          ppi: `${data.ppi}@ppi`,
        };
      }
    }
    if (data["processingMode"]) {
      req["processingDetail"] = {};
      req["processingDetail"]["processingMode"] = data.processingMode;
      req["processingDetail"]["authType"] = "WRITTEN";
      req["processingDetail"]["addenda"] = [data.addenda];
    }
    if (data.achCompanyDescription) {
      req["processingDetail"]["companyDescription"] =
        data.achCompanyDescription;
    }
    if(data['location']){
      req['processingDetail']['location'] = {
          'id' : data['location']
      }
    }
    if (props.isEdit) {
      req["id"] = context?.data?.body?.id;
      const unwantedRequestArray = ["source", "destination", "method", "type"];
      deleteUnwantedRequest(req, unwantedRequestArray);
  }
    req = clean(req);
    setIsloading(true);
    let status: any;
    status = await createSendMyAccount(req);
    if (status?.headers?.url) {
      sendId = status.headers.url.split("/").pop();
    }

    status = processAPIResponse(status);
    setIsloading(false);
    if (status.status) {
      //api success
      const successMessage = withdrawalInfo? 'Send Updated Successfully!': 'Send Created Successfully!';
      const redirectUrl = withdrawalInfo? `/transactions/send/${withdrawalInfo["id"]}`: '/thanks/transactions/send-onetime-ach/';
      const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
      if(status.responseData?.sessionId){
        const verifyProps = {
            ...createCommonVerifyProps(req, status, false, data?.amount, verifyMessage, 'send'),
            successMessage : successMessage,
            redirectUrl: redirectUrl,
            redirectUrlFromHeader:withdrawalInfo?false: true
        }
        updateComponentParams(dispatch, props.id, { verifyProps })
    }
    else{
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: 'success',
        text: withdrawalInfo ? 'Send Updated Successfully!' : 'Send Created Successfully!',
      });
      if (withdrawalInfo) {
        history.push("/transactions/send/" + withdrawalInfo["id"]);
      } else {
        history.push("/thanks/transactions/send-onetime-ach/" + sendId);
      }
    } 
   } else {
      //api  failed
      FxSnackBar.show({
        text: customErrorMessage(status),
      });
    
    }
  }

  const amount = watch("amount");
  const summaryData: Record<
    string,
    TransactionSummaryFieldType | string | undefined
  > = {
    "Send Amount": {
      id: "amount",
      ValueComponent: FxSummaryCurrency,
      value: amount && !isNaN(amount) ? amount : "",
    },
    From: {
      id: "from",
      value: accountLabelTransformation(getKey("selectedAccount")),
    },
    To: {
      id: "to",
      value: viaPpi
        ? watch("ppi")?watch("ppi") + "@ppi":"@ppi"
        : watch("accountHolderName"),
    },
    ...(businessCustomer
    ? { Purpose: { id: "purpose", value: watch("purpose") } }
    : { Memo: { id: "purpose", value: watch("purpose") } }),
    Via: {
      id: "method",
      value: "ACH",
    },
    ...(!viaPpi && {
      "To External Account": {
        id: "accountNumber",
        value: watch("accountNumber"),
      },
    }),
    "Processing Mode": {
      id: "processing-mode",
      value: watch("processingMode")
        ? watch("processingMode") === "FORWARD"
          ? removeUnderScoreConvertTitleCase("NEXT_DAY")
          : removeUnderScoreConvertTitleCase(watch("processingMode"))
        : "",
    }
  };

  if(businessCustomer) {
    summaryData['Memo'] = {
      id: 'achCompanyDescription',
      value: watch('achCompanyDescription'),
    }

    summaryData['Addenda'] = {
      id: "addenda",
      value: watch("addenda"),
    }
}

  const tabClickFunction = () => {
    try {
      const data: any = {
        schedule: "send",
        type: "one-time",
        formData: { amount: watch("amount"), purpose: watch("purpose") },
      };
      dispatch({
        type: "DATA_UPDATE_COMPONENT_REDUCER",
        payload: { id: "schedule-top-section", data: data },
      });
    } catch (e) {
      Logger.error("CreateSendOneTimeBook.tsx", "error", e); 
    }
  };

  const handleClose = () => {
    if (scheduleId) {
      history.push("/transactions/send/" + scheduleId);
    } else {
      history.push("/dashboard");
    }
  };
         /**
         * Function to handle the closing of the modal.
         */
	
   const handleCloseModal = () => {
    const verifyProps = {...props.verifyProps}
    verifyProps['open'] = false;
    updateComponentParams(dispatch, props.id, { verifyProps });
};
  return (
    <>
    <ScheduleLayout
      id={'create-send-onetime-ach'}
      type='send'
      loading={isloading}
      submitTitle={props.isEdit ? 'Save': 'Send Money'}
      summaryData={summaryData}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => handleClose()}
      saveEnabled={saveEnabled}
      verifyProps={props?.verifyProps}
      handleCloseModal={handleCloseModal}
    >
      <ScheduleCreateMethodTab
        tabItems={getPPISupportedPaymentMethods(
          method_Tab,
          "onetime"
        )}
        id="create-send-onetime-ach-form-card-tab"
        tabClick={tabClickFunction}
      />
      <Grid item>
        <fieldset className="fx-container-create-schdule-summery">
          <legend>Destination Details</legend>
          <Grid item xs={12} sm={12} container spacing={2}> 
            {viaPpi ? (
              !props.isEdit ?
              <Grid item xs={12} sm={12}>
                <FxVerifyPPI
                  id="create-send-to-one-time-ACH-fx-verify"
                  control={control}
                  fieldName="ppi"
                  watch={watch}
                  register={register}
                  errors={errors}
                  onVerificationFinished={(status) => {
                    if (
                      getKey("isPPIEnabled")?.toLowerCase() ===
                      "true"
                    ) {
                      const ppiSupportedMethods = getKey(
                        "ppiSupportedMethods"
                      )?.split(",");
                      const method = ppiSupportedMethods.find(
                        (element: string) =>
                          element?.toLowerCase() ===
                          "ACH".toLowerCase()
                      );
                      if (method) {
                        setSaveEnabled(status ? status : false);
                      } else {
                        setSaveEnabled(false);
                      }
                    } else {
                      setSaveEnabled(false);
                    }
                  }}
                />
              </Grid>
              :
                <Grid item xs={12} sm={12}>
                  <FxTextEdit
                    register={{ ...register("ppi") }}
                    type="text"
                    className={
                      errors.destination
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    control={control}
                    id="edit-send-to-one-time-ACH-ppi"
                    label="Payment ID"
                    name="ppi"
                    isEditable={false}
                    suffix={"@ppi"}
                  />
                </Grid>

            ) : (
              <>
                <Grid item xs={12} sm={12}>
                  <FxTextEdit
                    register={{ ...register("accountHolderName") }}
                    className={
                      errors.accountHolderName
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    control={control}
                    rules={{ required: props.isEdit ? false : true }}
                    id="create-send-onetime-holdername-ach-onetime-form-card-amount-textbox"
                    label="Account Holder Name"
                    name="accountHolderName"
                    type="text"
                    variant="outlined"
                    defaultValue={
                      context?.data?.body?.destination?.externalAccount?.holderName
                        ? context?.data?.body?.destination?.externalAccount?.holderName
                        : ""
                    }
                    isEditable={!props.isEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FxMaterialSelect
                    register={{ ...register("accountType") }}
                    control={control}
                    rules={{ required: true }}
                    id="create-send-onetime-ach-form-card-account-type"
                    name="accountType"
                    data={accountType}
                    value={context?.data?.body?.destination?.externalAccount?.type || "SAVINGS"}
                    label="Account Type"
                    setValue={setValue}
                    readOnly={!!props.isEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FxMaterialSelect
                    register={{ ...register("holderType") }}
                    control={control}
                    rules={{
                      required: !props.isEdit,
                    }}
                    className={
                      errors.accountPurpose
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    id="create-payee-form-card-account-purpose"
                    data={holderTypeData}
                    name="holderType"
                    readOnly={!!props.isEdit}
                    label="Holder Type"
                    setValue={setValue}
                    value={context?.data?.body?.destination?.externalAccount?.holderType || "CORPORATE"}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FxTextEdit
                    register={{ ...register("accountNumber") }}
                    className={
                      errors.accountNumber
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    control={control}
                    rules={!props.isEdit ? setAccountNumberValidation : {}}
                    id="create-send-onetime-accountnumber-ach-onetime-form-card-amount-textbox"
                    label="Account Number"
                    name="accountNumber"
                    type="text"
                    variant="outlined"
                    defaultValue={
                      context?.data?.body?.destination?.externalAccount?.accountNumberLast4
                        ? ('****'+context?.data?.body?.destination?.externalAccount?.accountNumberLast4)
                        : ""
                    }
                    isEditable={props.isEdit ? false : true}
                  />
                   <div className={"error-message"}>
                    {errors.accountNumber &&
                    renderAccountNumberError(errors.accountNumber)}
                  </div>
                </Grid>
                {(context?.data?.body?.destination || !scheduleId) && <Grid item xs={12} sm={12}>
                  <FxAutoComplete
                    register={{ ...register("routingNumber") }}
                    control={control}
                    rules={setRoutingNumberValidation}
                    id="create-external-account-form-card-routing-number"
                    name="routingNumber"
                    source={bank_src}
                    searchBy={"searchPattern"}
                    label="Routing Number*"
                    className={
                      errors.routingNumber
                        ? "border-error-input fx-input-edit"
                        : "fx-input-edit"
                    }
                    value={
                      context?.data?.body?.destination?.externalAccount?.bankInfo?.routingNumber
                        ? {
                          label:
                          context?.data?.body?.destination?.externalAccount?.bankInfo?.routingNumber + " (" + context?.data?.body?.destination?.externalAccount?.bankInfo?.name + ")",
                          value:
                          context?.data?.body?.destination?.externalAccount?.bankInfo?.routingNumber,
                        }
                        : null
                    }
                    dataTransformer={routingNumberTransformer}
                    setValue={setValue}
                    resetField={resetField}
                    setError={setError}
                    clearError={clearErrors}
                    defaultOperator={"eq"}
                    placeholder={"Routing Number"}
                    isEditable={(context?.data?.body?.destination?.externalAccount?.bankInfo?.routingNumber) ? false : true}
                  />
                  <div className={"error-message"}>
                    {errors.routingNumber &&
                      renderRoutingNumberError(
                        errors.routingNumber
                      )}
                  </div>
                </Grid>}
              </>
            )}
          </Grid>
        </fieldset>
      </Grid>
      <ScheduleTopSection
        id="create-send-one-time-ach-schedule-top-section"
        schedule="send"
        type="one-time"
        method='ACH'
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        acc_src={acc_src}
        dataSourceTransformation={dataSourceTransformation}
        amountId="create-send-onetime-ach-form-card-amount-textbox"
        sourceId="create-send-onetime-ach-form-card-source"
        purposeId="create-send-onetime-ach-form-card-purpose-textbox"
        isTopSectionMoved={!scheduleId}
        isBottomPaddingAllowed={!businessCustomer}
      />
      {((location || !scheduleId) && businessCustomer) && <Grid item xs={12} sm={12}>
        <FxLocationSelectComponent
          label='Location'
          register={{ ...register("location") }}
          control={control}
          rules={{ required: false }}
          id="create-send-one-time-ach-form-location"
          name="location"
          className="fx-input-edit"
          setError={setError}
          clearError={clearErrors}
          setValue={setValue}
          resetField={resetField}
          isEditable={!scheduleId}
          value={location ? {
            label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
            value: location?.id,
          }: null}
        />
      </Grid>}
      <Grid item container xs={12}>
        <Grid item xs={12} sm={12}>
          <FxMaterialSelect
            register={{ ...register("processingMode") }}
            control={control}
            rules={{ required: true }}
            id="create-send-onetime-ach-form-card-processing-mode"
            name="processingMode"
            data={processingModeData}
            value={"SAME_DAY"}
            label="Processing Mode"
            setValue={setValue}
          />
        </Grid>
      </Grid>
      {businessCustomer && <Grid item xs={12} sm={12}>
        <FxTextEdit
          register={{ ...register("achCompanyDescription") }}
          className={
            errors.achCompanyDescription
              ? "border-error-input fx-input-edit"
              : "fx-input-edit"
          }
          control={control}
          rules={{ required: false }}
          id="create-collect-from-others-ach-form-card-ach-company-description-textbox"
          label="Memo"
          name="achCompanyDescription"
          variant="outlined"
          defaultValue={
            context?.data?.body?.processingDetail
              ?.companyDescription
              ? context.data.body.processingDetail
                  .companyDescription
              : ""
          }
        />
      </Grid>}
      {businessCustomer && <Grid item xs={12} sm={12}>
        <FxTextEdit
          register={{ ...register("addenda") }}
          control={control}
          id="create-send-account-ach-form-card-addenda-textbox"
          label="Addenda"
          name="addenda"
          variant="outlined"
          defaultValue={
            context?.data?.body?.addenda
              ? context.data.body["addenda"]
              : ""
          }
        />
      </Grid>}
    </ScheduleLayout>
    </>
  );
});
