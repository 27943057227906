/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { Logger } from "../../../libs/utils/logger";
import FxCard from "../../Container/FxCard";
import { LedgerBatchCategoryCard } from "./FxLedgerBatchCategoryCard";
import FxCardHeader from "../../Container/FxCardHeader";
import { FxStatus } from "../../Input/FxStatus/FxStatus";
import FxCardBody from "../../Container/FxCardBody";
import { FxCurrencyView } from "../../Input/FxCurrency/FxCurrencyView";
import { useIsMobileOrTabletScreen } from "../../../libs/utils/utils";
import { FxDateTimeView } from "../../Input/FxDate/FxDateView";

Logger.debug("LedgerBatchSummary.tsx", "Ledger Batch Summary initializing")

/**
 * Component used to show Ledger Batch Summary
 */

export const LedgerBatchSummary: React.FC<any> = (props) => {
    ({ props } = RegisterComponent(props));
    const batchSummaryData = props?.data?.data;
    const [batchCardData, setBatchCardData] =  useState([]);

      useEffect(() => {
        dataTransform();
      }, []);


    /**
     * Method to Transform the data into a component friendly form and calculating the percentages for the Linear Graph
     **/
    const dataTransform = () => {
       const data :any = [
        {
          title: 'SETTLED',
          amount: batchSummaryData?.totalPostedAmount,
          description: 'This amount represents the sum of all the settled transactions in this batch.',
          refundPostedAmount: batchSummaryData?.refundPostedAmount
        },{
          title: 'SETTLEMENT ADJUSTMENTS',
          amount: batchSummaryData?.settlementAdjust,
        },{
            title: 'PROCESSOR HOLDS',
            amount: batchSummaryData?.holdAmount,
            description: 'Amount temporarily held by the processor for additional verification.'
        }
      ]
      setBatchCardData(data)
    }


    return (
      <Grid container xs={12} className="fx-layout-list" >
                <FxCard id="account-ledger-card" className={useIsMobileOrTabletScreen() ? "fx-theme-passport fx-ledger-summary-card fx-ledger-summary-card-mobile" : "fx-theme-passport fx-ledger-summary-card"}>
                <FxCardHeader className="fx-card-header">
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container columnSpacing={2}>
                                <Grid item>
                                    <Typography id="title-payees-info" variant="h3">Batch Summary</Typography>    
                                </Grid>
                                <Grid item container alignContent={'center'} >
                                    <FxStatus id="account-ledger-status" value= {batchSummaryData?.status} fill={true} className="fx-status"></FxStatus>
                                </Grid> 
                            </Grid> 
                            {batchSummaryData?.lastUpdatedOn && <Grid container item spacing={1}>
                                <Grid item>
                                    <Typography className="fx-ledger-summary-card-subtitle">AS ON&nbsp;<FxDateTimeView value={batchSummaryData?.lastUpdatedOn} format=" MMM DD, yyyy" /></Typography>   
                                </Grid>                               
                            </Grid> }
                        </Grid>                                             
                    </Grid>    
                </FxCardHeader>
                <FxCardBody className="fx-info-card fx-margin-top-reducer">                   
                    <Grid container columnSpacing={2} alignItems="center">
                        <Grid item xs={12} md={4}>
                            <Grid item container className="fx-ledger-summary-total-section">                                   
                                <Grid item container direction={"column"}>
                                    <Grid item>
                                        <Grid item container columnSpacing={2} alignContent={"center"}>
                                            <Grid item>
                                                <Typography id="" variant="subtitle1" >TOTAL BATCH AMOUNT</Typography>
                                             </Grid>                                            
                                        </Grid>                                            
                                    </Grid>
                                    <Grid item>
                                        <Typography id="" className="fx-transaction-summery-currency">
                                            <FxCurrencyView id="" value={batchSummaryData?.totalAmount} prefix={"$"} />
                                        </Typography>
                                    </Grid>
                                </Grid> 
                                <Grid item container className="fx-ledger-summary-total-value">
                                    <Grid item  xs={6}>
                                    <Typography id="" variant="subtitle1" >SALES ({batchSummaryData?.credit?.count})</Typography>
                                    <Typography id="" className="fx-sales-refund-grid" >
                                            <FxCurrencyView id="" value={batchSummaryData?.credit?.amount} prefix={"$"} />
                                        </Typography>
                                    </Grid>
                                    <Grid item  xs={6}>
                                         <Typography id="" variant="subtitle1" >REFUND ({batchSummaryData?.debit?.count})</Typography>
                                        <Typography id="" className="fx-sales-refund-grid">
                                            <FxCurrencyView id="home-account-info-card-currencyview" value={batchSummaryData?.debit?.amount} prefix={"$"} />
                                        </Typography>
                                    </Grid>                                   
                                </Grid>
                            </Grid> 
                        </Grid>
                        { !useIsMobileOrTabletScreen() && <Grid item xs={12} md={8}>       
                          <div className="ledger-graph-category-cards-block">
                            {batchCardData.map((item:any, index:any) => (
                              <div className="ledger-graph-category-card">
                                <LedgerBatchCategoryCard item={item} batchSummaryData={batchSummaryData}/>
                              </div>
                            ))}
                          </div>             
                        </Grid>}
                    </Grid>
                </FxCardBody>
            </FxCard>
            { useIsMobileOrTabletScreen() && 
                <Grid item xs={12} md={8}>       
                    <div className="ledger-graph-category-cards-block-mobile">
                    {batchCardData.map((item:any, index:any) => (
                        <FxCard>
                            <FxCardBody>
                                <LedgerBatchCategoryCard item={item} batchSummaryData={batchSummaryData}/>
                            </FxCardBody>
                        </FxCard>
                    ))}
                    </div>              
                </Grid>}
        </Grid>
    );
};
