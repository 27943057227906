/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react"; // we need this to make JSX compile
import { Grid, Typography } from "@mui/material";
import { Logger } from "../../../../libs/utils/logger";
import { RegisterComponent } from "../../../../libs/saga/dataSaga";
import { FxCurrencyView } from "../../../Input/FxCurrency/FxCurrencyView";
import { ReactComponent as DividerIcon } from "../../../../assets/svg/divider-icon.svg";
import { useSelector } from "react-redux";
import { ShowInfoToolTip } from "../../../Utils/CommonBaseClass";

Logger.debug(
  "ViewBalanceDetails.tsx",
  "View Balance Detail Modal initializing"
);

/**
 * This component handles the View Balance Detail Modal
 */
export const ViewBalanceDetails: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));

  /**
   * To get balanceDetails
   */
  const balanceDetails = useSelector((state: any) => {
    if (state?.data["customer-basic-info"]) {
      return state.data["customer-basic-info"]?.params?.viewBalanceDetails;
    }
  });

  return (
    <Grid container className={"fx-container"}>
      <Grid container item xs={12}>
        <Grid item container xs={12} justifyContent="flex-start">
          <Typography className="fx-header-title fx-accountinfo-modal-section-main">
          Pending Balance
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        className="fx-info-card fx-accountinfo-balance-account"
      >
        <span>
          <DividerIcon />
        </span>
        <Grid item container xs={12} className="fx-top-move-money-menu">
          {/** Reserved Funds */}
          { balanceDetails?.reservedFunds?.amount > 0 && (
            <Grid
              item
              container
              justifyContent="space-between"
              className="fx-accountinfo-modal-section-title fx-accountinfo-modal-section-title-space"
            >
              <Grid item>
                <Typography className="fx-accountinfo-balance-subtitle">
                  Reserved Funds {ShowInfoToolTip('fx-accountinfo-modal-section-tooltips','Funds held from settlement as agreed upon during onboarding.')}
                </Typography>
              </Grid>
              <Grid item className={"fx-balance-summary-currency"}>
                <FxCurrencyView
                  value={balanceDetails?.reservedFunds?.amount}
                  prefix={"$"}
                />
              </Grid>
            </Grid>
          )}

          {/** Suspended Funds */}
          {balanceDetails?.suspendedFunds?.amount > 0 && (
            <Grid
              item
              container
              justifyContent="space-between"
              className="fx-accountinfo-modal-section-title fx-accountinfo-modal-section-title-space"
            >
              <Grid item>
                <Typography className="fx-accountinfo-balance-subtitle">
                  Suspended Funds {ShowInfoToolTip('fx-accountinfo-modal-section-tooltips','Funds held from settlement due to a processing exception.')}
                </Typography>
              </Grid>
              <Grid item className={"fx-balance-summary-currency"}>
                <FxCurrencyView
                  value={balanceDetails?.suspendedFunds?.amount}
                  prefix={"$"}
                />
              </Grid>
            </Grid>
          )}

          {/** Processor Holds */}
          {balanceDetails?.processorHoldBalance?.amount > 0 && (
            <Grid
              item
              container
              justifyContent="space-between"
              className="fx-accountinfo-modal-section-title fx-accountinfo-modal-section-title-space"
            >
              <Grid item>
                <Typography className="fx-accountinfo-balance-subtitle">
                  Processor Holds {ShowInfoToolTip('fx-accountinfo-modal-section-tooltips','Amount temporarily held by the processor for additional verification.')}
                </Typography>
              </Grid>
              <Grid item className={"fx-balance-summary-currency"}>
                <FxCurrencyView
                  value={balanceDetails?.processorHoldBalance?.amount}
                  prefix={"$"}
                />
              </Grid>
            </Grid>
          )}

           {/** Target Balance Holds */}
           {balanceDetails?.targetBalanceHold?.amount > 0 && (
            <Grid
              item
              container
              justifyContent="space-between"
              className="fx-accountinfo-modal-section-title fx-accountinfo-modal-section-title-space"
            >
              <Grid item>
                <Typography className="fx-accountinfo-balance-subtitle">
                  Target Balance Hold {ShowInfoToolTip('fx-accountinfo-modal-section-tooltips','Funds reserved for transactions initiated by linked subsidiary accounts, temporarily unavailable until settled.')}
                </Typography>
              </Grid>
              <Grid item className={"fx-balance-summary-currency"}>
                <FxCurrencyView
                  value={balanceDetails?.targetBalanceHold?.amount}
                  prefix={"$"}
                />
              </Grid>
            </Grid>
          )}

          {/** Debit Card Holds */}
          {balanceDetails?.debitCardHoldBalance?.amount > 0 && (
            <Grid
              item
              container
              justifyContent="space-between"
              className="fx-accountinfo-modal-section-title fx-accountinfo-modal-section-title-space"
            >
              <Grid item>
                <Typography className="fx-accountinfo-balance-subtitle">
                  Debit Card Holds
                </Typography>
              </Grid>
              <Grid item className={"fx-balance-summary-currency"}>
                <FxCurrencyView
                  value={balanceDetails?.debitCardHoldBalance?.amount}
                  prefix={"$"}
                />
              </Grid>
            </Grid>
          )}

          {/** Total */}
          <Grid
            item
            container
            justifyContent="space-between"
            className="fx-grey-background fx-accountinfo-modal-section-subtitle fx-accountinfo-modal-section-title-space"
          >
            <Grid item className="">
              <Typography className="fx-accountinfo-balance-title ">
                Total
              </Typography>
            </Grid>
            <Grid item className={"fx-balance-summary-currency"}>
              <FxCurrencyView
                value={balanceDetails?.reserveAndHoldBalance}
                prefix={"$"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
