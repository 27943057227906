/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { DataGrid } from '../../../Data/DataGrid';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCardBody from '../../../Container/FxCardBody';
import FxCardFooter from '../../../Container/FxCardFooter';
import { IFilterColumns, IFilterFields, IDataGridProps } from '../../../../types/common.interfaces';
import { FxPaymentModeStatus } from '../../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { Filter } from '../../../Data/Filter';
import { getKey } from '../../../../libs/utils/storageManager';
import { FxCurrencyView } from '../../../Input/FxCurrency/FxCurrencyView';
import { truncateChars, updateComponentParams } from '../../../Utils/CommonBaseClass';
import { isBatchRequired, setColumnsBasedOnSettings } from '../../../Utils/ThemeChecker';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../../Data/FxGridList';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as HoldLedgerIcon } from '../../../../assets/svg/hold-ledger.svg';
import {  getBatchNumberJsx } from '../../../Utils/batch';
import { LedgerSummaryActionCard } from './LedgerSummaryActionCard';
import HttpClient from '../../../../libs/utils/httpClient';
import { getCustomerUrl } from '../../../../libs/utils/utils';
import { Logger } from '../../../../libs/utils/logger';
import { FxButtonGroup } from '../../../Action/FxButtonGroup';
import { FxStatus } from '../../../Input/FxStatus/FxStatus';

const httpClient = HttpClient.getClient();
/**
 * @author Vishesh
 * Component used to show pending account ledgers
 */

/**
 * This component handles the listing of the ledgers
 */
export const PendingTransactionHistory: React.FC = React.memo(() => {
    const dispatch = useDispatch();
    const [creditData, setCreditData] = useState();
    const [accountData, setAccountData] = useState();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
    const location: any = useLocation();
    const history = useHistory()
    let creditLineData;

    const selectedLedgerType = location.state?.ledgerType ?? 'allLedgers';

        const accountId = getKey("selectedAccount")?.id;
        function sourceInfo() {
            const filters: any = [];
            if (accountId) {
                const src = {
                    url: "account/id/"+getKey("selectedAccount")?.id+"/ledger/list",
                    method: "POST",
                    data: {
                        "pageNumber": 1,
                        "pageSize": 25,
                        "sortOptions": {
                            "sortBy": "lastUpdatedOn",
                            "sortOrder": "desc"
                        },
                        "criteria": {
                        }
                        
                    }
                }

                try {
                    filters.push({
                            "operator": "gte",
                            "key": "lastUpdatedOn",
                            "values": [
                                moment().subtract(1, 'months').format('MM/DD/YYYY')
                            ]
                        },
                        {
                            "operator": "eq",
                            "key": "customView",
                            "values": [ "true" ]
                        },
                        {
                            "operator": "eq",
                            "key": "isPending",
                            "values": [ "true" ]
                        },
                        {
                            "operator": "lte",
                            "key": "lastUpdatedOn",
                            "values": [
                                moment().format('MM/DD/YYYY')
                            ]
                        }, {
                            "operator": "eq",
                            "key": "account.id",
                            "values": [accountId]
                        },
                        {
                            "operator": "eq",
                            "key": "isRealTime",
                            "values": [
                                "true"
                            ]
                        })

                        selectedLedgerType && selectedLedgerType!=='allLedgers' && filters.push({
                            "operator": "eq",
                            "key": "ledgerType",
                            "values": [selectedLedgerType]
                    })


                    src.data.criteria = { filters }
                }

                catch (e) {
                    Logger.error("TransactionHistory.tsx", "error", e);
                }
                return src
            }else{
                return  {
                    url: "",
                    method: "POST",
                    data: {}
                }
            }
        }
    
    // type of ledgers
    const ledgerTypeData = [
        { id: 'COMPLETED_LEDGERS', value: 'COMPLETED_LEDGERS', label: 'Completed', selected: false },
        { id: 'PENDING_LEDGERS', value: 'PENDING_LEDGERS', label: 'Pending', selected: true }
    ];

    /**
     * Method handles the change event of the ledger Type
     */
    const handleActionChange = (item: any) => {
        if (item?.value === 'COMPLETED_LEDGERS') {
              history.push('/accountledgers');
        }
    }

    /**
    * useEffect to load title while loading page
    */
    useEffect(() => {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Account Activity' } } });
        getCreditLimitData();
        getAvailableBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Method to call the GET Accout Details API to fetch the available account balance and store it in a state
     */
    async function getAvailableBalance(){
        const balance = await getSelectedAccountDetails();
        setAccountData(balance);
    }

    /**
     * Method calls the GET account API for the selected account and return the data
     * @returns account data
     */
    async function getSelectedAccountDetails(){
        try {
            const data = await httpClient.get(getCustomerUrl("/account/id/" + getKey("selectedAccount")?.id, false));
            if(data && data.data){
              return data.data;
            }
          } catch (err) {
            Logger.error("TransactionHistory.tsx", "error", err);
            return err;
          }
    }

    /**
     * This async method calls the Credit Limit GET api, and waits for the response data and then sets the state
     */
    async function getCreditLimitData(){
        creditLineData = await getCreditLineDetails();
        setCreditData(creditLineData);
    }

    /**
     * Method to call the Credit Line API to get the Faster Funds Available Amount
     */
    async function getCreditLineDetails(){
        try {
            const data = await httpClient.get(getCustomerUrl("/account/id/" + getKey("selectedAccount")?.id + "/fasterFunds", false));
            if(data && data.data){
              return data.data;
            }
          } catch (err) {
            Logger.error("TransactionHistory.tsx", "error", err);
            return err;
          }
    }

    /**
    * useEffect to reset the filter while unmount the page
    */
      useEffect(() => () => {
        updateComponentParams(dispatch, 'account-ledger-card-filter', { resetFilter: true });
    }, [dispatch]);

        const column: IFilterColumns = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "icon",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "4.5rem", "padding-left": "0 !important" },
                    "dataStyle": { "text-align": "left !important" }
                },
                {
                    "label": "DATE",
                    "enable": true,
                    "fieldName": "date",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "10rem" }
                },
                {
                    "label": "TXN ID",
                    "enable": true,
                    "fieldName": "transactionId",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "10rem" },
                },
                {
                    "label": "NARRATION",
                    "enable": true,
                    "fieldName": "narration",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "20rem" },
                },
                {
                    "label": "CREDIT",
                    "enable": true,
                    "fieldName": "creditAmount",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": {"width": "10rem", "text-align": "end" }
                },
                {
                    "label": "DEBIT",
                    "enable": true,
                    "fieldName": "debitAmount",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": {"width": "10rem", "text-align": "end" }
                },
                {
                    "enable": true,
                    "fieldName": "spacer",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "2rem"}
                },
            ]
        }

        const gridColumn = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "divider",
                    "type": "fri://fieldtype/divider",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "header",
                    "type": "fri://fieldtype/header",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "description",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                    "alignItems": 'center'
                }

            ]
        }

        /**
         * Method handles the state that from which parent component BatchDetail is being called
         */
        const handleTabClick = (item:any) => {
            const { processorBatchDetails, batch } = item || {};
            const { trimmedBatchRef, batchRef, batchHash } = processorBatchDetails || {};
            const batchId = batch?.id;

            history.push({
                pathname: (trimmedBatchRef ? `/ledgerBatchDetails/${trimmedBatchRef}/${batchId}` : `/ledgerBatchDetails/${batchRef}/${batchId}` ),
                state: {batchHash: batchHash, url:'/accountledgers'}
            })
        }

        /**
         * Method handles the transformation of ledger data
         * @param data
         * @returns : transformed data
         */
        function transactionHistoryList(data: any) {
            const newData: any = [];
            data?.forEach((item: any, i: number) => {
                newData[i] = { ...data[i] }
                newData[i].icon = <div><HoldLedgerIcon /></div>
                newData[i].date = <FxStatus id="treasure-portfolio-status" value={"PENDING"} fill={true} className="fx-status"></FxStatus>
                newData[i].transactionId = item?.schedule?.id ? <Typography>{item.schedule.id}</Typography>  : '-'  
                newData[i].narration = <>
                    <span title={item.narration}>{` ${item.narration ? truncateChars(item?.narration, 40) : ''}`} &nbsp;</span>
                    <FxPaymentModeStatus tooltip={true} id={'recurring-method'} 
                        value={ (item.ledgerType === "HOLD" || item.ledgerType === 'RELEASE') ? "CARD" : item?.scheduleClass === 'SYSTEM_FEE' ? "FEE" : (item?.ledgerType && item?.ledgerType === 'FASTER_FUNDED') ? "FASTER FUNDED" : item?.isAdjustment === true ? "ADJUSTMENTS" : item?.method} 
                        noColor={true} fill={false} className="fx-status">
                    </FxPaymentModeStatus>
                </>
                newData[i].creditAmount = item.type === "CREDIT" ? <FxCurrencyView className={ "balance-amount-green"} justifyContent="flex-end" value={item.amount} prefix={"$"} /> : <Grid container justifyContent="flex-end" className='fx-padding-right-16'>-</Grid>
                newData[i].debitAmount = item.type === "DEBIT" ? <FxCurrencyView className={ "balance-amount-green"} justifyContent="flex-end" value={item.amount} prefix={"$"} /> : <Grid container justifyContent="flex-end" className='fx-padding-right-16'>-</Grid>     
            });

            return newData;
        }
         /**
         * @param data : Transaction List
         * @returns : transformed data
         */
        const transactionHistorytGridTransformer =(data:any)=>{
            const newData: any = [];
            let cur_date: string = '';
            data?.forEach((item: any, i: number) => {
                newData[i] = { ...data[i] }
                let title;
                if (cur_date === '' || cur_date !== moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')) {
                    cur_date = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
                    title = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
                }
                if (!title) {
                    newData[i].divider = true
                }
                if (title) {
                    newData[i].header = <Grid item className='fx-grid-list fx-padding-top-header'> <Typography variant='h6'>{title}</Typography></Grid>
                }
                // eslint-disable-next-line eqeqeq
                newData[i].description = <Grid item container xs={12} className='fx-grid-list-content'>
                    <Grid item container xs={2} alignItems='center' justifyContent={'flex-start'}>
                        <div><HoldLedgerIcon /></div>
                    </Grid>
                    <Grid item container xs={10} alignItems='center' alignContent={'center'}>
                        <Grid item container xs={12}>
                            <Grid item>
                                <Grid title={item?.narration}><Typography variant='h3'><span>{truncateChars(item?.narration, 25)}&nbsp;</span></Typography></Grid>
                            </Grid>
                            <Grid item>
                                &nbsp;<FxPaymentModeStatus tooltip={true} id={'transaction-method'} value={(item.ledgerType === "HOLD" || item.ledgerType === 'RELEASE') ? "CARD"  : item?.scheduleClass === 'SYSTEM_FEE' ? "FEE" : (item?.ledgerType && item?.ledgerType === 'FASTER_FUNDED') ? "FASTER FUNDED" : item?.isAdjustment === true ? "ADJUSTMENTS" : item?.method} noColor={true} fill={false} className="fx-status"></FxPaymentModeStatus>
                            </Grid>
                            <Grid item container xs justifyContent='end'>
                                <Grid item xs>
                                    <Grid>{item.type === "DEBIT" ? <FxCurrencyView className={"balance-amount-red"} value={item.amount} justifyContent="flex-end" prefix={"$"} showPlus /> : <FxCurrencyView className={"balance-amount-green"} value={item.amount} justifyContent="flex-end" prefix={"$"} showPlus />}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs alignItems='center'>
                            {item?.memo && <Grid item>
                                <Grid><Typography variant='h5'>Memo: {item?.memo}&nbsp;</Typography></Grid>
                            </Grid>}
                            {item?.schedule?.id && <Grid item>
                                <Grid><Typography variant='h5'>Txn ID: {item?.schedule?.id}</Typography></Grid>
                            </Grid>}
                            {getBatchNumberJsx(item, isSmallScreen,()=>handleTabClick(item))}
                        </Grid>
                    </Grid>
                </Grid>
            });
            return newData;
        }
        const datagridProps: IDataGridProps = {
            id: "pending-account-ledger-data-grid-list",
            column: column,
            pagination: true,
            pageNumber: 1,
            pageSize: 25,
        };

        const filterFields: IFilterFields = {
            "fields": [
                {
                    "label": "TXN ID",
                    "fieldName": "transactionId",
                    "type": "fri://fieldtype/textfield",
                    "operator":"eq"
                },
                {
                    "label": "Method",
                    "fieldName": "method",
                    "type": "fri://fieldtype/checkbox",
                    "className": "filter-divider filter-tri-row-checkbox-divider",
                    "data": [
                        { label: "ACH", value: "ACH", "checked": false },
                        { label: "Card", value: "CARD", "checked": false },
                        { label: "Check",value: "CHECK", "checked": false },
                        { label: "Book", value: "BOOK", "checked": false },
                        { label: "Wire", value: "WIRE", "checked": false },
                        { label: "International Wire", value: "INTERNATIONAL_WIRE", "checked": false },
                        { label: "Virtual Card", value: "VIRTUAL_CARD", "checked": false }
                    ]
                },
                {
                    "label": "Date",
                    "toLabel": "To",
                    "fieldName": "lastUpdatedOn",
                    "type": "fri://fieldtype/daterange",
                    "data": [],
                    "notAllowFutureDate": true,
                    "defaultValue": {
                        "from": moment().subtract(1, 'months').format('MM/DD/YYYY'),
                        "to": moment().format('MM/DD/YYYY')
                    }
                }
            ]
        }
        const permission ={
            entity: "Accounts",
            name: "Ledger",
            operation: "View"
          }

        return (
            <Grid id="pending-account-ledger-first-grid" item xs={12}>
              <Grid item xs={12}>
                    <LedgerSummaryActionCard id="pending-ledger-summary-action-card" data={creditData} accountData={accountData} />
                </Grid>
                <Grid item xs={12} >&nbsp;</Grid>
                <Grid container xs={12} className="fx-layout-list" >
                    <FxCard id="pending-account-ledger" className="fx-theme-passport pending-ledgers">
                        <FxCardHeader>
                            <Grid item xs={12} className="fx-flex-grow fx-tab-border fx-ea-type-button-mobile" >
                                <FxButtonGroup id="all-transaction-card-button-group" options={ledgerTypeData} onClick={handleActionChange} />
                            </Grid>
                            <Grid id="pending-account-ledger-header-first-grid" item xs justifyContent="flex-end" className="fx-account-ledger-header" display="flex">
                                <Grid id="pending-account-ledger-card-header-filter-grid" item className="fx-inline-flex">
                                    <Filter id="pending-account-ledger-card-filter" field={isBatchRequired(filterFields)} permissions={permission} dataGridProps={datagridProps} hiddenParameter={['account.id','customView','isRealTime','isPending']}></Filter> 
                                </Grid>
                            </Grid>
                            
                        </FxCardHeader>
                        <FxCardBody id="pending-account-ledger-card-body" className="fx-card-body-no-border-no-shadow">
                            <Grid id="pending-account-ledger-card-body-grid" item xs={12}>
                                {!isSmallScreen ? <DataGrid id="pending-account-ledger-data-grid-list" permissions={permission} tableClass="fx-table-passport no-hover-table" dataTransformer={transactionHistoryList} source={sourceInfo()} column={setColumnsBasedOnSettings(column)} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                                    : <FxGridList id="pending-account-ledger-data-grid-list" source={sourceInfo()} permissions={permission} dataTransformer={transactionHistorytGridTransformer} column={setColumnsBasedOnSettings(gridColumn)} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />}
                            </Grid>
                        </FxCardBody>
                        <FxCardFooter id="pending-account-ledger-card-footer">
                        </FxCardFooter>
                    </FxCard>
                </Grid>
            </Grid>

        )
    })
