/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Divider, Grid } from '@mui/material';
import { ReactComponent as FDICLogo } from '../../../assets/svg/fair-and-transparent-pricing.svg';
import { ReactComponent as FastFundingLogo } from '../../../assets/svg/diversified-payment.svg';
import { ReactComponent as DebitCardLogo } from '../../../assets/svg/paid-faster.svg';
import { ReactComponent as BillPayLogo } from '../../../assets/svg/contactless-payment.svg';
import { ReactComponent as CollectStoreSendLogo } from '../../../assets/svg/scalable.svg';
import { ReactComponent as SecureLogo } from '../../../assets/svg/secure.svg';
import { ReactComponent as SystemAutomationLogo } from '../../../assets/svg/system-automations.svg';
import { ReactComponent as PowerfulSolutionLogo } from '../../../assets/svg/powerful-solutions.svg';
import { ReactComponent as GenerateCashbackLogo } from '../../../assets/svg/generate-cashback.svg';
import { ReactComponent as OperationsAndSalesLogo } from '../../../assets/svg/operations-and-sales.svg';
import { ReactComponent as MonetizePaymentsLogo } from '../../../assets/svg/monetize-payments.svg';
import { getKey } from '../../../libs/utils/storageManager';
import { LogoAccount } from '../../Utils/Logo';

/**
 * Component used inside the right drawer as a left layout for fast funding and applying for debit card flows
 */

interface IFxBaseLayout {
    type?: 'primary' | 'secondary';
    layoutHeader: React.ReactElement;
}

export interface IInfoItem {
    class?:any;
    header?: string;
    text: string;
    Icon: any;
}

export const FxInfoLayout: React.FC<IFxBaseLayout> = React.memo(({ layoutHeader, type = 'primary' }) => {

    const layoutItemsPrimary: IInfoItem[] = [
        {
            Icon: FDICLogo,
            class:'fdic-icon-theme',
            header: 'FDIC insured* account',
            text: 'Pass through FDIC insurance on accounts'
        },
        {
            Icon: FastFundingLogo,
            class:'fast-fund-icon-theme',
            header: 'Faster Funding',
            text: 'Get cash in hand faster after accepting payments from your customers'
        },
        {
            Icon: DebitCardLogo,
            class:'debit-card-icon-theme',
            header: 'Debit Card',
            text: 'Corporate debit cards for you & your employees'
        },
        {
            Icon: BillPayLogo,
            class:'vendor-icon-theme',
            header: 'Vendor Payments',
            text: 'Online Vendor Payments and recurring electronic payments'
        },
        {
            Icon: CollectStoreSendLogo,
            class:'collect-store-icon-theme',
            header: 'Collect-Store-Send',
            text: 'Recurring Payments, ACH, Book, Check, Direct Deposit, Virtual Cards, and more'
        }

    ];

    const layoutItemsSecondary: IInfoItem[] = [
        {
            Icon: GenerateCashbackLogo,
            text: 'Get Faster Funding with Passport'
        },
        {
            Icon: SecureLogo,
            text: 'FDIC insured Account'
        },
        {
            Icon: SystemAutomationLogo,
            text: 'Zero Transaction Fees on ACH, Check, Bill Pay'
        },
        {
            Icon: MonetizePaymentsLogo,
            text: 'No Setup fees and No minimum balances'
        },
        {
            Icon: PowerfulSolutionLogo,
            text: 'Unlimited free book transfers'
        },
        {
            Icon: OperationsAndSalesLogo,
            text: 'Corporate debit cards'
        },
        {
            Icon: CollectStoreSendLogo,
            text: 'Collect - Store - Send - Experience the power of unified commerce with Passport'
        }

    ];

    const infoItems = {
        primary: layoutItemsPrimary,
        secondary: layoutItemsSecondary
    }

    const infoView = (() => infoItems[type].map((item) => {
        return (
            <Grid container item xs={12} className={'fx-drawer-base-layout-info-item'}
                  alignItems={type === 'primary' ? 'flex-start' : 'center'}
                  justifyContent='space-between'
            >
                <Grid item xs={1} className={'fx-info-item-icon'}>
                    <item.Icon className={item?.class}/>
                </Grid>
                <Grid item xs={10}>
                    {item.header && <h3>{item.header}</h3>}
                    <p>{item.text}</p>
                </Grid>
            </Grid>
        )
    }))

    return (
        <Grid container className={'fx-drawer-left-layout fx-drawer-base-layout'}>
            <Grid container item>
            <Grid item xs={12} className={'fx-left-layout-header'}>
                {type === 'primary' && <Grid item xs={2} className={'fx-logo'}><LogoAccount /></Grid>}
                <Grid item xs={10}>
                    <h2>Welcome!</h2>
                    <h2>{getKey('CustomerName')}</h2>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} className={'fx-left-layout-passport'}>
                {layoutHeader}
            </Grid>
            {type === 'secondary' && <Grid item xs={12} className={'fx-simple-pricing'}>
                <div className={'fx-simple-pricing-divider'} />
                <div className={'fx-simple-pricing-content'}>
                    <p>Simple Pricing,</p>
                    <p>No Hidden Charges</p>
                </div>
            </Grid>}
            {infoView()}
            <div className={'fx-drawer-left-layout-footer-prenote'}>*Pass-through FDIC insurance is subject to FDIC rules.</div>
            </Grid>
        </Grid>
    )
})