/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'; // we need this to make JSX compile
import { Grid, ListItemIcon } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import { getKey } from '../../../libs/utils/storageManager';
import { FxLink } from '../../Action/FxLink';
import { checkMenuDrawerPermission, checkTransactionApprovalPermission, getSelectedPath } from '../Passport';
import FxAvatar from '../../Input/FxAvatar/FxAvatar';

//icons
import { ReactComponent as DashboardIcon } from '../../../assets/svg/leftmenu-dashboard.svg';
import { ReactComponent as AuthorisedUsersIcon } from '../../../assets/svg/leftmenu-authorised-users.svg';
import { ReactComponent as TransactionsIcon } from '../../../assets/svg/leftmenu-transactions.svg';
import { ReactComponent as RolesPermissionIcon } from '../../../assets/svg/roles-permission-menu-icon.svg';
import { ReactComponent as RecurringIcon } from '../../../assets/svg/recurring-menu-icon.svg';
import { ReactComponent as ShoppingBagIcon } from '../../../assets/svg/shopping-bag.svg';
import { ReactComponent as AccountLedger } from '../../../assets/svg/account-ledger.svg';
import { ReactComponent as LogoutIconMobile } from '../../../assets/svg/logout-icon-mobile.svg';
import { ReactComponent as DebitCardIcon } from '../../../assets/svg/debit_card_icon.svg';
import { ReactComponent as ApprovalsIcon } from '../../../assets/svg/approval-queue-icon.svg';
import { ReactComponent as CloseIconLeft } from '../../../assets/svg/leftmenu-close.svg';
import { ReactComponent as CloseIconRight } from '../../../assets/svg/rightmenu-close.svg';
import { ReactComponent as HomeIcon } from '../../../assets/svg/account-home-icon.svg';
import { LogoMenu } from '../../Utils/Logo';

import { useIsMobileOrTabletScreen, useIsMobileScreen } from '../../../libs/utils/utils';
import { FxWelcomeListMenu } from '../../Page/Index/Passport/WelcomeMenuList';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as InvestmentInstruction } from '../../../assets/svg/portfolio-instruction-icon.svg';
import { drawerExpandTitle, swtichPortalMenu } from '../../Utils/ThemeCheckerPartner';
import { ManageMenu } from '../ManageMenu';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { handleHomeClick, handleLogoClick } from './DrawerHelper';
import { SettingsMenu } from '../SettingsMenu';
import { switchCustomerMenu } from '../../Utils/ThemeChecker';
import UIFeatureImpl from '../../../libs/services/uiFeatures';


/**
 * This Component handles the drawer expand state
 */
export const DrawerExpand: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const location = useLocation();
  const isMobileOrTabScreen = useIsMobileOrTabletScreen();
  const isSmallScreen = useIsMobileScreen();
  const dispatch = useDispatch()
  const history = useHistory();
  const uiFeat = UIFeatureImpl.getInstance();
  const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
  const individualCustomer = uiFeat.getIndividualCustomerFeature().available
  const jointTennancy=uiFeat.getJointTenancyFeature().available;
  const accountList = useSelector((state: any) => state.data['customer-basic-info']?.params?.accountList || null);
  const accountBasedMenu = props?.accountBasedMenu;
  const selectedAccount: any = props?.selectedOption;

  return (<>
    <Grid item container justifyContent="space-between" className="fx-logo-passport-menu-icon">
      <Grid item xs container alignItems={'center'}>
        <FxLink onClick={() => handleLogoClick(history, selectedAccount, accountList, dispatch)}><LogoMenu /></FxLink>
      </Grid>
      <Grid item xs={2} container alignContent='center' justifyContent='center' className="fx-logo-passport-menu-icon-close" id="close-expand-menu">
        {isSmallScreen ? <CloseIconRight /> : <CloseIconLeft />}
      </Grid>
    </Grid>
    {drawerExpandTitle(props)}
    <Grid container className="fx-menu-passport">
      <Grid item xs={12}>
        <ul>
          {accountList?.resources?.length >= 1  && checkMenuDrawerPermission('accounts') && <li className={getSelectedPath(location) === 12 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-account-home" onClick={() => handleHomeClick(dispatch)} redirect={"/home"}><ListItemIcon> <HomeIcon /></ListItemIcon>Home</FxLink></Grid></li>}
          {accountBasedMenu &&  <>
            <li className={getSelectedPath(location) === 1 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-dashboard" redirect={selectedAccount?.type === 'CASH_BUILDER_PLUS' ? "/cashbuilder" : selectedAccount?.type === 'CASH_BUILDER' ? "/portfolio" : "/"}><ListItemIcon> <DashboardIcon /></ListItemIcon>Dashboard</FxLink></Grid></li>
            {businessCustomer && (isMobileOrTabScreen) && checkMenuDrawerPermission('authorisedusers') && selectedAccount?.type !== 'DEPOSIT' && <li className={getSelectedPath(location) === 2 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-authorisedusers" redirect="/authorizedusers"><ListItemIcon> <AuthorisedUsersIcon /></ListItemIcon>Authorized Users</FxLink></Grid></li>}
            {checkMenuDrawerPermission('accountledgers') && <li className={getSelectedPath(location) === 3 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-accountledgers" redirect={"/accountledgers"}><ListItemIcon> <AccountLedger /></ListItemIcon>Account Activity</FxLink></Grid></li>}
            {(checkMenuDrawerPermission('transactions_card_collect') || checkMenuDrawerPermission('transactions_check_collect') || checkMenuDrawerPermission('transactions_ach_collect') || checkMenuDrawerPermission('transactions_wire_send') || checkMenuDrawerPermission('transactions_book_send') || checkMenuDrawerPermission('transactions_check_send') || checkMenuDrawerPermission('transactions_ach_send') || checkMenuDrawerPermission('transactions_internationalWire_send') || checkMenuDrawerPermission('transactions_virtual_card_send')) && <li className={getSelectedPath(location) === 4 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-transactions" redirect="/transactions"><ListItemIcon> <TransactionsIcon /></ListItemIcon>{!isMobileOrTabScreen ? 'Transaction Activity' : 'Transactions'}</FxLink></Grid></li>}
            {(checkMenuDrawerPermission('recurring_card_collect') || checkMenuDrawerPermission('recurring_ach_collect') || checkMenuDrawerPermission('recurring_wire_send') || checkMenuDrawerPermission('recurring_ach_send') || checkMenuDrawerPermission('recurring_internationalWire_send') || checkMenuDrawerPermission('recurring_check_send') || checkMenuDrawerPermission('recurring_book_send') || checkMenuDrawerPermission('recurring_virtual_card_send')) && selectedAccount?.type !== 'DEPOSIT' && <li className={getSelectedPath(location) === 5 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-recurring" redirect="/recurring"><ListItemIcon> <RecurringIcon /></ListItemIcon>{!isMobileOrTabScreen ? 'Recurring Txn. Activity' : 'Recurring Transactions'}</FxLink></Grid></li>}
            {checkMenuDrawerPermission('debitcards') && selectedAccount?.type !== 'DEPOSIT' && <li className={getSelectedPath(location) === 11 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-debit-cards" redirect="/debitcards"><ListItemIcon> <DebitCardIcon /></ListItemIcon>Debit Cards</FxLink></Grid></li>}
            {businessCustomer && (isMobileOrTabScreen) && checkMenuDrawerPermission('roles') && selectedAccount?.type !== 'DEPOSIT' && <li className={getSelectedPath(location) === 8 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-roles" redirect="/roles"><ListItemIcon> <RolesPermissionIcon /></ListItemIcon>Roles & Permission</FxLink></Grid></li>}
            {businessCustomer && (isMobileOrTabScreen) && checkMenuDrawerPermission('company') && selectedAccount?.type !== 'DEPOSIT' && <li className={getSelectedPath(location) === 9 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-company-information" redirect="/company"><ListItemIcon> <ShoppingBagIcon /></ListItemIcon>Company</FxLink></Grid></li>}
            {checkTransactionApprovalPermission() && <li className={getSelectedPath(location) === 10 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-approvals" redirect="/pendingApprovals"><ListItemIcon> <ApprovalsIcon /></ListItemIcon>Approvals</FxLink></Grid></li>}
            {selectedAccount?.type === 'CASH_BUILDER' && <li className={getSelectedPath(location) === 5 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-portfolio-instruction" redirect="/investmentInstructions"><ListItemIcon> <InvestmentInstruction /></ListItemIcon>Portflio Instructions</FxLink></Grid></li>}
          </> }
           <ManageMenu id="manage-external-menu" drawerExpand={true} selectedAccount={selectedAccount} onMenuSelect={props?.toggleDrawer}/>
        </ul>
      </Grid>
      <Grid item xs={12} className={`${isMobileOrTabScreen || individualCustomer ? 'fx-menu-passport-bottommenu' : 'fx-sidenav-bottommenu'}`}>
        {(businessCustomer || jointTennancy) && <SettingsMenu id="manage-settings-menu-expand" drawerExpand={true} onMenuSelect={props?.toggleDrawer} />}
          {switchCustomerMenu(props)}
        {getKey('isProxyLogin') !== true && (isMobileOrTabScreen || individualCustomer) ?
          <Grid item xs={12}>
            <FxLink id="menu-left-user-profile" redirect="/profile">
              <Grid item xs={12} container justifyContent='space-between' alignItems='center' paddingTop='1rem'>
                <Grid item xs={!isSmallScreen ? 2 : 1}><FxAvatar id="avtar-collapse" value={props?.userName} /></Grid>
                <Grid className='fx-menu-passport-profile-space' item xs={!isSmallScreen ? 10 : 11}><div className="fx-menu-passport-bottommenu-smalltext">Welcome</div><div>{props?.userName}</div></Grid>
              </Grid>
            </FxLink>
          </Grid> : <FxWelcomeListMenu drawerExpand={true} onMenuSelect={props?.toggleDrawer} userName={props.userName}></FxWelcomeListMenu>}
          {swtichPortalMenu(props)}
        {isSmallScreen && <>
          <Grid item>
            {uiFeat.getSSOUserFeatures().available && uiFeat.getSSOUserFeatures().button}
          </Grid>
          <Grid item xs={12} className={'fx-menu-left-logout'}>
            <FxLink id="menu-left-logout" redirect="/logout">
              <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <Grid item xs={!isSmallScreen ? 2 : 1} container alignItems={'center'}><LogoutIconMobile /></Grid>
                <Grid item xs={!isSmallScreen ? 10 : 11}><div className="fx-menu-passport-bottommenu-smalltext fx-menu-passport-profile-space">Logout</div></Grid>
              </Grid>
            </FxLink>
          </Grid>
        </>}
      </Grid>
    </Grid>
  </>
  )
})
