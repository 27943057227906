/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../../libs/utils/logger';
import HttpClient from '../../../../libs/utils/httpClient';
import FxCardHeader from '../../../Container/FxCardHeader';
import FxCard from '../../../Container/FxCard';
import FxCardBody from '../../../Container/FxCardBody';
import { DataGrid } from '../../../Data/DataGrid';
import { getImageDownload } from '../../ScheduleDetails/DownloadImage';
import { ReactComponent as DownloadIcon } from '../../../../assets/svg/download.svg';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { getCustomerUrl } from '../../../../libs/utils/utils';
import { useMediaQuery } from 'react-responsive';
import { FxStatus } from '../../../Input/FxStatus/FxStatus';
import { FxGridList } from '../../../Data/FxGridList';

Logger.debug("RecurringViewDocuments.tsx", "view documents recurring initializing");
/**
 * Component: RecurringViewDocuments
 * Usage: For external account linked documents list view
 */

export const RecurringViewDocuments: React.FC<any> = React.memo((props) => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
        let fileName: any;
        let fileType: any;
        ({ props } = RegisterComponent(props));
        let externalAccountData: any;
        let linkedDocuments: any = [];
        /**
         * Set default data
         */
        if (props?.data) {
            externalAccountData = props.data;
            if (externalAccountData.linkedDocument) {
                linkedDocuments = transformDocumentsEAList(externalAccountData.linkedDocument);
            }
        }
        const dispatch = useDispatch()
        /**
         * Method to rest the component on unmount.so that when redirect back page it will call the api again
         */
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        }, [])

        //linked documents list columns
        const column: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "Type",
                    "enable": true,
                    "fieldName": "documentType",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "ID",
                    "enable": true,
                    "fieldName": "id",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "Name",
                    "enable": true,
                    "fieldName": "documentName",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "Status",
                    "enable": true,
                    "fieldName": "status",
                    "type": "fri://fieldtype/status",
                    "default": true
                },
                {
                    "enable": true,
                    "fieldName": "icon",
                    "icon": <DownloadIcon />,
                    "type": "fri://fieldtype/image",
                    "onIconClick": true,
                    "className": "fx-row-icon fx-cursor-pointer",
                    "default": true
                }

            ]
        };

        /**
         * Method to transform external-account-linked documents
         * @param data : external account linked documents
         * @returns
         */
        function transformDocumentsEAList(data: any) {
            try {
                const newData: any = [];
                // eslint-disable-next-line array-callback-return
                data?.map((item: any, i: number) => {
                    newData[i] = { ...data[i] }
                    newData[i].documentName = item.document.name;
                    newData[i].documentType = item.document.type;
                    newData[i].status = item.status;
                    newData[i].linkedOn = item.linkedOn;
                    newData[i].linkedByUser = item.linkedBy.username;

                });
                return newData;
            }
            catch (e) {
                Logger.error("RecurringViewDocuments.tsx", "error", e);
                return false;
            }
        }
    /**
     * Grid lay out for mobile
     * @param item 
     * @returns 
     */
    const createDocumentDetails = (item: any) => (
        <> <Grid item container xs={11} alignItems='center'>
            <Grid item container xs={12}>
                <Grid item>
                    <Typography variant='h4'>ID: {item?.document?.id}</Typography>
                </Grid>

            </Grid>
            <Grid item container xs={12}>
                <Grid item>
                    <Typography variant='h4'> {item?.document?.type}</Typography>
                </Grid>
                <Grid item>
                    <FxStatus id="recurring-history-status" value={item?.status} fill={true} className="fx-status" />
                </Grid>
            </Grid>
            <Grid xs={12}>
                <Grid xs={12}>
                    <Typography variant='h4'> {item?.document?.name}</Typography>
                </Grid>

            </Grid>
        </Grid>
            <Grid item container xs={1} alignItems='end'>
                <Grid >
                    <IconButton onClick={(e: any) => downloadFile(e, item)}>
                        <DownloadIcon />
                    </IconButton>
                </Grid>
            </Grid></>
    );
    /**
     * Divider
     * @returns 
     */
    const createDivider = () => (
        <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
            <Grid item xs>
                <Divider />
            </Grid>
        </Grid>
    );
    /**
* @param data : View document List for mobile
* @returns : transformed data
*/
    const docListGridTransformerMobile = (data: any) => {
        const transformedData = data?.map((item: any) => ({
            description: (
                <Grid item container xs={12} className='fx-grid-list-content'>
                    {createDocumentDetails(item)}
                </Grid>
            ),
            divider: createDivider(),
        }));

        return transformedData || [];
    };

    const gridColumn: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "enable": true,
                "fieldName": "description",
                "type": "fri://fieldtype/custom",
                "xs": "12",
                "alignItems": 'center'
            },
            {
                "enable": true,
                "fieldName": "divider",
                "type": "fri://fieldtype/custom",
                "xs": "12",
            }

        ]
    }
        /**
         * Method to download linked document by API
         * @param event :event object
         * @param data :EA documents data
         */
        async function downloadFile(event: any, data: any) {

            const  downloadObj = data;

            if (downloadObj) {
                if (downloadObj.document && downloadObj.document.id && downloadObj.document.name) {
                    fileName = downloadObj.document.name?.split('.')[0];
                    fileType = downloadObj.document.name?.split('.')[1];
                    const httpClientBlob = HttpClient.newClientBlob();
                    await httpClientBlob.get(getCustomerUrl(`/document/id/${downloadObj.document.id}`)).then((response: any) => {
                        if (response.data) {
                            getImageDownload(response.data, fileName, "." + fileType)
                        }

                    })

                }
            }
        }
        return (
            <Grid id="external-account-documents-Grid" item xs={12}>
                <Grid id="external-account-documents-Grid-b" item xs={12}>
                        <FxCard className="fx-theme-passport">
                            <FxCardHeader id="external-accounts-view-documents-header">
                                <Grid container item direction="row" justifyContent="space-between">
                                    <Typography id="title-external-account-linked-documents" variant="h4" >Documents </Typography>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="extrernal-account-document-list-Card-Body" className="fx-card-body-no-border-no-shadow">
                                <Grid id="all-account-document-list-DataGrids" item xs={12}>
                                    {!isSmallScreen?<DataGrid id="datagrid-view-documents-external-account-list" tableClass="fx-table-passport" data={linkedDocuments} column={column} pagination={false} pageNumber={1} pageSize={25} resultPerPage={25} iconClick={downloadFile} />
                                    :<FxGridList id="datagrid-view-documents-external-account-list" data={docListGridTransformerMobile(externalAccountData.linkedDocument)} column={gridColumn} /> } 
                                </Grid>
                            </FxCardBody>
                        </FxCard>
                </Grid>
            </Grid>
        )
    });
