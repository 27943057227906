/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'
import { Grid, IconButton, Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { ReactComponent as ThanksIcon } from '../../../assets/svg/circular-tick-with-shadows.svg';  
import { FxCurrencyView } from '../../Input/FxCurrency/FxCurrencyView';
import { ReactComponent as CrossIcon } from '../../../assets/svg/success-cross-icon.svg';  

Logger.debug("DepositPlusTransactionSuccessCard.tsx", "DepositPlusTransactionSuccessCard Card initializing")

/**
 * This component handles the Success Card for Transfer
 */
export const DepositPlusTransactionSuccessCard: React.FC<any> = (props) => {
        
    const { headerText, amount, title, description, handleOnClose } = props;

    return (
        <Grid>
            <FxCard className='fx-deposit-plus-success-card fx-theme-passport'>
                <FxCardBody id="fx-deposit-plus-success-card-modal-body">
                    <Grid container direction="row" className="fx-modal-form flex column" alignItems="center">
                        <Grid item container xs={12} justifyContent="flex-end" marginBottom={'1rem'}>
                            <IconButton id='modal-close-button' size='medium' onClick={handleOnClose}>
                                <CrossIcon />
                            </IconButton>
                        </Grid>
                        <Grid item container xs={12} justifyContent="center" className='fx-deposit-plus-success-card-header'>
                            <Typography id="fx-deposit-plus-success-card-modal-subtext" variant="h2">
                                {headerText}
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} justifyContent="center" className='fx-deposit-plus-success-card-amount'>
                            <FxCurrencyView id="deosit-plus-success-card-currencyview" value={amount? amount : 0} prefix={"$"} />
                        </Grid>
                        <Grid item container xs={12} justifyContent="center" className='fx-deposit-plus-success-card-icon'>
                            <ThanksIcon />
                        </Grid>
                        <Grid item container xs={12} className='fx-deposit-plus-success-card-title' justifyContent="center">
                            <Typography id="fx-deposit-plus-success-card-modal-title" variant='h5'>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} className='fx-deposit-plus-success-card-description' justifyContent="center">
                            <Typography id="fx-eposit-plus-success-card-modal-subtext" variant="h5">
                                {description}
                            </Typography>
                        </Grid>
                    </Grid>
                </FxCardBody>
            </FxCard>
        </Grid>
    );
};
