/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import { QuickInvite } from './QuickInvite';
import { LeadsInsight } from './LeadsInsight';
import { TransactionVolume } from './TransactionVolume';
import { RecentInviteCard } from './RecentInviteCard';
import moment from 'moment';
import { updateComponentData } from '../../../Utils/CommonBaseClass';

/**
* component used to draw Dasnboard of Partner
*/
export const PartnerHome: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1280px)' })
    const dispatch = useDispatch();
   /**
    * UseEffect to set title and reset component while unmount
    */
    useEffect(() => {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: "page-title-details", data: { title: "Dashboard" } } });
        updateComponentData(dispatch, 'portal-selected', { selectedPortal: 'Leads'})
        return ()=>{
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'lead-insights' } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'recent-invites' } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'transaction-volume' } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'recent-invited-leads'} });
        }
    }, [])

    const COLORS = ["#40596B", "#C8CFD5", "#40596B",  "#F58220","#93A1AC"];
    const labelText = { text: 'Transactions', value: 500 ,labelColor:'#103046'}
    const src = {
        url: "/lead/summary",
        method: "POST",
        data: {
            "pageNumber": 1,
            "pageSize": 25,
            "sortOptions": {
              "sortBy": "createdOn",
              "sortOrder": "desc"
            },
            "criteria": {
              "filters": [
                
              ]
            }
          }
      }
      const transactionSummarySrc = {
        url: "/transactionSummary",
        method: "POST",
        data: {
            "pageNumber": 1,
            "pageSize": 25,
            "sortOptions": {
              "sortBy": "createdOn",
              "sortOrder": "desc"
            },
            "criteria": {
                "filters": [
                    {
                        "operator": "gte",
                        "key": "statusDate",
                        "values": [
                            moment().format('MM/DD/YYYY')
                        ]
                    },
                    {
                        "operator": "lte",
                        "key": "statusDate",
                        "values": [
                            moment().format('MM/DD/YYYY')
                        ]
                    }
                ]
            }
          }
      }
    /**
     * Method to draw transaction Volume
     * @returns 
     */
    const transactionVolumeGrid = () => {
            return <TransactionVolume id="transaction-volume" source={transactionSummarySrc} colors={COLORS} label={labelText} />
    }
    return <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className={"fx-passport-home fx-container"}>
        <Grid container className="fx-container-left" >
            <Grid container xs={12} >
                <LeadsInsight id={"lead-insights"} source={src} />
            </Grid>
            <Grid container xs={12} >
                <RecentInviteCard id={"recent-invites"} />
            </Grid>
            <Grid container xs={12} >
                {isSmallScreen && <Grid container xs={12} >
                    <QuickInvite id='quick-invite' shareVia="EMAIL"/>
                    {transactionVolumeGrid()}
                </Grid>}
            </Grid>
        </Grid>
        {!isSmallScreen && <Grid container className="fx-container-right">
            <QuickInvite id='quick-invite' shareVia="EMAIL" />
            {transactionVolumeGrid()}
        </Grid>}
    </Grid>
})