/* eslint-disable  @typescript-eslint/no-explicit-any */   
/* eslint-disable react-hooks/exhaustive-deps */ 
import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import FxCardHeader from '../../../../Container/FxCardHeader';
import FxCard from '../../../../Container/FxCard';
import FxCardFooter from '../../../../Container/FxCardFooter';
import { FxButton } from '../../../../Action/FxButton';
import FxCardBody from '../../../../Container/FxCardBody';
//icons
import { ReactComponent as AddIcon } from '../../../../../assets/svg/add-new-icon.svg';
import { ReactComponent as MultipleUser } from '../../../../../assets/svg/multiple-user-icon.svg';
import { useMediaQuery } from 'react-responsive';
import { renderError, updateComponentParams, validateCoOwnerData } from '../../../../Utils/CommonBaseClass';
import { RegisterComponent } from '../../../../../libs/saga/dataSaga';
import { useDispatch } from 'react-redux';
import { JointTenancyCoOwnerAdd } from './JointTenancyCoOwnerAdd';
import { JointTenancyCoOwnerCardView } from './JointTenancyCoOwnerCardView';
//component used to handle business owner pages

export const JointTenancyCoOwnerCard: React.FC<any> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch();
        const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
        const { formState: { errors }, setError, clearErrors } = useForm();
        const [hideCreateButton, setHideCreateButton] = useState(false);
        const [addScreenHide, setAddScreenHide] = useState(true);
        const [editScreenOpen, setEditScreenOpen] = useState<any>([]);
 
        useEffect(() => {
            updateComponentParams(dispatch, props.id, { isLoading: false })
        }, [])

        /**
         * submit data
         */
        const onSubmitData = (data: any, req: any, action: any, index: any) => {
            if (props?.onSubmit) {
                setAddScreenHide(true)
                onEditOwnerHandler(index, false);
                props.onSubmit(data, req, action, index)
            }
        }

        const onEditOwnerHandler =(index:number, isOpen:boolean)=>{
            editScreenOpen[index] = isOpen;
            setEditScreenOpen([...editScreenOpen])
        }

        /**
         * method to handle removed data
         * @param index :index
         * @param array :updated array
         */
        const updatedData = (index: any, array: any) => {
            if (props?.updatedData) {
                props?.updatedData(index, array)
            }
            if (props?.data?.length === 1) {
                setHideCreateButton(false)
            }
        }

        /* method to validate Co Owner data
        * @param item 
        */
        const validateCOData = (item: any) => {
            return validateCoOwnerData(item)
        };

        /**
         * function to validate all the Co Owners Data
         * @param data 
         */
        const checkCoOwnerData = (data: any) => {
            return data?.every(validateCOData) ?? true;
        };

        // Method handles the click operation for going back to business details screen
        const handleGoBackToDetailsClick = () => {
            props.goBackClick(1, 1);
        }

        // Method set the click event for going forward to next screen
        const handleClick = () => {
            if (!checkCoOwnerData(coOwnerData)) {
                setError("validateAuthDetails", {
                    type: "manual",
                    message: "Please ensure all the mandatory fields are filled.",
                });
                setTimeout(() => {
                    clearErrors('validateAuthDetails')
                }, 10000);
                return;
            }

            if (props.onClick) {
                updateComponentParams(dispatch, props.id, { isLoading: true })
                props.onClick(()=>{
                    updateComponentParams(dispatch, props.id, { isLoading: false })
                });
            }            
        }


        // Method set the click event for adding business owner
        const addAnotherBusinessOwner = () => {
            setHideCreateButton(true)
            setAddScreenHide(false)
        }

        //update data when changes happened
        const coOwnerData = useMemo(() => {
            if (props?.data) {
                return props?.data
            }
        }, [props?.data])

         //update data when changes happened
         const showAddOwnerButton = useMemo(() => {
            if (coOwnerData?.length > 0 && addScreenHide && !editScreenOpen.find((x:any)=>x === true)) {
                return true
            }
            else{
                return false;
            }
        }, [coOwnerData, addScreenHide, editScreenOpen])

        /**
         * method to cancel add screen 
         */
        const cancelAdd = () => {
            setAddScreenHide(true)
            setHideCreateButton(false)
        }


        return (
            <Grid container id='business-owner-card' xs={12} justifyContent='center' className='fx-join-tenancy-co-owner-card'>
                <Grid item xs={12} sm={isSmallScreen ? undefined : 11} md={isSmallScreen ? undefined : 7}>
                    <FxCard id="business-owner-card-form-card" className={isSmallScreen ? "fx-theme-passport-no-before fx-no-shadow fx-signup-formkyc fx-signup-formkyc-mobile fx-business-owner" : "fx-theme-passport-no-before fx-no-shadow fx-signup-formkyc fx-business-owner"}>
                        <FxCardHeader id="business-owner-card-form-card-header">
                            <Grid item xs={12} className='fx-signup-formkyc-topblock'>
                                <Grid item xs={12} flexWrap={'nowrap'} container>
                                    <Grid item className={"fx-signup-formkyc-topblock-icon"}>
                                        <MultipleUser />
                                    </Grid>
                                    <Grid item>
                                        <Typography id="business-owner-card-form-card-title" variant="h3">
                                            Add Co-Owner
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} marginTop={2.5}>
                                    <Typography variant="h6" id='business-owner-card-form-card-label' className={isSmallScreen ? "fx-word-wrap" : ''}>
                                        To facilitate shared Ownership and Management of funds, kindly provide the necessary details of the Co-Owner to open a Joint Account in the fields provided below.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody id="signUp-business-owner-add-form-card-body" className="fx-info-card fx-margin-top-reducer fx-info-kyc" >
                            <Grid container className="fx-info-card-form" >
                                {props?.data?.length === 0 && !hideCreateButton && <FxCardBody id="signUp-business-owner-add-form-card-body" className="fx-info-card" >
                                    <Grid container direction="row" spacing={2} className="fx-info-card-form" >
                                        <Grid id="address-Grid" item container justifyContent="space-between" xs={12} sm={12} className={isSmallScreen ? 'fx-business-owner-add-button' : ''} >
                                            <FxButton
                                                disableRipple={false}
                                                className={"fx-button fx-button-passport-border fx-button-passport-border-kyc fx-svg-theme"}
                                                id="add-business-owner"
                                                startIcon={<AddIcon />}
                                                onClick={addAnotherBusinessOwner}
                                            > Add CO-OWNER
                                            </FxButton>
                                        </Grid>
                                    </Grid>
                                </FxCardBody>}
                                {coOwnerData && coOwnerData.map((item: any, index: any) => {
                                    return <JointTenancyCoOwnerCardView addAnotherBusinessOwner={addAnotherBusinessOwner} item={item} index={index} data={coOwnerData} updatedData={updatedData} primaryOwnerDetails={props?.primaryOwnerDetails} onSubmit={onSubmitData} editOpen={onEditOwnerHandler} />
                                })}
                                { showAddOwnerButton && <Grid id="address-Grid" item container justifyContent="space-between" xs={12} >
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-passport-border fx-button-passport-border-kyc fx-svg-theme"
                                        id="add-business-owner"
                                        startIcon={<AddIcon />}
                                        onClick={addAnotherBusinessOwner}
                                    > Add CO-OWNER
                                    </FxButton>
                                </Grid>}
                                {!addScreenHide && <JointTenancyCoOwnerAdd data={props?.data} onSubmit={onSubmitData} primaryOwnerDetails={props?.primaryOwnerDetails} onClick={props?.onClick} goBackClick={props?.goBackClick} cancel={cancelAdd} id='add-business-owner' />}
                                {props?.data?.length > 0 && <>
                                    <Grid item xs={12} sm={12}>
                                    </Grid></>
                                }
                                <Grid item xs={12} sm={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </FxCardBody>
                        <Grid item container xs={12} sm={12}>&nbsp;</Grid>
                            <Grid item container xs={12} sm={12}>
                                <div className={'error-message'} >
                                    {errors.validateAuthDetails && renderError(errors.validateAuthDetails)}
                                </div>
                            </Grid>
                        <FxCardFooter id="business-owner-card-Card-Footer" className="fx-footer">
                            <Grid container direction="row" justifyContent="space-between" columnSpacing={2} className={isSmallScreen ? "fx-modal-footer fx-footer-kyc" : "fx-modal-footer"}>                            
                            <Grid item xs={12}>
                                <Grid container direction='row' columnSpacing={2} justifyContent={'flex-end'}>
                                    <Grid item>
                                        <FxButton
                                            disableRipple={false}
                                            variant="contained"
                                            className="fx-button fx-button-cancel"
                                            id="back-business-owner-card-btn"
                                            onClick={handleGoBackToDetailsClick}
                                        > GO BACK
                                        </FxButton>
                                    </Grid>
                                    <Grid item>
                                        <FxButton
                                            disableRipple={false}
                                            className={coOwnerData?.length === 0 || !showAddOwnerButton? "fx-button fx-button-theme-disabled" : "fx-button fx-button-theme"}
                                            id="continue-business-owner-card-btn"
                                            type="submit"
                                            onClick={handleClick}
                                            isSubmitting={props?.isLoading}
                                            disabled= {coOwnerData?.length === 0 || !showAddOwnerButton? true: false}
                                        > CONTINUE
                                        </FxButton>
                                    </Grid>
                                    
                                </Grid>                                
                            </Grid>  
                           </Grid>
                        </FxCardFooter>
                    </FxCard>
                </Grid>
            </Grid>
        )
    }
)
